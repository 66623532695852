import { Card, Form, Select, DatePicker, Button } from 'antd'
import dayjs from 'dayjs'
import React from 'react'

import AppLayout from '../../layouts/AppLayout'

function FinancialReports() {
  const [form] = Form.useForm()
  const [loading, setLoading] = React.useState(false)

  async function onSubmit(values) {
    if (!values.range) return

    setLoading(true)

    const [startDate, endDate] = values.range

    fetch(
      `${
        process.env.REACT_APP_ENDPOINT_API ||
        'https://core-api.advqxs.easypanel.host'
      }/report/${values.type}?startDate=${dayjs(startDate).format(
        'YYYY-MM-DD'
      )}&endDate=${dayjs(endDate).format('YYYY-MM-DD')}`
    )
      .then(resp => resp.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = 'relatorio.xlsx'
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)

        setLoading(false)
      })
      .catch(() => {
        alert('Ocorreu algum problema')

        setLoading(false)
      })
  }

  return (
    <AppLayout title="Relatórios" keyPage="5" subKeyPage="sub1">
      <Card title="Filtrar busca">
        <Form
          layout="inline"
          form={form}
          onFinish={onSubmit}
          initialValues={{
            type: 'rents'
          }}
        >
          <Form.Item label="Tipo de relatório" name="type" required>
            <Select style={{ width: 200 }}>
              <Select.Option value="rents">Aluguéis</Select.Option>
              <Select.Option value="sales">Vendas</Select.Option>
              <Select.Option value="expenses">Despesas</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Selecione o perído" name="range" required>
            <DatePicker.RangePicker />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Buscar & Baixar
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </AppLayout>
  )
}

export default FinancialReports
