import {
  Box,
  Button,
  Flex,
  Heading,
  Select,
  Text,
  VStack,
  useDisclosure
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import React from 'react'
import Calendar from 'react-calendar'
import { AiOutlinePlus } from 'react-icons/ai'
import { Sticky } from 'react-sticky'
import MovimentationsModal from '../Movimentations/Modal'

function TellerFilters({
  transaction,
  setTransaction,
  dates,
  setDates,
  loading,
  onUpdate
}) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Box>
      <MovimentationsModal
        isOpen={isOpen}
        onClose={onClose}
        transaction={transaction}
        onUpdate={onUpdate}
      />
      <Sticky>
        {({ style, isSticky }) => (
          <div style={style}>
            <Box pt={isSticky ? 4 : 0} transition="all ease .3s">
              <Box
                p={8}
                bg="white"
                borderRadius="lg"
                border="solid 1px"
                borderColor="gray.200"
              >
                <Flex alignItems="center" justifyContent="space-between" pb="3">
                  <Heading size="md" m={0}>
                    Movimentações
                  </Heading>
                  <Button
                    size="sm"
                    leftIcon={<AiOutlinePlus />}
                    colorScheme="green"
                    isDisabled={transaction === 'rents'}
                    onClick={onOpen}
                  >
                    Novo
                  </Button>
                </Flex>
                <VStack spacing={4} w="100%" mt={4}>
                  <Box w="100%">
                    <Text fontSize="sm" fontWeight="700">
                      Transações
                    </Text>
                    <Select
                      variant="outline"
                      bg="white"
                      value={transaction}
                      onChange={e => setTransaction(e.target.value)}
                    >
                      <option value="rents">Aluguéis</option>
                      <option value="sales">Vendas</option>
                      <option value="expenses">Despesas</option>
                    </Select>
                  </Box>
                  <Box w="100%">
                    <Calendar
                      className="mb-calendar"
                      onChange={setDates}
                      value={dates}
                      allowPartialRange={true}
                      locale="pt-BR"
                      selectRange={true}
                      returnValue="range"
                      maxDate={new Date()}
                    />
                  </Box>
                </VStack>
                {transaction === 'rents' && (
                  <Button
                    mt={8}
                    colorScheme="orange"
                    w="100%"
                    isLoading={loading}
                    isDisabled={loading}
                    onClick={() =>
                      window.open(
                        `https://core-api.advqxs.easypanel.host/report/${transaction}?startDate=${dayjs(
                          dates[0]
                        ).format('YYYY-MM-DD')}&endDate=${dayjs(
                          dates[1]
                        ).format('YYYY-MM-DD')}`,
                        '_blank'
                      )
                    }
                  >
                    Baixar relatório
                  </Button>
                )}
              </Box>
            </Box>
          </div>
        )}
      </Sticky>
    </Box>
  )
}

export default TellerFilters
