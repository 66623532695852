/* eslint-disable react/no-children-prop */
import { Button, Card, Form, Input, message } from 'antd'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import ReactSummernote from 'react-summernote'
import 'react-summernote/dist/react-summernote.css' // import styles

import 'bootstrap/js/src/modal'
import 'bootstrap/js/src/dropdown'
import 'bootstrap/js/src/tooltip'
import 'bootstrap/dist/css/bootstrap.css'

import AppLayout from '../../layouts/AppLayout'
import api from '../../services/api'

function BlogEdit() {
  const [loading, setLoading] = useState(true)
  const [file, setFile] = useState(null)
  const [body, setBody] = useState('')

  const { id } = useParams()
  const [form] = Form.useForm()

  React.useEffect(() => {
    ;(async () => {
      setLoading(true)

      try {
        const { data } = await api.get(`/feed/${id}`)

        form.setFieldsValue({
          title: data.title
        })

        setBody(data.body)
      } catch (err) {
        console.log(err)
        message.error('Ocorreu um erro ao buscar a publicação')
      }

      setLoading(false)
    })()
  }, [id, form])

  async function edit(values) {
    message.loading('Atualizando...')

    setLoading(true)

    var formData = new FormData()

    Object.keys(values).forEach(key => {
      if (key !== 'image') {
        formData.append(key, values[key])
      }
    })

    if (file) {
      formData.append('image', file, file.name)
    }
    formData.append('body', body)

    try {
      await api.patch(`/feed/${id}`, formData)

      message.success('Notícia atualizada!')

      form.resetFields()
    } catch (err) {
      message.error('Ocorreu um erro ao tentar atualizar a publicação')
    }

    setLoading(false)
  }

  return (
    <AppLayout title="Editar publicação" keyPage="15">
      <Card title="Publicar" style={{ marginBottom: 25 }} loading={loading}>
        <Form name="publish" form={form} onFinish={edit} autoComplete="off">
          <Form.Item
            name="title"
            rules={[
              { required: true, message: 'Informe o título da notícia!' }
            ]}
          >
            <Input placeholder="Título da notícia" />
          </Form.Item>
          <Form.Item name="image" label="Imagem do cabeçalho">
            <Input type="file" onChange={e => setFile(e.target.files[0])} />
          </Form.Item>
          <Form.Item>
            <ReactSummernote
              value={body}
              options={{
                height: 350,
                dialogsInBody: true,
                toolbar: [
                  ['style', ['style']],
                  ['font', ['bold', 'underline', 'clear']],
                  ['fontname', ['fontname']],
                  ['para', ['ul', 'ol', 'paragraph']],
                  ['table', ['table']],
                  ['insert', ['link', 'picture', 'video']],
                  ['view', ['fullscreen', 'codeview']]
                ]
              }}
              onChange={setBody}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Cadastrar
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </AppLayout>
  )
}

export default BlogEdit
