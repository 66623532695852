import {
  Card,
  Menu,
  Dropdown,
  Tabs,
  Row,
  Col,
  Form,
  Select,
  Switch,
  Input,
  Button,
  Statistic,
  Upload,
  Table,
  message,
  Tag
} from 'antd'
import arrayMove from 'array-move'
import axios from 'axios'
import React, { useState, useEffect } from 'react'
import Lightbox from 'react-image-lightbox'
import InputMask from 'react-input-mask'
import { Link, useParams } from 'react-router-dom'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

import 'react-image-lightbox/style.css'

import { getToken } from '../../guard/auth'
import propertiesTranslate from '../../helpers/translate/properties'
import AppLayout from '../../layouts/AppLayout'
import api from '../../services/api'
import geolocation from '../../services/geolocation'

const { TabPane } = Tabs

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 16 }
}
const tailLayout = {
  wrapperCol: { offset: 5, span: 16 }
}
const types = [
  ['SALE', 'Venda'],
  ['RENT', 'Aluguel'],
  ['PRE_SALE', 'Pré Venda'],
  ['EXCHANGE', 'Permuta']
]
const locations = [
  ['APARTMENT', 'Apartamento'],
  ['ROOF', 'Cobertura'],
  ['HOUSE', 'Casa'],
  ['FARM', 'Fazenda'],
  ['CLINIC', 'Consultório'],
  ['SHED', 'Galpão'],
  ['GARAGE', 'Garagem'],
  ['KITNET', 'Kitnet'],
  ['LOT', 'Lote'],
  ['STORE', 'Loja'],
  ['COMERCIAL', 'Sala Comercial'],
  ['SITE', 'Sítio']
]

const status = [
  {
    name: 'PENDING',
    color: 'gold',
    translate: 'Pendente'
  },
  {
    name: 'CONFIRMED',
    color: 'green',
    translate: 'Confirmado'
  },
  {
    name: 'USING',
    color: 'purple',
    translate: 'Em visita'
  },
  {
    name: 'RETURNED',
    color: 'geekblue',
    translate: 'Retornado / Finalizado'
  },
  {
    name: 'CANCELED',
    color: 'red',
    translate: 'Cancelado'
  }
]

function PropertiesInfo() {
  const [loading, setLoading] = useState(true)
  const [updateLoading, setUpdateLoading] = useState(false)
  const [exterior, setExterior] = useState(false)
  const [users, setUsers] = useState([])
  const [data, setData] = useState({
    status: 'PENDING',
    images: [],
    fees: [],
    features: [],
    schedules: [],
    information: {
      pet: false,
      comercial: false,
      furnished: false
    }
  })

  const [form] = Form.useForm()
  const [formFee] = Form.useForm()
  const [formFeature] = Form.useForm()
  const [formInfo] = Form.useForm()

  const [lightboxOpened, setLightboxOpened] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)

  const { id } = useParams()

  const statusMenu = (
    <Menu>
      <Menu.Item onClick={() => updateStatus('AVAILABLE')}>
        Disponível
      </Menu.Item>
      {data.type && data.type === 'SALE' ? (
        <Menu.Item onClick={() => updateStatus('SOLD')}>Vendido</Menu.Item>
      ) : (
        <Menu.Item onClick={() => updateStatus('RENTED')}>Alugado</Menu.Item>
      )}
      <Menu.Item onClick={() => updateStatus('UNAVAILABLE')}>
        Indisponível
      </Menu.Item>
    </Menu>
  )

  useEffect(() => {
    getData()
    getUsers()
    // eslint-disable-next-line
  }, [id])

  async function getUsers() {
    const { data } = await api.get('/user').catch(() => {
      message.error('Não foi possível buscar os proprietários')
    })

    setUsers(data)
  }

  async function getData(loadAgain = true) {
    if (loadAgain) {
      setLoading(true)
    }

    try {
      const { data } = await api.get(`/property/${id}/admin`)

      if (data.is_exterior) {
        setExterior(true)
      }

      setData({
        ...data,
        owner_id: data.relationship.filter(
          el => el.pivot.relationship === 'OWNER'
        )[0].pivot.user_id
      })
    } catch (err) {
      message.error('Ocorreu um erro ao buscar os dados')
    }

    setLoading(false)
  }

  async function onSubmit(values) {
    setUpdateLoading(true)

    try {
      await api.patch('/property/' + id, values)

      message.success('Imóvel atualizado!')
    } catch (err) {
      message.error(err.response.data[0].message)
    }

    setUpdateLoading(false)
  }

  async function onUpdate(values) {
    setUpdateLoading(true)

    try {
      await api.put('/property/' + id + '/information', values)

      message.success('Imóvel atualizado!')
    } catch (err) {
      message.error(err.response.data[0].message)
    }

    setUpdateLoading(false)
  }

  async function onRemoveFee(id) {
    try {
      await api.delete(`/property/${id}/fees`)

      var oldState = data.fees
      var idx = data.fees.findIndex(el => el.id === id)

      oldState[idx] = undefined

      setData({ ...data, fees: oldState.filter(el => el !== undefined) })
    } catch (err) {
      message.error('Ocorreu um erro ao tentar remover a taxa')
    }
  }

  async function onRemoveImage(id) {
    try {
      await api.delete(`/property/${id}/images`)

      var oldState = data.images
      var idx = data.images.findIndex(el => el.id === id)

      oldState[idx] = undefined

      setData({ ...data, images: oldState.filter(el => el !== undefined) })
    } catch (err) {
      message.error('Ocorreu um erro ao tentar remover a imagem')
    }
  }

  async function onRemoveFeature(id) {
    try {
      await api.delete(`/property/${id}/features`)

      var oldState = data.features
      var idx = data.features.findIndex(el => el.id === id)

      oldState[idx] = undefined

      setData({ ...data, features: oldState.filter(el => el !== undefined) })
    } catch (err) {
      message.error('Ocorreu um erro ao tentar remover a característica')
    }
  }

  const SortableList = SortableContainer(
    ({ items, isImage, isFee, isFeature }) => {
      return (
        <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
          {items.map((value, index) => (
            <SortableItem
              key={`item-${index}`}
              index={index}
              sortIndex={index}
              value={value}
              isImage={isImage}
              isFee={isFee}
              isFeature={isFeature}
            />
          ))}
        </ul>
      )
    }
  )

  const SortableItem = SortableElement(
    ({ value, isImage, isFee, isFeature }) => (
      <li style={{ marginBottom: 10 }}>
        {isImage && (
          <div style={{ position: 'relative', width: 'fit-content' }}>
            <img src={value.url} alt="..." height="120px" />
            <button
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                padding: 5,
                fontSize: '12px',
                cursor: 'pointer',
                border: 'none',
                background: 'red',
                color: 'white'
              }}
              onClick={() => onRemoveImage(value.id)}
            >
              Remover
            </button>
          </div>
        )}
        {isFee && (
          <p>
            {value.name} R$
            {parseFloat(value.price).toLocaleString('pt-BR', {
              minimumFractionDigits: 2
            })}
            <Button
              type="danger"
              size="small"
              style={{ float: 'right' }}
              onClick={() => onRemoveFee(value.id)}
            >
              Remover
            </Button>
          </p>
        )}
        {isFeature && (
          <p>
            {value.name}
            <Button
              type="danger"
              size="small"
              style={{ float: 'right' }}
              onClick={() => onRemoveFeature(value.id)}
            >
              Remover
            </Button>
          </p>
        )}
      </li>
    )
  )

  async function updateStatus(status) {
    message.loading('Atualizando...')

    api
      .patch('/property/' + id, { status })
      .then(() => {
        message.success('Situação do imóvel atualizado com sucesso!')
        setData({ ...data, status })
      })
      .catch(() => {
        message.error(
          'Ocorreu um erro ao tentar mudar a situação da propriedade.'
        )
      })
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      responsive: ['md'],
      sorter: (a, b) => a.id - b.id
    },
    {
      title: 'Imóvel',
      dataIndex: 'property_id',
      key: 'property_id',
      render: text => <Link to={`/properties/info/${text}`}>{text}</Link>
    },
    {
      title: 'Usuário',
      render: (_text, record) => (
        <Link to={`/clients/info/${record.user.id}`}>
          {record.user.name.split(' ')[0] +
            ' ' +
            record.user.name.split(' ')[1] || ''}
        </Link>
      )
    },
    {
      title: 'Data',
      dataIndex: 'date',
      key: 'date',
      render: text => new Date(text).toLocaleDateString('pt-BR')
    },
    {
      title: 'Horário',
      dataIndex: 'shift',
      key: 'shift',
      responsive: ['md'],
      render: text => (text === 'AM' ? 'Manhã' : 'Tarde')
    },
    {
      title: 'Check-In',
      dataIndex: 'checkIn',
      key: 'checkIn',
      render: text => (text ? new Date(text).toLocaleString('pt-BR') : '-/-')
    },
    {
      title: 'Check-Out',
      dataIndex: 'checkOut',
      key: 'checkOut',
      render: text => (text ? new Date(text).toLocaleString('pt-BR') : '-/-')
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: text => (
        <Tag color={status.find(el => el.name === text).color}>
          {status.find(el => el.name === text).translate}
        </Tag>
      )
    }
  ]

  return (
    <AppLayout title="Ver imóvel" subKeyPage="sub2">
      <Card
        title="Informações do Imóvel"
        extra={
          <Tag color="#f50">
            {!loading &&
              `${id}${
                data.external_id
                  ? ' - ' + data.external_id + ' [IMOVEL INTEGRADO]'
                  : ''
              }`}
          </Tag>
        }
        loading={loading}
      >
        <Row gutter={25}>
          <Col md={9}>
            <h6 className="text-uppercase font-weight-light">Ações rápidas</h6>
            <Dropdown overlay={statusMenu} placement="bottomLeft" arrow>
              <Button type="ghost">Alterar situação</Button>
            </Dropdown>
            <br />
            {data.status === 'PENDING' && (
              <div className="mt-3">
                <p className="mb-2">Deseja aprovar publicação deste imóvel?</p>
                <Button
                  type="primary"
                  onClick={() => updateStatus('AVAILABLE')}
                >
                  Aprovar
                </Button>
                <Button type="danger" className="ml-2">
                  Reprovar
                </Button>
              </div>
            )}
          </Col>
          <Col md={5}>
            <Statistic
              title="Situação"
              value={propertiesTranslate.status(data.status)}
            />
          </Col>
          <Col md={5}>
            <Statistic
              title="Proprietário"
              value={
                data.relationship &&
                data.relationship
                  .find(el => el.pivot.relationship === 'OWNER')
                  .name.split(' ')[0]
              }
            />
            <Link
              to={`/clients/info/${
                data.relationship &&
                data.relationship.find(el => el.pivot.relationship === 'OWNER')
                  .id
              }`}
            >
              visualizar perfil
            </Link>
          </Col>
          <Col md={5}>
            <Statistic
              title="Inquilino"
              value={
                data.relationship &&
                data.relationship.find(el => el.pivot.relationship === 'TENANT')
                  ? data.relationship
                      .find(el => el.pivot.relationship === 'TENANT')
                      .name.split(' ')[0]
                  : '-/-'
              }
            />
            {data.relationship &&
              data.relationship.find(
                el => el.pivot.relationship === 'TENANT'
              ) && (
                <Link
                  to={`/clients/info/${
                    data.relationship &&
                    data.relationship.find(
                      el => el.pivot.relationship === 'TENANT'
                    ).id
                  }`}
                >
                  visualizar perfil
                </Link>
              )}
          </Col>
        </Row>
        <br />
        <Tabs defaultActiveKey="6">
          <TabPane tab="Principal" key="6">
            <Form
              {...layout}
              name="register"
              form={form}
              onFinish={onSubmit}
              initialValues={data}
              onValuesChange={({ is_exterior: isExterior }) => {
                if (isExterior !== undefined) {
                  if (isExterior) {
                    form.setFieldsValue({ cep: '' })
                  }

                  setExterior(isExterior)
                }
              }}
              autoComplete="off"
              scrollToFirstError
            >
              <Form.Item label="Destaque" name="spotlight">
                <Switch
                  defaultChecked={data.spotlight ? data.spotlight : false}
                />
              </Form.Item>
              <Form.Item label="Exterior" name="is_exterior">
                <Switch defaultChecked={exterior} />
              </Form.Item>
              {!exterior && (
                <Form.Item label="CEP" name="cep">
                  <InputMask
                    mask="99.999-999"
                    onChange={async e => {
                      if (!e.target.value.includes('_')) {
                        var unformatted = e.target.value

                        unformatted = unformatted
                          .split('.')
                          .join('')
                          .split('-')
                          .join('')

                        const { data } = await axios.get(
                          `https://viacep.com.br/ws/${unformatted}/json/`
                        )

                        form.setFieldsValue({
                          street: data.logradouro,
                          neighborhood: data.bairro,
                          city: data.localidade,
                          state: data.uf,
                          latitude: '',
                          longitude: ''
                        })

                        form.scrollToField('number')
                      }
                    }}
                  >
                    {inputProps => <Input {...inputProps} />}
                  </InputMask>
                </Form.Item>
              )}
              <Form.Item label="Logradouro" name="street">
                <Input />
              </Form.Item>
              <Form.Item label="Bairro" name="neighborhood">
                <Input />
              </Form.Item>
              <Form.Item label="Cidade" name="city">
                <Input />
              </Form.Item>
              <Form.Item label="Estado" name="state">
                <Input />
              </Form.Item>
              <Form.Item label="País" name="country">
                <Input />
              </Form.Item>
              <Form.Item
                label="Número"
                name="number"
                rules={[{ required: true, message: 'Informe o Número' }]}
              >
                <Input
                  onBlur={async () => {
                    const { street, neighborhood, number, city, cep } =
                      form.getFieldsValue()
                    const location = await geolocation(
                      `${street}, ${number}, ${neighborhood} - ${city} ${cep}`
                    )

                    form.setFieldsValue({
                      ...location
                    })
                  }}
                />
              </Form.Item>
              <Form.Item label="Localidade">
                <Input.Group compact>
                  <Form.Item name="latitude">
                    <Input />
                  </Form.Item>
                  <Form.Item name="longitude">
                    <Input />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
              <Form.Item label="Complemento" name="complement">
                <Input />
              </Form.Item>
              <Form.Item
                label="Quartos"
                name="bedrooms"
                rules={[
                  {
                    required: true,
                    message: 'Informe a quantidade de quartos'
                  }
                ]}
              >
                <Input type="number" />
              </Form.Item>
              <Form.Item
                label="Banheiros"
                name="bathrooms"
                rules={[
                  {
                    required: true,
                    message: 'Informe a quantidade de banheiros'
                  }
                ]}
              >
                <Input type="number" />
              </Form.Item>
              <Form.Item
                label="Vagas"
                name="garage"
                rules={[
                  {
                    required: true,
                    message: 'Informe a quantidade de vagas na garagem'
                  }
                ]}
              >
                <Input type="number" />
              </Form.Item>
              <Form.Item
                label="Transação"
                name="type"
                rules={[{ required: true, message: 'Informe o tipo' }]}
              >
                <Select>
                  {types.map((type, idx) => (
                    <Select.Option key={`type-${idx}`} value={type[0]}>
                      {type[1]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Tipo"
                name="location"
                rules={[{ required: true, message: 'Informe a locação' }]}
              >
                <Select>
                  {locations.map((location, idx) => (
                    <Select.Option key={`location-${idx}`} value={location[0]}>
                      {location[1]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Proprietário"
                name="owner_id"
                rules={[{ required: true, message: 'Informe o proprietário' }]}
              >
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {users.map(user => (
                    <Select.Option key={`user-${user.id}`} value={user.id}>
                      [Cod {user.id}] {user.name} {user.cpf_cpnj}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Preço"
                name="price"
                rules={[{ required: true, message: 'Informe o preço' }]}
              >
                <Input />
              </Form.Item>
              {data.type === 'SALE' && (
                <>
                  <hr />
                  <br />
                  <Form.Item
                    label="Captador"
                    name="pickup"
                    rules={[{ message: 'Informe o capitador' }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Comissão"
                    name="commission"
                    rules={[{ message: 'Informe a comissão' }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item label="Exclusividade" name="exclusivity">
                    <Switch
                      defaultChecked={
                        data.exclusivity ? data.exclusivity : false
                      }
                    />
                  </Form.Item>
                  <hr />
                </>
              )}
              <Form.Item {...tailLayout}>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  disabled={updateLoading}
                  loading={updateLoading}
                >
                  Salvar alterações
                </Button>
              </Form.Item>
            </Form>
          </TabPane>
          <TabPane tab="Informações" key="4">
            <Form
              {...layout}
              form={formInfo}
              name="add-info"
              onFinish={onUpdate}
              initialValues={data.information}
            >
              <Form.Item
                label="Descrição"
                name="description"
                rules={[{ required: true, message: 'Informe a descrição' }]}
              >
                <Input.TextArea placeholder="Descreva o imóvel..." rows={4} />
              </Form.Item>
              <Form.Item label="Área do terreno" name="land_area">
                <Input type="number" />
              </Form.Item>
              <Form.Item label="Área construída" name="building_area">
                <Input type="number" />
              </Form.Item>
              <Form.Item label="Inscrição Energisa" name="energisa">
                <Input />
              </Form.Item>
              <Form.Item label="Inscrição Copasa" name="copasa">
                <Input />
              </Form.Item>
              <Form.Item label="Permitido PET" name="pet">
                <Switch
                  defaultChecked={
                    data.information ? data.information.pet : false
                  }
                />
              </Form.Item>
              <Form.Item label="Mobiliado" name="furnished">
                <Switch
                  defaultChecked={
                    data.information ? data.information.furnished : false
                  }
                />
              </Form.Item>
              <Form.Item label="Comércio Próx." name="comercial">
                <Switch
                  defaultChecked={
                    data.information ? data.information.comercial : false
                  }
                />
              </Form.Item>
              <Form.Item {...tailLayout}>
                <Button
                  htmlType="submit"
                  onClick={async () => {
                    const { description, area } = formInfo.getFieldsValue()
                    const data = formInfo.getFieldsValue()

                    if (description && area) {
                      try {
                        await api.put(`/property/${id}/information`, data)

                        message.success('Atualizado!')
                      } catch (err) {
                        message.error(
                          'Ocorreu algum erro ao tentar atualizar as informações'
                        )
                      }
                    }
                  }}
                >
                  Atualizar
                </Button>
              </Form.Item>
            </Form>
          </TabPane>
          <TabPane tab="Características" key="1">
            <Form form={formFeature} name="add-feature">
              <Row gutter={15}>
                <Col md={18}>
                  <Form.Item
                    name="name"
                    rules={[{ required: true, message: 'Informe o nome' }]}
                  >
                    <Input placeholder="Informe a característica" />
                  </Form.Item>
                </Col>
                <Col md={6}>
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      onClick={async () => {
                        const { name } = formFeature.getFieldsValue()

                        if (name) {
                          try {
                            await api.put(`/property/${id}/features`, {
                              data: [name]
                            })

                            setData({
                              ...data,
                              features: [...data.features, { name }]
                            })
                            formFeature.resetFields()
                          } catch (err) {
                            message.error(
                              'Ocorreu algum erro ao tentar cadastrar uma característica'
                            )
                          }
                        }
                      }}
                    >
                      Cadastrar
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <SortableList
              isFeature
              items={data.features}
              pressDelay={200}
              onSortEnd={({ oldIndex, newIndex }) => {
                var newPosition = arrayMove(data.features, oldIndex, newIndex)

                setData({ ...data, features: newPosition })

                api
                  .patch(`/property/${id}/features/position`, {
                    data: newPosition
                  })
                  .catch(() => {
                    message.error('Ocorreu um erro ao tentar mudar a posição')
                  })
              }}
            />
          </TabPane>
          <TabPane tab="Taxas" key="2">
            <Form
              form={formFee}
              name="add-fee"
              onSubmit={() => message.success('Enviado')}
            >
              <Row gutter={15}>
                <Col md={9}>
                  <Form.Item
                    name="name"
                    rules={[{ required: true, message: 'Informe o nome' }]}
                  >
                    <Input placeholder="Nome da Taxa" />
                  </Form.Item>
                </Col>
                <Col md={9}>
                  <Form.Item
                    name="price"
                    rules={[{ required: true, message: 'Informe o valor' }]}
                  >
                    <Input placeholder="Valor da Taxa" />
                  </Form.Item>
                </Col>
                <Col md={6}>
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      onClick={async () => {
                        const { name, price } = formFee.getFieldsValue()

                        if (name && price) {
                          try {
                            await api.put(`/property/${id}/fees`, {
                              data: [
                                {
                                  name,
                                  price
                                }
                              ]
                            })

                            setData({
                              ...data,
                              fees: [...data.fees, { name, price }]
                            })
                            formFee.resetFields()
                          } catch (err) {
                            message.error(
                              'Ocorreu algum erro ao tentar cadastrar uma taxa'
                            )
                          }
                        }
                      }}
                    >
                      Cadastrar
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <SortableList
              isFee
              items={data.fees}
              pressDelay={200}
              onSortEnd={({ oldIndex, newIndex }) => {
                var newPosition = arrayMove(data.fees, oldIndex, newIndex)

                setData({ ...data, fees: newPosition })

                api
                  .patch(`/property/${id}/fees/position`, {
                    data: newPosition
                  })
                  .catch(() => {
                    message.error('Ocorreu um erro ao tentar mudar a posição')
                  })
              }}
            />
          </TabPane>
          <TabPane tab="Imagens" key="3">
            <Upload
              action={`${
                process.env.REACT_APP_ENDPOINT_API ||
                'https://core-api.advqxs.easypanel.host'
              }/property/${id}/images`}
              method="PUT"
              headers={{
                Authorization: `Bearer ${getToken()}`
              }}
              multiple
              onChange={e => {
                if (e.file.status === 'done') {
                  getData(false)
                  message.success('Imagem enviada com sucesso')
                }
              }}
            >
              <Button>Clique para fazer upload</Button>
            </Upload>
            <Button
              type="primary"
              className="float-right"
              onClick={() => setLightboxOpened(true)}
            >
              Abrir na galeria
            </Button>
            <br />
            <SortableList
              isImage
              items={data.images}
              pressDelay={200}
              onSortEnd={({ oldIndex, newIndex }) => {
                var newPosition = arrayMove(data.images, oldIndex, newIndex)

                setData({ ...data, images: newPosition })

                api
                  .patch(`/property/${id}/images/position`, {
                    data: newPosition
                  })
                  .catch(() => {
                    message.error('Ocorreu um erro ao tentar mudar a posição')
                  })
              }}
            />
          </TabPane>
          <TabPane tab="Visitas" key="8">
            <Table
              columns={columns}
              dataSource={data.schedules}
              locale={{ emptyText: 'Não há agendamentos feitos neste imóvel' }}
              loading={loading}
            />
          </TabPane>
        </Tabs>
      </Card>
      {data.images.length > 0 && lightboxOpened && (
        <Lightbox
          mainSrc={data.images.map(el => el.url)[photoIndex]}
          nextSrc={
            data.images.map(el => el.url)[(photoIndex + 1) % data.images.length]
          }
          prevSrc={
            data.images.map(el => el.url)[
              (photoIndex + data.images.length - 1) % data.images.length
            ]
          }
          onCloseRequest={() => setLightboxOpened(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + data.images.length - 1) % data.images.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % data.images.length)
          }
        />
      )}
    </AppLayout>
  )
}

export default PropertiesInfo
