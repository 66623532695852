import { getPermissions } from '../../guard/auth'

const script = {
  all: () => {
    return getPermissions().map(item => item.permission)
  },
  has: value => {
    var permissions = script.all()

    if (permissions.includes('*')) {
      return true
    }

    return permissions.includes(value)
  },
  variables: [
    {
      name: 'Visualizar boleto',
      value: 'BOLETO_VIEW'
    },
    {
      name: 'Gerar boleto',
      value: 'BOLETO_CREATE'
    },
    {
      name: 'Dar baixa em boleto',
      value: 'BOLETO_DOWN'
    },
    {
      name: 'Visualizar suporte',
      value: 'SUPPORT_VIEW'
    },
    {
      name: 'Responder suporte',
      value: 'SUPPORT_REPLY'
    },
    {
      name: 'Atualizar suporte',
      value: 'SUPPORT_UPDATE'
    },
    {
      name: 'Deletar suporte',
      value: 'SUPPORT_DELETE'
    },
    {
      name: 'Visualizar contato',
      value: 'CONTACT_VIEW'
    },
    {
      name: 'Deletar contato',
      value: 'CONTACT_DESTROY'
    },
    {
      name: 'Visualizar template de contrato',
      value: 'CONTRACT_TEMPLATE_VIEW'
    },
    {
      name: 'Criar template de contrato',
      value: 'CONTRACT_TEMPLATE_CREATE'
    },
    {
      name: 'Editar template de contrato',
      value: 'CONTRACT_TEMPLATE_EDIT'
    },
    {
      name: 'Deletar template de contrato',
      value: 'CONTRACT_TEMPLATE_DESTROY'
    },
    {
      name: 'Publicar notícia',
      value: 'FEED_CREATE'
    },
    {
      name: 'Visualizar notícias',
      value: 'FEED_VIEW'
    },
    {
      name: 'Editar notícia',
      value: 'FEED_EDIT'
    },
    {
      name: 'Deletar notícia',
      value: 'FEED_DESTROY'
    },
    {
      name: 'Visualizar financeiro',
      value: 'FINANCIAL_VIEW'
    },
    {
      name: 'Visualizar repasses financeiras',
      value: 'FINANCIAL_TRANSFER_VIEW'
    },
    {
      name: 'Criar repasses financeiras',
      value: 'FINANCIAL_TRANSFER_CREATE'
    },
    {
      name: 'Atualizar repassses financeiros',
      value: 'FINANCIAL_TRANSFER_EDIT'
    },
    {
      name: 'Visualizar atrasos financeiros',
      value: 'FINANCIAL_SHALL_VIEW'
    },
    {
      name: 'Atualizar atrasos financeiros',
      value: 'FINANCIAL_SHALL_UPDATE'
    },
    {
      name: 'Visualizar relatórios financeiros',
      value: 'FINANCIAL_REPORTS_VIEW'
    },
    {
      name: 'Visualizar comissões financeiras',
      value: 'FINANCIAL_COMMISSION_VIEW'
    },
    {
      name: 'Visualizar faturas financeiras',
      value: 'FINANCIAL_INVOICE_VIEW'
    },
    {
      name: 'Gerar fatura financeira',
      value: 'FINANCIAL_INVOICE_CREATE'
    },
    {
      name: 'Visualizar caixa financeiro',
      value: 'FINANCIAL_MOVIMENTATION_VIEW'
    },
    {
      name: 'Criar movimentação no caixa',
      value: 'FINANCIAL_MOVIMENTATION_CREATE'
    },
    {
      name: 'Visualizar notificações do sistema',
      value: 'SYSTEM_NOTIFICATION_VIEW'
    },
    {
      name: 'Criar notificações do sistema',
      value: 'SYSTEM_NOTIFICATION_CREATE'
    },
    {
      name: 'Editar notificações do sistema',
      value: 'SYSTEM_NOTIFICATION_EDIT'
    },
    {
      name: 'Deletar notificações do sistema',
      value: 'SYSTEM_NOTIFICATION_DESTROY'
    },
    {
      name: 'Visualizar propriedades',
      value: 'PROPERTY_VIEW'
    },
    {
      name: 'Visualizar requisições de propriedades',
      value: 'PROPERTY_REQUEST_VIEW'
    },
    {
      name: 'Criar propriedade',
      value: 'PROPERTY_CREATE'
    },
    {
      name: 'Editar propriedade',
      value: 'PROPERTY_EDIT'
    },
    {
      name: 'Deletar propriedade',
      value: 'PROPERTY_REMOVE'
    },
    {
      name: 'Criar contrato',
      value: 'PROPOSAL_CREATE'
    },
    {
      name: 'Visualizar contrato',
      value: 'PROPOSAL_VIEW'
    },
    {
      name: 'Editar contrato',
      value: 'PROPOSAL_EDIT'
    },
    {
      name: 'Upload de arquivos no contrato',
      value: 'PROPOSAL_UPLOAD'
    },
    {
      name: 'Atualizar contrato',
      value: 'PROPOSAL_UPDATE'
    },
    {
      name: 'Deletar contrato',
      value: 'PROPOSAL_DELETE'
    },
    {
      name: 'Visualizar agendamentos',
      value: 'SCHEDULE_VIEW'
    },
    {
      name: 'Editar agendamento',
      value: 'SCHEDULE_EDIT'
    },
    {
      name: 'Criar novo usuário/cliente',
      value: 'USER_CREATE'
    },
    {
      name: 'Criar grupo de permissões',
      value: 'ADMIN_GROUP_CREATE'
    },
    {
      name: 'Criar usuário administrativo',
      value: 'ADMIN_USER_CREATE'
    },
    {
      name: 'Visualizar grupos de permissões',
      value: 'GROUP_VIEW'
    },
    {
      name: 'Visualizar usuário administrativo',
      value: 'ADMIN_USER_VIEW'
    },
    {
      name: 'Deletar usuário administrativo',
      value: 'ADMIN_USER_DESTROY'
    },
    {
      name: 'Deletar grupo de permissões',
      value: 'GROUP_DESTROY'
    },
    {
      name: 'Editar cliente',
      value: 'CLIENT_EDIT'
    },
    {
      name: 'Visualizar cliente',
      value: 'CLIENT_VIEW'
    },
    {
      name: 'Deletar cliente',
      value: 'CLIENT_DESTROY'
    }
  ]
}

export default script
