import React, { createContext, useState, useEffect } from 'react'
import * as SecureLS from 'secure-ls'

export const PageContext = createContext()

const ls = new SecureLS({
  encodingType: 'aes',
  encryptionSecret: '90c14285-ab2d-4625-adfb-bb30f6e79dc8'
})

const SESSION_NAME = 'MB@History'

function PageProvider({ children }) {
  const [pages, setPages] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const session = ls.get(SESSION_NAME)

    if (!session) {
      ls.set(SESSION_NAME, [])
    } else {
      setPages(session)

      console.log('Old session', session)
    }

    setLoading(false)
  }, [])

  function get(page, value) {
    if (loading) {
      return null
    }

    const data = pages.filter(el => el.page === page)

    if (data.length === 0) {
      var newpage = pages

      var obj = {}
      obj[value] = ''

      newpage.push({
        page,
        fields: {
          ...obj
        }
      })

      setPages(newpage)
      ls.set(SESSION_NAME, newpage)

      return null
    }

    return data[0].fields[value] || (value === 'search' ? '' : null)
  }

  function update(page, newValues) {
    var idx = pages.findIndex(el => el.page === page)
    var updatedPages = pages

    if (!idx && idx < 0) {
      idx = pages.length

      updatedPages[idx] = {
        page,
        fields: newValues
      }
    } else {
      updatedPages[idx] = {
        ...updatedPages[idx],
        fields: {
          ...updatedPages[idx].fields,
          ...newValues
        }
      }
    }

    console.log('Novos dados', updatedPages)

    setPages(_prevPages => [...updatedPages])
  }

  useEffect(() => {
    ls.set(SESSION_NAME, pages)
  }, [pages])

  return (
    <PageContext.Provider
      value={{
        pages,
        setPages,
        get,
        update
      }}
    >
      {children}
    </PageContext.Provider>
  )
}

export default PageProvider
