import React from 'react'

import PropertiesProvider from '../providers/PropertiesProvider'
import PageProvider from './Page'

function Contexts({ children }) {
  return (
    <PageProvider>
      <PropertiesProvider>{children}</PropertiesProvider>
    </PageProvider>
  )
}

export default Contexts
