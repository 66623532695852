import { Button, Flex } from '@chakra-ui/react'
import React from 'react'
import { AiOutlineDelete } from 'react-icons/ai'
import { VscSymbolFile } from 'react-icons/vsc'

function FileListItem({ file, index, remove }) {
  return (
    <Flex align="center" justifyContent="space-between">
      <Flex align="center">
        <VscSymbolFile color="#cbd4db" size="16px" />
        <a
          href={file.url}
          style={{
            fontSize: 13,
            marginLeft: 4,
            cursor: 'pointer',
            color: '#14aaf5',
            marginRight: 10
          }}
          target="blank"
        >
          Anexo {index} - {file.source}
        </a>
      </Flex>
      <Button
        bg="transparent"
        size="sm"
        borderRadius="100px"
        w="30px"
        h="30px"
        p="1"
        onClick={remove}
      >
        <AiOutlineDelete size="15px" />
      </Button>
    </Flex>
  )
}

export default FileListItem
