import { Line, Pie } from '@ant-design/charts'
import { Grid } from '@chakra-ui/react'
import { Typography, Row, Col, Card } from 'antd'
import React, { useState } from 'react'
import uniqid from 'uniqid'

import PropertyPreview from '../../components/Property/Preview'
import AppLayout from '../../layouts/AppLayout'
import api from '../../services/api'

const { Title } = Typography

function Home() {
  const [dash, setDash] = useState(null)
  const [loading, setLoading] = useState(true)

  const [data, setData] = useState([])
  const [dataPie, setDataPie] = useState([])

  const config = {
    data,
    title: {
      visible: false
    },
    xField: 'date',
    yField: 'value',
    seriesField: 'type',
    responsive: true
  }

  const configPie = {
    forceFit: true,
    title: {
      visible: false
    },
    description: {
      visible: false
    },
    radius: 0.8,
    data: dataPie,
    angleField: 'visitas',
    colorField: 'type',
    label: {
      visible: true,
      type: 'inner'
    }
  }

  React.useEffect(() => {
    getData()
  }, [])

  async function getData() {
    setLoading(true)

    try {
      const { data: resultData } = await api.get('/dashboard')

      setDash({
        popular: resultData.popular.map(el => ({
          key: el.id,
          ...el,
          address: `${el.street}, n° ${el.number}, ${
            el.complement && ` / ${el.complement}`
          } , ${el.neighborhood} - ${el.city}`
        }))
      })

      setData(resultData.resume)

      var pieChart = []

      resultData.popular.forEach(el => {
        if (pieChart.length < 6) {
          pieChart.push({
            type: `Imóvel ${el.id}`,
            visitas: el.views
          })
        }
      })

      setDataPie(pieChart)
    } catch (err) {
      alert('Ocorreu um erro ao tentar recuperar dados')
    }

    setLoading(false)
  }

  return (
    <AppLayout title="Dashboard" keyPage="1">
      <Row gutter={25}>
        <Col md={16} span={24}>
          <Card>
            <Title>Resumo</Title>
            <Line {...config} loading={loading} />
          </Card>
        </Col>
        <Col md={8} span={24}>
          <Card>
            <Title level={3}>Imóveis em alta</Title>
            <br />
            <Pie {...configPie} loading={loading} />
          </Card>
        </Col>
      </Row>
      <br />
      <Card title="Alta possibilidade de locação">
        <Grid templateColumns="repeat(4, 1fr)" gap={8}>
          {dash &&
            dash.popular &&
            dash.popular.map(property => (
              <PropertyPreview key={uniqid()} data={property} />
            ))}
        </Grid>
      </Card>
    </AppLayout>
  )
}

export default Home
