import { useDisclosure } from '@chakra-ui/react'
import { Table, Tabs, Card, Tag, Button, message } from 'antd'
import React, { useState, useEffect } from 'react'

import 'react-image-lightbox/style.css'

import ClientModal from '../../components/Client/Modal'
import PropertyModal from '../../components/Property/Modal'
import AppLayout from '../../layouts/AppLayout'
import api from '../../services/api'

const { TabPane } = Tabs

const status = [
  {
    name: 'PENDING',
    color: 'gold',
    translate: 'Pendente'
  },
  {
    name: 'CONFIRMED',
    color: 'green',
    translate: 'Confirmado'
  },
  {
    name: 'USING',
    color: 'purple',
    translate: 'Em visita'
  },
  {
    name: 'RETURNED',
    color: 'geekblue',
    translate: 'Retornado / Finalizado'
  },
  {
    name: 'CANCELED',
    color: 'red',
    translate: 'Cancelado'
  }
]

function Schedules() {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)
  const [selected, setSelected] = useState()
  const [propertySelected, setPropertySelected] = useState()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isPropertyOpen,
    onOpen: onPropertyOpen,
    onClose: onPropertyClose
  } = useDisclosure()

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      responsive: ['md'],
      sorter: (a, b) => a.id - b.id,
      render: text => text
    },
    {
      title: 'Imóvel',
      dataIndex: 'property_id',
      key: 'property_id',
      render: text => (
        // eslint-disable-next-line
        <a
          href="#"
          onClick={e => {
            e.preventDefault()

            setPropertySelected(text)
            onPropertyOpen()
          }}
        >
          {text}
        </a>
      )
    },
    {
      title: 'Usuário',
      render: (text, record) => (
        // eslint-disable-next-line
        <a
          href="#"
          onClick={e => {
            e.preventDefault()

            setSelected(record.user.id)
            onOpen()
          }}
        >
          {record.user.name.split(' ')[0] +
            ' ' +
            record.user.name.split(' ')[1] || ''}
        </a>
      )
    },
    {
      title: 'Data',
      dataIndex: 'date',
      key: 'date',
      render: text => new Date(text).toLocaleDateString('pt-BR')
    },
    {
      title: 'Horário',
      dataIndex: 'shift',
      key: 'shift',
      responsive: ['md'],
      render: text => (text === 'AM' ? 'Manhã' : 'Tarde')
    },
    {
      title: 'Check-In',
      dataIndex: 'checkIn',
      key: 'checkIn',
      render: (text, record) =>
        text ? (
          new Date(text).toLocaleString('pt-BR')
        ) : record.status === 'CONFIRMED' ? (
          <Button onClick={() => updateCheck(record.id, 'checkIn')}>
            Marcar
          </Button>
        ) : (
          'Aguarde'
        )
    },
    {
      title: 'Check-Out',
      dataIndex: 'checkOut',
      key: 'checkOut',
      render: (text, record) =>
        text ? (
          new Date(text).toLocaleString('pt-BR')
        ) : record.status === 'USING' ? (
          <Button onClick={() => updateCheck(record.id, 'checkOut')}>
            Marcar
          </Button>
        ) : (
          'Aguarde'
        )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: text => (
        <Tag color={status.find(el => el.name === text).color}>
          {status.find(el => el.name === text).translate}
        </Tag>
      )
    },
    {
      title: 'Ações',
      render: (text, record) => {
        return (
          <>
            {record.status === 'PENDING' && (
              <Button
                type="primary"
                onClick={() => updateStatus(record.id, 'CONFIRMED')}
                className="mr-2"
              >
                Confirmar
              </Button>
            )}
            {(record.status === 'PENDING' || record.status === 'CONFIRMED') && (
              <Button
                type="danger"
                onClick={() => updateStatus(record.id, 'CANCELED')}
              >
                Cancelar
              </Button>
            )}
          </>
        )
      }
    }
  ]

  useEffect(() => {
    getData()
  }, [])

  async function getData() {
    setLoading(true)

    try {
      const { data: schedules } = await api.get('/schedule/admin')

      setData(schedules)
    } catch (err) {
      message.error('Ocorreu um erro ao buscar os dados')
    }

    setLoading(false)
  }

  async function updateStatus(id, status) {
    setLoading(true)

    try {
      await api.patch(`/schedule/admin/${id}/${status}/status`)

      getData()
    } catch (err) {
      message.error('Ocorreu um erro ao tentar cancelar o agendamento')
    }

    setLoading(false)
  }

  async function updateCheck(id, type) {
    setLoading(true)

    try {
      await api.patch(`/schedule/admin/${id}/${type}/date`)

      getData()
    } catch (err) {
      message.error('Ocorreu um erro ao tentar efetuar ' + type)
    }

    setLoading(false)
  }

  return (
    <AppLayout title="Agendamentos" keyPage="13">
      <ClientModal isOpen={isOpen} onClose={onClose} clientId={selected} />
      <PropertyModal
        isOpen={isPropertyOpen}
        onClose={onPropertyClose}
        id={propertySelected}
      />
      <Card title="Gerencie os agendamentos">
        <Tabs defaultActiveKey="1">
          <TabPane tab="Em andamento" key="1">
            <Table
              columns={columns}
              dataSource={
                data && data.progress.map(el => ({ ...el, key: el.id }))
              }
              locale={{ emptyText: 'Não há agendamentos em andamento' }}
              loading={loading}
            />
          </TabPane>
          <TabPane tab="Histórico" key="2">
            <Table
              columns={columns}
              dataSource={
                data && data.history.map(el => ({ ...el, key: el.id }))
              }
              locale={{ emptyText: 'Não há histórico de agendamentos' }}
              loading={loading}
            />
          </TabPane>
        </Tabs>
      </Card>
    </AppLayout>
  )
}

export default Schedules
