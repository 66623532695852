import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useToast,
  Text,
  Select as ChakraSelect,
  Box,
  Input,
  Grid,
  Textarea
} from '@chakra-ui/react'
import { mask, unMask } from 'node-masker'
import React, { useCallback, useEffect, useState } from 'react'
import Select from 'react-select'
import { useImmer } from 'use-immer'

import useClient from '../../../hooks/use-client'
import api from '../../../services/api'
import brasilApi from '../../../services/brasil-api'

function BanksCreateModal({ isOpen, onClose }) {
  const toast = useToast()

  const { clientId, updateBanks } = useClient()

  const [banks, setBanks] = useState([])
  const [banksLoading, setBanksLoading] = useState(false)

  const [loading, setLoading] = useState(false)

  const [bank, setBank] = useState()
  const [form, updateForm] = useImmer({
    holder_name: '',
    account_number: '',
    account_type: 'CORRENTE',
    agency_number: '',
    operation_number: '',
    document: '',
    pix_key: '',
    obs: ''
  })

  const getBanks = useCallback(async () => {
    setBanksLoading(true)

    try {
      const { data } = await brasilApi.get('/banks/v1')

      setBanks(data)
    } catch {
      toast({
        title: 'Oopss...',
        description: 'Não foi possível retornar os bancos',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }

    setBanksLoading(false)
  }, [toast, setBanks])

  function handleInputChange(event) {
    var { name, value } = event.target
    if (name === 'document') {
      value = unMask(value)
    }

    updateForm(draft => {
      draft[name] = value
    })
  }

  async function submit() {
    setLoading(true)

    try {
      const { data } = await api.post(`/user/${clientId}/bank`, {
        ...form,
        code: bank.value,
        bank_name: bank.label,
        document: mask(form.document, ['999.999.999-99', '99.999.999/9999-99'])
      })

      updateBanks(draft => {
        return Object.assign(draft, data)
      })

      setBank(null)

      updateForm(draft => {
        return Object.assign(draft, {
          holder_name: '',
          account_number: '',
          account_type: 'CORRENTE',
          agency_number: '',
          operation_number: '',
          document: '',
          pix_key: '',
          obs: ''
        })
      })

      onClose()
    } catch {
      toast({
        title: 'Oopss...',
        description: 'Não foi possível adicionar a conta bancária',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }

    setLoading(false)
  }

  useEffect(() => {
    getBanks()
  }, [isOpen, getBanks])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="md"
      motionPreset="slideInRight"
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Adicionar conta bancária</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box mb={2}>
            <Text>Selecione um banco</Text>
            <Select
              placeholder="Buscar..."
              value={bank}
              onChange={setBank}
              noOptionsMessage={() => 'Banco não encontrado'}
              isDisabled={banksLoading}
              isLoading={banksLoading}
              options={banks.map(bank => ({
                value: bank.code,
                label: bank.name,
                data: bank
              }))}
            />
          </Box>
          <Grid templateColumns="repeat(2, 1fr)" gap={4} mb={2}>
            <Box>
              <Text>Titular</Text>
              <Input
                name="holder_name"
                value={form.holder_name}
                onChange={handleInputChange}
              />
            </Box>
            <Box>
              <Text>
                Documento{' '}
                <span
                  style={{
                    color: 'var(--chakra-colors-gray-400)',
                    fontSize: 12
                  }}
                >
                  (CPF ou CNPJ)
                </span>
              </Text>
              <Input
                name="document"
                value={mask(form.document, [
                  '999.999.999-99',
                  '99.999.999/9999-99'
                ])}
                onChange={handleInputChange}
              />
            </Box>
          </Grid>
          <Box mb={2}>
            <Text>Chave PIX</Text>
            <Input
              name="pix_key"
              value={form.pix_key}
              onChange={handleInputChange}
            />
          </Box>
          <Grid templateColumns="repeat(3, 1fr)" gap={4} mb={2}>
            <Box>
              <Text>Nº Conta</Text>
              <Input
                name="account_number"
                value={form.account_number}
                onChange={handleInputChange}
              />
            </Box>
            <Box>
              <Text>Agência</Text>
              <Input
                type="number"
                name="agency_number"
                value={form.agency_number}
                onChange={handleInputChange}
              />
            </Box>
            <Box>
              <Text>
                Operação{' '}
                <span
                  style={{
                    color: 'var(--chakra-colors-gray-400)',
                    fontSize: 12
                  }}
                >
                  (opcional)
                </span>
              </Text>
              <Input
                type="number"
                name="operation_number"
                value={form.operation_number}
                onChange={handleInputChange}
              />
            </Box>
          </Grid>
          <Box mb={2}>
            <Text>Tipo da conta</Text>
            <ChakraSelect
              name="account_type"
              value={form.account_type}
              onChange={handleInputChange}
            >
              <option value="CORRENTE">Corrente</option>
              <option value="POUPANCA">Poupança</option>
            </ChakraSelect>
          </Box>
          <Box>
            <Text>
              Observações{' '}
              <span
                style={{
                  color: 'var(--chakra-colors-gray-400)',
                  fontSize: 12
                }}
              >
                (opcional)
              </span>
            </Text>
            <Textarea
              name="obs"
              value={form.obs}
              onChange={handleInputChange}
              rows={3}
            />
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancelar
          </Button>
          <Button
            colorScheme="whatsapp"
            isLoading={loading}
            isDisabled={loading}
            onClick={submit}
          >
            Adicionar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default BanksCreateModal
