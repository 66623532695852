import {
  Box,
  FormControl,
  FormLabel,
  Heading,
  Input,
  FormHelperText,
  Grid,
  Select
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import InputMask from 'react-input-mask'

import useClient from '../../hooks/use-client'

function ClientForm() {
  const { data, updateData, setUpdating, setUpdatingUser } = useClient()

  const [personType, setPersonType] = useState()

  function handleUserChange(event) {
    const { name, value } = event.target

    var toAssign = {
      ...JSON.parse(JSON.stringify(data))
    }

    toAssign.user[name] = value

    setUpdating(true)
    setUpdatingUser(true)

    updateData(draft => {
      return Object.assign(draft, toAssign)
    })
  }

  useEffect(() => {
    if (data && data.user) {
      setPersonType(data.user.cpf_cnpj.length > 14 ? 'PJ' : 'PF')
    }
  }, [data])

  return (
    <Grid templateColumns="2fr 1fr" gap="60px">
      <Box p="0 5">
        <Grid templateColumns="45% 25% calc(30% - 30px)" gap="15px" mb={3}>
          <FormControl id="name" mb={2} isRequired>
            <FormLabel>Nome Completo</FormLabel>
            <Input
              name="name"
              value={data.user && data.user.name}
              onChange={handleUserChange}
            />
          </FormControl>
          <FormControl id="personType" isRequired>
            <FormLabel>Pessoa</FormLabel>
            <Select
              onChange={e => setPersonType(e.target.value)}
              value={personType}
            >
              <option value="PF">Pessoa Física</option>
              <option value="PJ">Pessoa Jurídica</option>
            </Select>
          </FormControl>
          <FormControl id="document" isRequired>
            <FormLabel>Documento</FormLabel>
            <InputMask
              mask={
                personType === 'PF' ? '999.999.999-99' : '99.999.999/9999-99'
              }
              name="cpf_cnpj"
              value={data.user && data.user.cpf_cnpj}
              onChange={handleUserChange}
            >
              {inputProps => <Input {...inputProps} />}
            </InputMask>
          </FormControl>
        </Grid>
        <FormControl id="phone" mb={6} isRequired>
          <FormLabel>Telefone</FormLabel>
          <InputMask
            mask="(99) 99999-9999"
            name="phone"
            value={data.user && data.user.phone}
            onChange={handleUserChange}
          >
            {inputProps => <Input {...inputProps} />}
          </InputMask>
        </FormControl>
      </Box>
      <Box p="0 5">
        <Heading size="md" mb={4} isRequired>
          Dados de acesso
        </Heading>
        <FormControl id="email" mb={3}>
          <FormLabel>E-mail</FormLabel>
          <Input
            type="email"
            name="email"
            value={data.user && data.user.email}
            onChange={handleUserChange}
          />
        </FormControl>
        <FormControl id="password" mb={3}>
          <FormLabel>Nova senha</FormLabel>
          <Input
            type="password"
            name="password"
            value={(data.user && data.user.password) || ''}
            onChange={handleUserChange}
          />
          <FormHelperText>
            Só informe este campo se precisar trocar a senha do usuário
          </FormHelperText>
        </FormControl>
      </Box>
    </Grid>
  )
}

export default ClientForm
