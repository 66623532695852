import styled from 'styled-components'

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #001529;
  min-height: 100vh;
`
export const Card = styled.div`
  margin-top: 35px;
  max-width: 420px;
  padding: 45px;
  background: #fff;
  border-radius: 10px;

  h1 {
    text-align: center;
    font-weight: bold;
    margin-bottom: 20px;
  }
`
