import axios from 'axios'

// const token = 'e3dc201e130c2af8c38c0838ee3a382b'
const token = 'bf21864c51d75ac365c7401d5b65f229'
const api = axios.create({ baseURL: 'http://api.positionstack.com/v1/' })

export default async query => {
  const {
    data: { data }
  } = await api
    .get('/forward', {
      params: {
        access_key: token,
        query,
        limit: 1,
        output: 'json'
      }
    })
    .catch(err => console.log('Erro na Geolocalização', err.response))

  const { latitude, longitude } = data[0]

  return { latitude, longitude }
}
