import React from 'react'

import InvoicesContent from '../../components/Invoices/Content'
import AppLayout from '../../layouts/AppLayout'
import InvoiceProvider from '../../providers/InvoiceProvider'

function Invoices() {
  return (
    <AppLayout title="Faturas abertas" subKeyPage="sub1" keyPage="25">
      <InvoiceProvider>
        <InvoicesContent />
      </InvoiceProvider>
    </AppLayout>
  )
}

export default Invoices
