import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Flex,
  Button,
  Tooltip,
  IconButton,
  useDisclosure
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { AiOutlineHome, AiOutlineFieldTime } from 'react-icons/ai'
import { MdAttachMoney } from 'react-icons/md'
import { RiRestTimeLine } from 'react-icons/ri'
import { useHistory } from 'react-router-dom'
import uniqid from 'uniqid'

import useClient from '../../hooks/use-client'
import PropertyModal from '../Property/Modal'
import ClientProposalTransactions from './ProposalTransactions'

function ClientContracts() {
  const { data } = useClient()

  const history = useHistory()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [selected, setSelected] = useState()
  const [contract, setContract] = useState()

  return (
    <Box p="15px 0">
      <PropertyModal isOpen={isOpen} onClose={onClose} id={selected} />
      <ClientProposalTransactions
        isOpen={contract}
        selected={contract}
        onClose={() => {
          setContract(null)
        }}
      />
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>Imóvel</Th>
            <Th>Preço</Th>
            <Th>Data Inicial</Th>
            <Th>Próximo Reajuste</Th>
            <Th isNumeric>Mais opções</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.proposals &&
            data.proposals.map(proposal => (
              <Tr
                key={uniqid()}
                bg={
                  !proposal.renewed
                    ? 'red.100'
                    : proposal.delayed
                    ? 'orange.100'
                    : 'white'
                }
              >
                <Td>
                  <Tooltip
                    label={
                      !proposal.renewed
                        ? 'Contrato não foi reajustado!'
                        : proposal.delayed
                        ? 'Contrato em período de reajuste!'
                        : 'Contrato em dia.'
                    }
                    placement="right"
                    aria-label="A tooltip"
                  >
                    <Flex justifyContent="flex-start" alignItems="center">
                      {!proposal.renewed ? (
                        <AiOutlineFieldTime
                          size="18px"
                          color="black"
                          style={{ marginRight: 10 }}
                        />
                      ) : (
                        proposal.delayed && (
                          <RiRestTimeLine
                            size="18px"
                            color="black"
                            style={{ marginRight: 10 }}
                          />
                        )
                      )}
                      <Text>{proposal.id}</Text>
                    </Flex>
                  </Tooltip>
                </Td>
                <Td>
                  <Text isTruncated maxW="250px">
                    {proposal.property.street}, {proposal.property.neighborhood}{' '}
                    - {proposal.property.number} /{' '}
                    {proposal.property.complement}
                  </Text>
                </Td>
                <Td>
                  R$
                  {parseFloat(proposal.current_price).toLocaleString('pt-BR', {
                    minimumFractionDigits: 2
                  })}
                </Td>
                <Td>{dayjs(proposal.start_date).format('DD/MM/YYYY')}</Td>
                <Td>
                  {dayjs(proposal.next_readjustment).format('DD/MM/YYYY')}
                </Td>
                <Td isNumeric>
                  <IconButton
                    mr={3}
                    size="sm"
                    variant="ghost"
                    bg="white"
                    aria-label="Abrir imóvel"
                    icon={<AiOutlineHome />}
                    onClick={() => {
                      setSelected(proposal.property_id)
                      onOpen()
                    }}
                  />
                  <IconButton
                    mr={3}
                    size="sm"
                    variant="ghost"
                    bg="white"
                    aria-label="Abrir contas"
                    icon={<MdAttachMoney />}
                    onClick={() => {
                      setContract(proposal)
                    }}
                  />
                  <Button
                    size="sm"
                    variant="outline"
                    bg="white"
                    onClick={() =>
                      history.push(`/contracts/info/${proposal.id}`)
                    }
                  >
                    Abrir
                  </Button>
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </Box>
  )
}

export default ClientContracts
