import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Box,
  Center,
  useToast
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import Calendar from 'react-calendar'
import Select from 'react-select'

import 'react-calendar/dist/Calendar.css'
import './calendar.css'
import useInvoice from '../../hooks/use-invoice'
import api from '../../services/api'

const formatPrice = price => {
  const formatter = new Intl.NumberFormat('pt-BR', {
    currency: 'BRL'
  })

  return formatter.format(price)
}

function InvoicesCreate({ isOpen, onClose }) {
  const { users, getData } = useInvoice()

  const toast = useToast()

  const [user, setUser] = useState()
  const [proposal, setProposal] = useState()
  const [reference, setReference] = useState()

  const [loading, setLoading] = useState(false)

  const [proposals, setProposals] = useState([])

  function handleUser(user) {
    setUser(user)

    setProposal(null)
    setProposals(
      user.data.proposals.map(proposal => ({
        value: proposal.id,
        label: `Contrato #${proposal.id} - R$${formatPrice(
          proposal.current_price
        )}`,
        data: proposal
      }))
    )
  }

  async function handleSubmit() {
    if (!reference) {
      return toast({
        title: 'Oopss...',
        description: 'Informe o mês da fatura',
        status: 'info'
      })
    }

    setLoading(true)

    try {
      await api.post('/financial/invoice', {
        proposal_id: proposal.data.id,
        reference
      })

      await getData()

      toast({
        title: 'Pronto!',
        description: 'A fatura foi antecipada.'
      })

      setUser(null)
      setProposal(null)
      setReference(null)

      onClose()
    } catch (err) {
      toast({
        title: 'Oopss...',
        description: err.response.data.message,
        status: 'error'
      })
    }

    setLoading(false)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Antecipar fatura</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box mb={3}>
            <Text>Selecione o cliente</Text>
            <Select
              placeholder="Selecione..."
              value={user}
              onChange={handleUser}
              noOptionsMessage={() => 'Cliente não encontrado'}
              options={users.map(user => ({
                value: user.id,
                label: user.name,
                data: user
              }))}
            />
          </Box>
          <Box mb={5}>
            <Text>Contrato</Text>
            <Select
              placeholder={user ? 'Selecione...' : 'Selecione o cliente'}
              isDisabled={!user}
              noOptionsMessage={() => 'Contrato não encontrado'}
              value={proposal}
              onChange={value => {
                setProposal(value)
                setReference(null)
              }}
              options={proposals}
            />
          </Box>
          {!!proposal && (
            <Center>
              <Calendar
                defaultActiveStartDate={dayjs().toDate()}
                minDate={dayjs(
                  proposal.data.invoices.find(
                    invoice => invoice.status === 'PAID'
                  )
                    ? proposal.data.invoices.filter(
                        invoice => invoice.status === 'PAID'
                      )[0].reference + '-01'
                    : dayjs(proposal.data.start_date).toDate()
                ).toDate()}
                maxDate={dayjs(proposal.data.next_readjustment).toDate()}
                tileClassName={({ date }) => {
                  return proposal.data.invoices
                    .filter(invoice => invoice.status === 'PENDING')
                    .map(invoice => invoice.reference)
                    .includes(dayjs(date).format('YYYY-MM'))
                    ? 'pending-invoice'
                    : ''
                }}
                locale="pt-BR"
                defaultView="year"
                view="year"
                onClickMonth={setReference}
                value={reference}
              />
            </Center>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="orange"
            isDisabled={!user || !proposal || !reference || loading}
            isLoading={loading}
            onClick={handleSubmit}
          >
            Antecipar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default InvoicesCreate
