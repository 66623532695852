import {
  Table,
  DatePicker,
  Row,
  Col,
  Card,
  Statistic,
  Popover,
  message,
  Button
} from 'antd'
import React, { useState } from 'react'
import { CSVLink } from 'react-csv'
import { Link } from 'react-router-dom'

import AppLayout from '../../layouts/AppLayout'
import api from '../../services/api'

function FinancialComissions() {
  const [loading, setLoading] = useState(false)

  const [period, setPeriod] = useState(null)

  const [data, setData] = useState({
    sum: 0,
    data: []
  })

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Contrato',
      dataIndex: 'proposal_id',
      key: 'proposal_id',
      render: (text, record) => (
        <Popover
          placement="right"
          content={
            <>
              <h6 className="m-0">Inquilino:</h6>
              <p className="font-weight-light m-0">{record.owner}</p>
              <h6 className="m-0">Imóvel:</h6>
              <p className="font-weight-light m-0">
                {record.property.neighborhood} - {record.property.city}
              </p>
            </>
          }
        >
          <Link to={`/contracts/info/${text}`}>{text}</Link>
        </Popover>
      )
    },
    {
      title: 'Valor recebido',
      render: (_text, record) =>
        'R$' +
        parseFloat(record.price).toLocaleString('pt-BR', {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        })
    },
    {
      title: 'Percentual',
      dataIndex: 'percentage',
      key: 'percentage'
    },
    {
      title: 'Comissão',
      dataIndex: 'commission',
      key: 'commission',
      render: text => (
        <div style={{ color: 'green' }}>
          R$
          {text !== null
            ? parseFloat(text).toLocaleString('pt-BR', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              })
            : '0,00'}
        </div>
      )
    },
    {
      title: 'Taxas extras',
      dataIndex: 'extra',
      key: 'extra',
      render: text => (
        <div>
          R$
          {parseFloat(text).toLocaleString('pt-BR', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
          })}
        </div>
      )
    },
    {
      title: 'Valor repassado',
      dataIndex: 'transfer',
      key: 'transfer',
      render: text => (
        <div style={{ color: 'tomato' }}>
          R$
          {parseFloat(text).toLocaleString('pt-BR', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
          })}
        </div>
      )
    },
    {
      title: 'Vencimento da fatura',
      dataIndex: 'due_date',
      key: 'due_date',
      render: text => new Date(text).toLocaleDateString('pt-BR')
    },
    {
      title: 'Data de crédito',
      dataIndex: 'pay_day',
      key: 'pay_day',
      render: text => new Date(text).toLocaleDateString('pt-BR')
    }
  ]

  React.useEffect(() => {
    getData()
  }, [])

  const [csvData, setCsvData] = useState([])

  async function getData() {
    setLoading(true)

    try {
      const { data } = await api.get('/financial/commissions')

      setData(data)

      const csv = [
        [
          'ID',
          'ID do contrato',
          'Valor recebido',
          'Percentual',
          'Comissão',
          'Taxas extras',
          'Valor repassado',
          'Data de crédito'
        ]
      ]

      data.data.forEach(el => {
        csv.push([
          el.id,
          el.proposal_id,
          parseFloat(el.price).toLocaleString('pt-BR', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
          }),
          el.percentage,
          parseFloat(el.commission).toLocaleString('pt-BR', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
          }),
          parseFloat(el.extra).toLocaleString('pt-BR', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
          }),
          parseFloat(el.transfer).toLocaleString('pt-BR', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
          }),
          new Date(el.pay_day).toLocaleDateString('pt-BR')
        ])
      })

      setCsvData(csv)
    } catch (err) {
      console.log(err)
      message.error('Ocorreu um erro ao tentar recuperar os dados')
    }

    setLoading(false)
  }

  async function filter(values) {
    setLoading(true)

    try {
      const { data } = await api.post('/financial/commissions/filter', {
        period: values
      })

      setData(data)

      const csv = [
        [
          'ID',
          'ID do contrato',
          'Valor recebido',
          'Percentual',
          'Comissão',
          'Taxas extras',
          'Valor repassado',
          'Data de crédito'
        ]
      ]

      data.data.forEach(el => {
        csv.push([
          el.id,
          el.proposal_id,
          parseFloat(el.price).toLocaleString('pt-BR', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
          }),
          el.percentage,
          parseFloat(el.commission).toLocaleString('pt-BR', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
          }),
          parseFloat(el.extra).toLocaleString('pt-BR', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
          }),
          parseFloat(el.transfer).toLocaleString('pt-BR', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
          }),
          new Date(el.pay_day).toLocaleDateString('pt-BR')
        ])
      })

      // setCsvData(csv)
    } catch {
      message.error('Não foi possível recuperar os dados filtrados')
    }

    setLoading(false)
  }

  return (
    <AppLayout title="Relatório de comissão" keyPage="26" subKeyPage="sub1">
      <Row gutter={25}>
        <Col md={7}>
          <Card loading={loading}>
            <Statistic
              title="Receita"
              value={data.sum.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}
              precision={2}
              valueStyle={{ color: '#3f8700' }}
            />
          </Card>
        </Col>
        <Col md={7}>
          <Card loading={loading}>
            <Statistic
              title="Período"
              value={period ? 'Filtrado' : 'Todo período'}
              precision={2}
              valueStyle={{ color: '#e84118' }}
            />
          </Card>
        </Col>
        <Col md={10}>
          <Card>
            <h5>Filtre sua busca</h5>
            <DatePicker.RangePicker
              onChange={values => {
                setPeriod(values)
                filter(values)
              }}
            />
          </Card>
        </Col>
      </Row>
      <Card
        title="Comissão"
        bordered={false}
        className="mt-4"
        extra={
          <CSVLink data={csvData} filename="comissoes.csv">
            <Button type="primary">Baixar CSV</Button>
          </CSVLink>
        }
      >
        <Table
          dataSource={data && data.data.map(el => ({ key: el.id, ...el }))}
          columns={columns}
          locale={{ emptyText: 'Não há registros de comissões neste período' }}
          loading={loading}
          footer={() => (
            <>
              <Row gutter={25}>
                <Col md={4}>
                  <p className="font-weight-bold m-0">Valor total</p>
                  <h5 className="font-weight-light m-0">
                    R${' '}
                    {data
                      ? parseFloat(data.total).toLocaleString('pt-BR', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })
                      : '0,00'}
                  </h5>
                </Col>
                <Col md={4}>
                  <p className="font-weight-bold m-0">Valor repassado</p>
                  <h5 className="font-weight-light m-0">
                    R${' '}
                    {data
                      ? parseFloat(data.transfers).toLocaleString('pt-BR', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })
                      : '0,00'}
                  </h5>
                </Col>
                <Col md={4}>
                  <p className="font-weight-bold m-0">Comissão</p>
                  <h5 className="font-weight-light m-0">
                    R${' '}
                    {data
                      ? parseFloat(data.commissions).toLocaleString('pt-BR', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })
                      : '0,00'}
                  </h5>
                </Col>
              </Row>
            </>
          )}
        />
      </Card>
    </AppLayout>
  )
}

export default FinancialComissions
