import { Grid, useToast } from '@chakra-ui/react'
import dayjs from 'dayjs'
import React, { useCallback, useEffect, useState } from 'react'
import { StickyContainer } from 'react-sticky'

import TellerFilters from '../../components/Teller/Filters'
import TellerTable from '../../components/Teller/Table'
import AppLayout from '../../layouts/AppLayout'
import api from '../../services/api'

function FinancialMovimentations() {
  const toast = useToast()

  const [page, setPage] = useState(1)
  const [dates, setDates] = useState([
    dayjs().startOf('month').toDate(),
    dayjs().toDate()
  ])
  const [transaction, setTransaction] = useState('rents')
  const [loading, setLoading] = useState()
  const [data, setData] = useState()

  const getData = useCallback(async () => {
    if (!dates[0] || !dates[1]) return

    setLoading(true)

    try {
      const { data } = await api.get('/movimentation/' + transaction, {
        params: {
          page,
          startDate: dayjs(dates[0]).format('YYYY-MM-DD'),
          endDate: dayjs(dates[1]).format('YYYY-MM-DD')
        }
      })

      setData(data)
    } catch {
      toast({
        title: 'Oopss...',
        description: 'Não foi possível retornar os dados do gráfico',
        status: 'error',
        isClosable: true,
        duration: 4000
      })
    }

    setLoading(false)
  }, [dates, page, toast, transaction])

  useEffect(() => {
    getData()
  }, [getData, transaction, page, dates])

  return (
    <AppLayout title="Movimentações" keyPage="23" subKeyPage="sub1">
      <StickyContainer>
        <Grid
          pos="relative"
          templateColumns="380px calc(100% - 408px)"
          gap="24px"
          alignItems="flex-start"
        >
          <TellerFilters
            dates={dates}
            setDates={setDates}
            transaction={transaction}
            setTransaction={setTransaction}
            loading={loading}
            onUpdate={getData}
          />
          <TellerTable
            data={data}
            page={page}
            setPage={setPage}
            loading={loading}
            transaction={transaction}
            onUpdate={getData}
          />
        </Grid>
      </StickyContainer>
    </AppLayout>
  )
}

export default FinancialMovimentations
