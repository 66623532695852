import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  useToast,
  useDisclosure
} from '@chakra-ui/react'
import React, { useCallback, useEffect, useState, createContext } from 'react'
import { useImmer } from 'use-immer'

import api from '../services/api'

function ClientProvider({ clientId, children, onCloseModal }) {
  const toast = useToast()

  const {
    isOpen: isOpenDelete,
    onOpen: onDelete,
    onClose: onCloseDelete
  } = useDisclosure()

  const [loading, setLoading] = useState(true)
  const [data, updateData] = useImmer({})

  const [banks, updateBanks] = useImmer([])

  const [updating, setUpdating] = useState(false)
  const [updatingLoading, setUpdatingLoading] = useState(false)

  const [updatingUser, setUpdatingUser] = useState(false)
  const [updatingInformation, setUpdatingInformation] = useState(false)
  const [updatingAddress, setUpdatingAddress] = useState(false)

  const [deleteLoading, setDeleteLoading] = useState(false)

  const getData = useCallback(async () => {
    setLoading(true)

    try {
      const { data } = await api.get(`/user/${clientId}`)

      updateData(draft => {
        return Object.assign(draft, data)
      })
    } catch {
      toast({
        title: 'Oopss...',
        description: 'Não foi possível retornar os dados do cliente',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }

    setLoading(false)
  }, [clientId, toast, updateData])

  async function updateValues() {
    setUpdatingLoading(true)

    try {
      if (updatingUser) {
        await api.patch(`/user/${clientId}/user`, data.user)
      }

      if (updatingAddress) {
        await api.patch(`/user/${clientId}/address`, data.address)
      }

      if (updatingInformation) {
        var informationData = JSON.parse(JSON.stringify(data.information))

        if (!informationData.person_type) {
          informationData.person_type = 'FISICA'
        }

        await api.patch(`/user/${clientId}/information`, informationData)
      }

      toast({
        title: 'Pronto!',
        description: 'Dados atualizados',
        status: 'success',
        duration: 5000,
        isClosable: true
      })

      setUpdatingUser(false)
      setUpdatingInformation(false)
      setUpdatingAddress(false)

      setUpdating(false)
    } catch {
      toast({
        title: 'Oopss...',
        description: 'Não foi possível atualizar os dados do cliente',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }

    setUpdatingLoading(false)
  }

  async function destroy() {
    setDeleteLoading(true)

    try {
      await api.delete(`/user/${clientId}/admin`)

      toast({
        title: 'Pronto!',
        description: 'O usuário foi deletado',
        status: 'success',
        duration: 5000,
        isClosable: true
      })

      onCloseModal()
    } catch {
      toast({
        title: 'Oopss...',
        description: 'Não foi possível atualizar o cliente',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }

    setDeleteLoading(false)
  }

  useEffect(() => {
    if (clientId) {
      getData()
    }
  }, [clientId, getData])

  return (
    <ClientContext.Provider
      value={{
        clientId,
        loading,
        data,
        updateData,
        updating,
        setUpdating,
        updatingLoading,
        updateValues,
        updatingUser,
        setUpdatingUser,
        updatingInformation,
        setUpdatingInformation,
        updatingAddress,
        setUpdatingAddress,
        banks,
        updateBanks,
        onDelete
      }}
    >
      <AlertDialog isOpen={isOpenDelete} onClose={onCloseDelete}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Deletar cliente
            </AlertDialogHeader>

            <AlertDialogBody>
              Tem certeza que deseja deletar o cliente? Esta ação não poderá ser
              desfeita
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={onCloseDelete}>Cancelar</Button>
              <Button
                colorScheme="red"
                onClick={destroy}
                ml={3}
                isLoading={deleteLoading}
              >
                Deletar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      {children}
    </ClientContext.Provider>
  )
}

export const ClientContext = createContext({})
export default ClientProvider
