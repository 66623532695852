import { Modal, ModalOverlay } from '@chakra-ui/react'
import React from 'react'

import ClientProvider from '../../providers/ClientProvider'
import ClientModalContent from './ModalContent'

function ClientModal({ isOpen, onClose, clientId }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ClientProvider clientId={clientId} onCloseModal={onClose}>
        <ClientModalContent onClose={onClose} />
      </ClientProvider>
    </Modal>
  )
}

export default ClientModal
