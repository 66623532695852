import { useContext } from 'react'

import { PageContext } from '../Page'

function usePage() {
  const ctx = useContext(PageContext)

  return ctx
}
export default usePage
