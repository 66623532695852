import {
  Stat,
  StatLabel,
  StatNumber,
  StatGroup,
  Grid,
  Box,
  Heading,
  SkeletonCircle,
  Skeleton,
  Avatar,
  Select
} from '@chakra-ui/react'
import React from 'react'

import useClient from '../../hooks/use-client'

function ClientHeader() {
  const { loading, data, updateData, setUpdating, setUpdatingUser } =
    useClient()

  function handleUserChange(event) {
    const { name, value } = event.target

    var toAssign = {
      ...JSON.parse(JSON.stringify(data))
    }

    toAssign.user[name] = value

    setUpdatingUser(true)
    setUpdating(true)

    updateData(draft => {
      return Object.assign(draft, toAssign)
    })
  }

  return (
    <Grid templateColumns={'1fr 1fr'} alignItems="center" mb={4} gap="40px">
      <Box>
        {loading ? (
          <SkeletonCircle size="24" />
        ) : (
          <Avatar size="xl" name={data.user && data.user.name} />
        )}
        <Skeleton isLoaded={!loading}>
          <Heading size="md" mb={1} mt={4} isTruncated>
            {data.user ? data.user.name : '...'}
          </Heading>
        </Skeleton>
        <Skeleton isLoaded={!loading}>
          <Heading
            size="sm"
            color="gray.400"
            lineHeight="1.3"
            fontWeight="400"
            mb={4}
            isTruncated
          >
            {data.user ? data.user.email : '...'}
          </Heading>
        </Skeleton>
      </Box>
      <StatGroup>
        <Stat>
          <StatLabel>Imóveis relacionados</StatLabel>
          <Skeleton isLoaded={!loading}>
            <StatNumber>
              {data.properties ? data.properties.length : '...'}
            </StatNumber>
          </Skeleton>
        </Stat>
        <Stat>
          <StatLabel>Contratos ativos</StatLabel>
          <Skeleton isLoaded={!loading}>
            <StatNumber>
              {data.proposals ? data.proposals.length : '...'}
            </StatNumber>
          </Skeleton>
        </Stat>
        <Stat>
          <StatLabel>Tipo de cliente</StatLabel>
          <Skeleton isLoaded={!loading}>
            <Select
              size="md"
              name="type"
              value={data.user && data.user.type}
              onChange={handleUserChange}
            >
              <option value="OWNER">Proprietário</option>
              <option value="TENANT">Inquilino</option>
              <option value="GUARANTOR">Fiador</option>
            </Select>
          </Skeleton>
        </Stat>
      </StatGroup>
    </Grid>
  )
}

export default ClientHeader
