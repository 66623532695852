import { Table, Button, Card, Form, Input, message } from 'antd'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import ReactSummernote from 'react-summernote'
import 'react-summernote/dist/react-summernote.css' // import styles

import 'bootstrap/js/src/modal'
import 'bootstrap/js/src/dropdown'
import 'bootstrap/js/src/tooltip'
import 'bootstrap/dist/css/bootstrap.css'

import AppLayout from '../../layouts/AppLayout'
import api from '../../services/api'

function Blog() {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [file, setFile] = useState(null)
  const [body, setBody] = useState('')

  const [form] = Form.useForm()

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      responsive: ['md'],
      sorter: (a, b) => a.id - b.id
    },
    {
      title: 'Título',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: 'Visualizações',
      dataIndex: 'views',
      key: 'views'
    },
    {
      title: 'Publicado em',
      dataIndex: 'created_at',
      key: 'created_at',
      render: text => new Date(text).toLocaleString('pt-BR')
    },
    {
      title: 'Ações',
      render: (text, record) => {
        return (
          <>
            <Link to={`/blog/${record.id}`}>Editar</Link>
            <Button
              style={{ marginLeft: 20 }}
              type="danger"
              onClick={() => destroy(record.id)}
            >
              Deletar
            </Button>
          </>
        )
      }
    }
  ]

  useEffect(() => {
    getData()
  }, [])

  async function destroy(id) {
    try {
      await api.delete(`/feed/${id}`)
      getData()
    } catch (err) {
      message.error('Não foi possível deletar esta publicação')
    }
  }

  async function getData() {
    setLoading(true)

    try {
      const { data: feeds } = await api.get('/feed')

      setData(feeds.map(feed => ({ ...feed, key: feed.id })))
    } catch (err) {
      message.error('Ocorreu um erro ao buscar os dados')
    }

    setLoading(false)
  }

  async function create(values) {
    message.loading('Cadastrando...')

    var formData = new FormData()

    Object.keys(values).forEach(key => {
      if (key !== 'image') {
        formData.append(key, values[key])
      }
    })

    formData.append('image', file, file.name)
    formData.append('body', body)

    try {
      await api.post('/feed', formData)

      message.success('Notícia publicada!')

      form.resetFields()
      setBody('')

      getData()
    } catch (err) {
      message.error('Ocorreu um erro ao tentar criar uma nova publicação')
    }
  }

  return (
    <AppLayout title="Publicações" keyPage="15">
      <Card title="Publicar" style={{ marginBottom: 25 }}>
        <Form name="publish" form={form} onFinish={create} autoComplete="off">
          <Form.Item
            name="title"
            rules={[
              { required: true, message: 'Informe o título da notícia!' }
            ]}
          >
            <Input placeholder="Título da notícia" />
          </Form.Item>
          <Form.Item
            name="image"
            label="Imagem do cabeçalho"
            rules={[{ required: true, message: 'Insira uma imagem!' }]}
          >
            <Input type="file" onChange={e => setFile(e.target.files[0])} />
          </Form.Item>
          <Form.Item>
            <ReactSummernote
              value={body}
              options={{
                height: 350,
                dialogsInBody: true,
                toolbar: [
                  ['style', ['style']],
                  ['font', ['bold', 'underline', 'clear']],
                  ['fontname', ['fontname']],
                  ['para', ['ul', 'ol', 'paragraph']],
                  ['table', ['table']],
                  ['insert', ['link', 'picture', 'video']],
                  ['view', ['fullscreen', 'codeview']]
                ]
              }}
              onChange={setBody}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Cadastrar
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <Card title="Notícias publicas">
        <Table
          columns={columns}
          dataSource={data}
          locale={{ emptyText: 'Não há notificações cadastrados' }}
          loading={loading}
        />
      </Card>
    </AppLayout>
  )
}

export default Blog
