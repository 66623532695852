import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  FormControl,
  FormLabel,
  Input,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  SlideFade,
  Select,
  Box,
  Text,
  Flex,
  Tooltip,
  Heading,
  Button,
  Progress,
  Grid,
  Divider,
  Spinner,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import DebounceInput from 'react-debounce-input'
import InputMask from 'react-input-mask'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'
import uniqid from 'uniqid'

import api from '../../services/api'
import ClientCreate from '../Client/Create'

function PropertyCreate({ isOpen, onClose, onCreate }) {
  const toast = useToast()

  const locations = [
    ['APARTMENT', 'Apartamento'],
    ['ROOF', 'Cobertura'],
    ['HOUSE', 'Casa'],
    ['FARM', 'Fazenda'],
    ['CLINIC', 'Consultório'],
    ['SHED', 'Galpão'],
    ['GARAGE', 'Garagem'],
    ['KITNET', 'Kitnet'],
    ['LOT', 'Lote'],
    ['STORE', 'Loja'],
    ['COMERCIAL', 'Sala Comercial'],
    ['SITE', 'Sítio'],
    ['INDUSTRIA', 'Indústria'],
    ['CHACARA', 'Chácara'],
    ['COMERCIO', 'Comércio'],
    ['GALPAO', 'Galpão']
  ]

  const {
    onOpen: onOpenCreate,
    isOpen: isOpenCreate,
    onClose: onCloseCreate
  } = useDisclosure()

  const {
    isOpen: isClientsOpen,
    onOpen,
    onClose: onClientsClose
  } = useDisclosure()

  const [loading, setLoading] = useState(false)
  const [clientsLoading, setClientsLoading] = useState(false)

  const [clientSearch, setClientSearch] = useState('')
  const [clients, setClients] = useState([])

  const [cep, setCep] = useState()
  const [street, setStreet] = useState()
  const [neighborhood, setNeighborhood] = useState()
  const [city, setCity] = useState()
  const [state, setState] = useState()
  const [country, setCountry] = useState()
  const [number, setNumber] = useState()
  const [complement, setComplement] = useState()
  const [latitude, setLatitude] = useState()
  const [longitude, setLongitude] = useState()
  const [transaction, setTransaction] = useState()
  const [category, setCategory] = useState()
  const [clientId, setClientId] = useState()
  const [price, setPrice] = useState(0)

  const [tmpAddress, setTmpAddress] = useState('')

  async function create() {
    setLoading(true)

    try {
      const { data } = await api.post('property', {
        cep,
        street,
        neighborhood,
        city,
        state,
        country,
        complement,
        number,
        latitude,
        longitude,
        location: category,
        owner_id: clientId,
        type: transaction,
        price
      })

      if (onCreate) {
        onCreate(data)
      }

      setCep()
      setStreet()
      setNeighborhood()
      setCity()
      setState()
      setCountry()
      setNumber()
      setLongitude()
      setLatitude()
      setClientId()
      setComplement()
      setClientSearch()
      setClients([])

      setPrice(0)

      onClose()
    } catch (err) {
      const { errors } = err.response.data

      toast({
        title: 'Oopss...',
        description: errors[0].message,
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }

    setLoading(false)
  }

  function getAddressData(object, name) {
    const data = object.address_components
    const item = data.find(el => el.types.includes(name))

    return item ? item.long_name : null
  }

  async function handleAddress(address) {
    const place = await geocodeByAddress(address)

    setCep(getAddressData(place[0], 'postal_code'))
    setStreet(getAddressData(place[0], 'route'))
    setNeighborhood(getAddressData(place[0], 'sublocality'))
    setCity(getAddressData(place[0], 'administrative_area_level_2'))
    setState(getAddressData(place[0], 'administrative_area_level_1'))
    setCountry(getAddressData(place[0], 'country'))
    setNumber(getAddressData(place[0], 'street_number'))

    setLatitude(place[0].geometry.location.lat())
    setLongitude(place[0].geometry.location.lng())

    // if (!!place[0].geometry.bounds) {
    //   if (!!place[0].geometry.bounds["Sa"]) {
    //     setLatitude(place[0].geometry.bounds["Sa"].g);
    //   } else {
    //     setLatitude(place[0].geometry.bounds["Ra"].g);
    //   }
    //   setLongitude(place[0].geometry.bounds["La"].g);
    // }

    // if (!!place[0].geometry.viewport) {
    //   if (!!place[0].geometry.viewport["Sa"]) {
    //     setLatitude(place[0].geometry.viewport["Sa"].g);
    //   } else {
    //     setLatitude(place[0].geometry.viewport["Ra"].g);
    //   }
    //   setLongitude(place[0].geometry.viewport["La"].g);
    // }

    setTmpAddress(place[0].formatted_address)
  }

  const searchClients = useCallback(async () => {
    if (!clientSearch) {
      return
    }

    setClientsLoading(true)

    try {
      const { data } = await api.get('user', {
        params: { page: 1, query: encodeURIComponent(clientSearch) }
      })

      setClients(data.data)
    } catch {
      toast({
        title: 'Oopss...',
        description: 'Ocorreu um erro ao tentar buscar pelas categorias',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }

    setClientsLoading(false)
  }, [clientSearch, toast])

  useEffect(() => {
    if (clientSearch) {
      searchClients()
    }
  }, [clientSearch, searchClients])

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
      <ClientCreate isOpen={isOpenCreate} onClose={onCloseCreate} />
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Cadastrar novo imóvel</DrawerHeader>
          <DrawerBody>
            <PlacesAutocomplete
              value={tmpAddress}
              onChange={setTmpAddress}
              onSelect={handleAddress}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading
              }) => (
                <>
                  <Input
                    {...getInputProps({
                      placeholder:
                        'Digite o endereço para agilizarmos seu cadastro...'
                    })}
                  />
                  {(loading || suggestions.length > 0) && (
                    <Box p="2" bg="gray.50" borderRadius="5px">
                      {suggestions.map(suggestion => {
                        return (
                          <Tooltip
                            label={suggestion.description}
                            key={suggestion.placeId}
                          >
                            <Flex
                              {...getSuggestionItemProps(suggestion)}
                              direction="row"
                              cursor="pointer"
                              bg={suggestion.active ? 'blue.50' : 'white'}
                              my={2}
                              borderRadius="5px"
                              p="2"
                              transition="all ease .3s"
                            >
                              <Heading size="sm" color="gray.700" isTruncated>
                                {suggestion.description}
                              </Heading>
                            </Flex>
                          </Tooltip>
                        )
                      })}
                      {loading && <Progress size="xs" isIndeterminate />}
                    </Box>
                  )}
                </>
              )}
            </PlacesAutocomplete>
            <Divider my={5} />
            <FormControl id="name" mb={3} isRequired>
              <FormLabel>CEP / Código Postal</FormLabel>
              <InputMask
                value={cep}
                onChange={e => setCep(e.target.value)}
                mask={'99.999-999'}
              >
                {inputProps => <Input {...inputProps} />}
              </InputMask>
            </FormControl>
            <Grid templateColumns="45% calc(35% - 30px) 20%" gap="15px" mb={3}>
              <FormControl id="street" isRequired>
                <FormLabel>Logradouro</FormLabel>
                <Input
                  value={street}
                  onChange={e => setStreet(e.target.value)}
                />
              </FormControl>
              <FormControl id="neighborhood" isRequired>
                <FormLabel>Bairro</FormLabel>
                <Input
                  value={neighborhood}
                  onChange={e => setNeighborhood(e.target.value)}
                />
              </FormControl>
              <FormControl id="number">
                <FormLabel>Número</FormLabel>
                <Input
                  value={number}
                  onChange={e => setNumber(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid templateColumns="repeat(3, 1fr)" gap="15px" mb={3}>
              <FormControl id="city" isRequired>
                <FormLabel>Cidade</FormLabel>
                <Input value={city} onChange={e => setCity(e.target.value)} />
              </FormControl>
              <FormControl id="state" isRequired>
                <FormLabel>Estado</FormLabel>
                <Input value={state} onChange={e => setState(e.target.value)} />
              </FormControl>
              <FormControl id="country" isRequired>
                <FormLabel>País</FormLabel>
                <Input
                  value={country}
                  onChange={e => setCountry(e.target.value)}
                />
              </FormControl>
            </Grid>
            <FormControl mb={3}>
              <FormLabel htmlFor="complement">Complemento</FormLabel>
              <Input
                value={complement}
                onChange={e => setComplement(e.target.value)}
              />
            </FormControl>
            <Grid templateColumns="repeat(2, 1fr)" gap="15px" mb={3}>
              <FormControl mb={4} isRequired>
                <FormLabel>Transação</FormLabel>
                <Select
                  size="lg"
                  value={transaction}
                  onChange={e => setTransaction(e.target.value)}
                >
                  <option disabled selected>
                    Selecione
                  </option>
                  <option value="SALE">Venda</option>
                  <option value="RENT">Aluguel</option>
                </Select>
              </FormControl>
              <FormControl mb={4} isRequired>
                <FormLabel>Categoria</FormLabel>
                <Select
                  size="lg"
                  value={category}
                  onChange={e => setCategory(e.target.value)}
                >
                  <option disabled selected>
                    Selecione
                  </option>
                  {locations.map(location => (
                    <option key={uniqid()} value={location[0]}>
                      {location[1]}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <FormControl id="price" mb={3} isRequired>
              <FormLabel>Valor do imóvel</FormLabel>
              <NumberInput
                size="lg"
                pattern="[0-9]*(,[0-9]+)?"
                value={price}
                onChange={setPrice}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
            <Grid
              templateColumns="2fr 1fr"
              gap="15px"
              alignItems="flex-end"
              mb={3}
            >
              <FormControl pos="relative" isRequired>
                <FormLabel htmlFor="tenant" value={clientId}>
                  Proprietário
                </FormLabel>
                <DebounceInput
                  element={Input}
                  minLength={2}
                  debounceTimeout={300}
                  placeholder="Pesquise pelo nome, email, cpf ou telefone"
                  onBlur={() => {
                    setTimeout(() => {
                      onClientsClose()
                    }, 200)
                  }}
                  onFocus={onOpen}
                  size="lg"
                  value={clientSearch}
                  onChange={e => setClientSearch(e.target.value)}
                />
                {clientsLoading && (
                  <Spinner
                    pos="absolute"
                    right="15px"
                    bottom="15px"
                    size="sm"
                    color="blue.400"
                    zIndex="9999"
                  />
                )}
                {isClientsOpen &&
                  clientSearch.length > 2 &&
                  clients.length > 0 && (
                    <SlideFade in={true}>
                      <Box
                        pos="absolute"
                        top="83px"
                        left="0"
                        w="100%"
                        bg="white"
                        boxShadow="lg"
                        overflow="auto"
                        minH="50px"
                        maxH="250px"
                        borderRadius="5px"
                        zIndex="9999"
                      >
                        {clients.map((client, idx) => (
                          <Box
                            borderRadius={idx === 0 ? '5px 5px 0 0' : '0'}
                            key={`client-${client.id}`}
                            cursor="pointer"
                            p="3"
                            transition="all ease .3s"
                            _hover={{
                              bg: 'gray.100'
                            }}
                            onClick={() => {
                              setClientId(client.id)
                              setClientSearch(client.name)
                            }}
                          >
                            <Text>
                              {client.name}{' '}
                              <span style={{ color: 'rgba(0, 0, 0, 0.5)' }}>
                                ({client.cpf_cnpj})
                              </span>
                            </Text>
                          </Box>
                        ))}
                      </Box>
                    </SlideFade>
                  )}
              </FormControl>
              <Button variant="ghost" size="lg" onClick={onOpenCreate}>
                Novo cliente
              </Button>
            </Grid>
          </DrawerBody>
          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              bg="orange.400"
              color="white"
              isLoading={loading}
              disabled={loading || !clientId}
              onClick={create}
              _hover={{ bg: 'orange.600' }}
            >
              Salvar
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  )
}

export default PropertyCreate
