import { Table, Button, Card, Row, Col, Form, Input, message } from 'antd'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import AppLayout from '../../layouts/AppLayout'
import api from '../../services/api'

function SystemNotification() {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])

  const [form] = Form.useForm()

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      responsive: ['md'],
      sorter: (a, b) => a.id - b.id
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Ações',
      render: (text, record) => {
        return (
          <>
            <Link to="/">Editar</Link>
            <Button
              style={{ marginLeft: 20 }}
              type="danger"
              onClick={() => destroy(record.id)}
            >
              Deletar
            </Button>
          </>
        )
      }
    }
  ]

  useEffect(() => {
    getData()
  }, [])

  async function destroy(id) {
    try {
      await api.delete(`/notification/system/${id}`)
      getData()
    } catch (err) {
      message.error('Não foi possível deletar este usuário')
    }
  }

  async function getData() {
    setLoading(true)

    try {
      const { data: templates } = await api.get('/notification/system')

      setData(templates.map(template => ({ ...template, key: template.id })))
    } catch (err) {
      message.error('Ocorreu um erro ao buscar os dados')
    }

    setLoading(false)
  }

  async function create(values) {
    message.loading('Cadastrando...')

    try {
      await api.post('/notification/system', values)

      message.success('Notificação cadastrada!')
      getData()
    } catch (err) {
      message.error('Ocorreu um erro ao tentar criar uma nova notificação')
    }
  }

  return (
    <AppLayout title="Notificações" keyPage="19" subKeyPage="sub3">
      <Row gutter={25}>
        <Col md={14}>
          <Card title="Cadastre uma nova notificação">
            <Form
              name="create-template"
              form={form}
              onFinish={create}
              autoComplete="off"
            >
              <Form.Item
                name="name"
                rules={[
                  { required: true, message: 'Informe o nome da notificação!' }
                ]}
              >
                <Input placeholder="Nome" />
              </Form.Item>
              <Form.Item
                name="message"
                rules={[{ required: true, message: 'Informe a mensagem!' }]}
              >
                <Input.TextArea rows={6} placeholder="Mensagem..." />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Cadastrar
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col md={10}>
          <Card title="Notificações cadastradas">
            <Table
              columns={columns}
              dataSource={data}
              locale={{ emptyText: 'Não há notificações cadastrados' }}
              loading={loading}
            />
          </Card>
        </Col>
      </Row>
    </AppLayout>
  )
}

export default SystemNotification
