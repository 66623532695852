import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Text,
  Divider,
  Heading,
  Center,
  Spinner,
  Flex,
  Avatar,
  IconButton,
  useToast,
  Button,
  Badge
} from '@chakra-ui/react'
import React, { useCallback, useState, useEffect } from 'react'
import { CgClose } from 'react-icons/cg'
import Select from 'react-select'

import api from '../../services/api'
import ClientModal from '../Client/Modal'

function PropertyOwners({ id, isOpen, onClose }) {
  const toast = useToast()

  const [users, setUsers] = useState([])
  const [owners, setOwners] = useState([])
  const [user, setUser] = useState()

  const [userSelected, setUserSelected] = useState()

  const [usersLoading, setUsersLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [actionLoading, setActionLoading] = useState()

  const getUsers = useCallback(async () => {
    setUsersLoading(true)

    try {
      const { data } = await api.get('/user')

      setUsers(data)
    } catch {
      toast({
        title: 'Oopss...',
        description: 'Não foi possível retornar os dados de clientes',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }

    setUsersLoading(false)
  }, [toast, setUsers])

  const getOwners = useCallback(async () => {
    setLoading(true)

    try {
      const { data } = await api.get(`/property/${id}/members/OWNER`)

      setOwners(data)
    } catch {
      toast({
        title: 'Oopss...',
        description: 'Não foi possível retornar os proprietários do imóvel',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }

    setLoading(false)
  }, [toast, setOwners, id])

  async function handleAddOwner() {
    if (actionLoading === 'NEW') return

    setActionLoading('NEW')

    try {
      const { data } = await api.put(
        `/property/${id}/member/OWNER/${user.value}`
      )

      setUser(null)
      setOwners(data)
    } catch {
      toast({
        title: 'Oopss...',
        description: 'Não foi possível adicionar o novo proprietário ao imóvel',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }

    setActionLoading(null)
  }

  async function handleRemoveOwner(userId) {
    if (actionLoading) return

    setActionLoading('DELETE-' + userId)

    try {
      const { data } = await api.delete(`/property/${id}/member/${userId}`)

      setOwners(data)
      console.log('Adicionado')
    } catch {
      toast({
        title: 'Oopss...',
        description: 'Não foi possível remover o proprietário do imóvel',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }

    setActionLoading(null)
  }

  useEffect(() => {
    if (isOpen) {
      getUsers()
      getOwners()
    }
  }, [getUsers, getOwners, isOpen])

  return (
    <>
      <ClientModal
        clientId={userSelected}
        isOpen={userSelected}
        onClose={() => setUserSelected(null)}
      />
      <Modal isOpen={isOpen} onClose={onClose} size="md">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Proprietário(s) do imóvel</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex mb={3} alignItems="flex-end">
              <Box w="100%" mr={4}>
                <Text>Selecione um cliente</Text>
                <Select
                  placeholder="Buscar..."
                  value={user}
                  onChange={setUser}
                  noOptionsMessage={() => 'Cliente não encontrado'}
                  isDisabled={usersLoading}
                  isLoading={usersLoading}
                  options={users.map(user => ({
                    value: user.id,
                    label: user.name,
                    data: user
                  }))}
                />
              </Box>
              <Button
                colorScheme="whatsapp"
                isLoading={actionLoading === 'NEW'}
                isDisabled={actionLoading === 'NEW' || !user}
                onClick={handleAddOwner}
              >
                Adicionar
              </Button>
            </Flex>
            <Divider my={6} />
            <Heading size="sm" fontWeight="500" mb={4}>
              {owners.length > 0
                ? 'Proprietários vinculados'
                : 'Proprietário vinculado'}
            </Heading>
            {loading && (
              <Center>
                <Spinner size="sm" />
              </Center>
            )}
            {owners.length === 0 && !loading && (
              <Heading size="xs" textAlign="center" py={8}>
                Não há proprietários vinculados no imóvel.
              </Heading>
            )}
            {owners.map((owner, index) => (
              <Flex
                key={owner.id}
                alignItems="center"
                py="4"
                borderBottom="solid 1px"
                borderColor="gray.100"
              >
                <Avatar size="md" name={owner.name} borderRadius="lg" />
                <Box mr="auto" w="65%" ml={4}>
                  <Heading
                    size="sm"
                    isTruncated
                    maxW="100%"
                    cursor="pointer"
                    transition="all ease .3s"
                    onClick={() => setUserSelected(owner.id)}
                    _hover={{ color: 'orange.400', textDecor: 'underline' }}
                  >
                    {owner.name}
                  </Heading>

                  {index === 0 && <Badge>Principal</Badge>}
                </Box>
                <IconButton
                  isRound
                  size="sm"
                  icon={<CgClose />}
                  variant="outline"
                  colorScheme="red"
                  onClick={() => handleRemoveOwner(owner.id)}
                  isLoading={actionLoading === `DELETE-${owner.id}`}
                  isDisabled={actionLoading === `DELETE-${owner.id}`}
                />
              </Flex>
            ))}
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
    </>
  )
}

export default PropertyOwners
