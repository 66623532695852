import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Center,
  Spinner
} from '@chakra-ui/react'
import React from 'react'

import useClient from '../../hooks/use-client'
import ClientAddress from './Address'
import ClientBanks from './Banks'
import ClientContracts from './Contracts'
import ClientDetails from './Details'
import ClientForm from './Form'
import ClientProperties from './Properties'

function ClientTabs() {
  const { loading } = useClient()

  return loading ? (
    <Center>
      <Spinner size="lg" mt="40px" />
    </Center>
  ) : (
    <Tabs mt={7} isFitted>
      <TabList>
        <Tab>Dados gerais</Tab>
        <Tab>Mais detalhes</Tab>
        <Tab>Endereço</Tab>
        <Tab>Contas bancárias</Tab>
        <Tab>Imóveis vinculados</Tab>
        <Tab>Contratos</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <ClientForm />
        </TabPanel>
        <TabPanel>
          <ClientDetails />
        </TabPanel>
        <TabPanel>
          <ClientAddress />
        </TabPanel>
        <TabPanel>
          <ClientBanks />
        </TabPanel>
        <TabPanel>
          <ClientProperties />
        </TabPanel>
        <TabPanel>
          <ClientContracts />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

export default ClientTabs
