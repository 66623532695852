import {
  Stat,
  StatLabel,
  StatNumber,
  StatGroup,
  Skeleton,
  Button,
  Select,
  IconButton,
  ButtonGroup,
  Box,
  useDisclosure
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { CgArrowsExchangeAlt } from 'react-icons/cg'

import useProperty from '../../hooks/use-property'
import ClientModal from '../Client/Modal'
import PropertyOwners from './Owners'

function PropertyHeader() {
  const { id, loading, data, updateData, setUpdating, setUpdatingDefault } =
    useProperty()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isOwnersOpen,
    onOpen: onOwnersOpen,
    onClose: onOwnersClose
  } = useDisclosure()

  const [selected, setSelected] = useState()

  function handleChange(event) {
    const { name, value } = event.target

    var toAssign = {
      ...JSON.parse(JSON.stringify(data))
    }

    toAssign[name] = value

    setUpdating(true)
    setUpdatingDefault(true)

    updateData(draft => {
      return Object.assign(draft, toAssign)
    })
  }

  return (
    <StatGroup>
      <ClientModal isOpen={isOpen} onClose={onClose} clientId={selected} />
      <PropertyOwners
        id={id}
        isOpen={isOwnersOpen}
        onClose={onOwnersClose}
        property={data}
      />
      <Stat>
        <StatLabel>Proprietário</StatLabel>
        <Skeleton isLoaded={!loading}>
          <ButtonGroup isAttached variant="outline">
            <Button
              mr="-px"
              onClick={() => {
                setSelected(
                  data.relationship
                    ? data.relationship.find(
                        el => el.pivot.relationship === 'OWNER'
                      ).id
                    : 0
                )
                onOpen()
              }}
            >
              Abrir perfil de{' '}
              {data.relationship
                ? data.relationship
                    .find(el => el.pivot.relationship === 'OWNER')
                    .name.split(' ')[0]
                : '...'}
            </Button>
            <IconButton
              aria-label="Change property"
              icon={<CgArrowsExchangeAlt />}
              onClick={onOwnersOpen}
            />
          </ButtonGroup>
        </Skeleton>
      </Stat>
      <Stat>
        <StatLabel>Inquilino</StatLabel>
        <Skeleton isLoaded={!loading}>
          <StatNumber>
            {data.relationship &&
            !!data.relationship.find(
              el => el.pivot.relationship === 'TENANT'
            ) ? (
              <Button
                variant="outline"
                onClick={() => {
                  setSelected(
                    data.relationship
                      ? data.relationship.find(
                          el => el.pivot.relationship === 'TENANT'
                        ).id
                      : 0
                  )
                  onOpen()
                }}
              >
                Abrir perfil de{' '}
                {data.relationship
                  ? data.relationship
                      .find(el => el.pivot.relationship === 'TENANT')
                      .name.split(' ')[0]
                  : '...'}
              </Button>
            ) : (
              <Box fontSize="18px">Não possui inquilino</Box>
            )}
          </StatNumber>
        </Skeleton>
      </Stat>
      <Stat>
        <StatLabel>Status do imóvel</StatLabel>
        <Skeleton isLoaded={!loading}>
          <Select
            size="md"
            name="status"
            value={data && data.status}
            onChange={handleChange}
          >
            <option value="AVAILABLE">Disponível</option>
            <option value="SOLD">Vendido</option>
            <option value="RENTED">Alugado</option>
            <option value="UNAVAILABLE">Indisponível</option>
          </Select>
        </Skeleton>
      </Stat>
    </StatGroup>
  )
}

export default PropertyHeader
