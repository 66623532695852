import {
  Box,
  Grid,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Tooltip,
  Flex,
  Progress,
  Divider
} from '@chakra-ui/react'
import React, { useState } from 'react'
import InputMask from 'react-input-mask'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'

import useClient from '../../hooks/use-client'

function ClientAddress() {
  const { data, updateData, setUpdating, setUpdatingAddress } = useClient()

  const [tmpAddress, setTmpAddress] = useState('')

  function handleAddressChange(event) {
    const { name, value } = event.target

    var toAssign = {
      ...JSON.parse(JSON.stringify(data))
    }

    toAssign.address[name] = value

    setUpdating(true)
    setUpdatingAddress(true)

    updateData(draft => {
      return Object.assign(draft, toAssign)
    })
  }

  function getAddressData(object, name) {
    const data = object.address_components
    const item = data.find(el => el.types.includes(name))

    return item ? item.long_name : null
  }

  async function handleAddress(address) {
    const place = await geocodeByAddress(address)

    setTmpAddress(place[0].formatted_address)
    setUpdating(true)

    var toAssign = {
      ...JSON.parse(JSON.stringify(data)),
      address: {
        cep: getAddressData(place[0], 'postal_code'),
        street: getAddressData(place[0], 'route'),
        neighborhood: getAddressData(place[0], 'sublocality'),
        city: getAddressData(place[0], 'administrative_area_level_2'),
        state: getAddressData(place[0], 'administrative_area_level_1'),
        number: getAddressData(place[0], 'street_number')
      }
    }

    updateData(draft => {
      return Object.assign(draft, toAssign)
    })
  }

  return (
    <Box p="15px 0">
      <PlacesAutocomplete
        value={tmpAddress}
        onChange={setTmpAddress}
        onSelect={handleAddress}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <>
            <Input
              {...getInputProps({
                placeholder:
                  'Digite o endereço para agilizarmos seu cadastro...'
              })}
            />
            {(loading || suggestions.length > 0) && (
              <Box p="2" bg="gray.50" borderRadius="5px">
                {suggestions.map(suggestion => {
                  return (
                    <Tooltip
                      label={suggestion.description}
                      key={suggestion.placeId}
                    >
                      <Flex
                        {...getSuggestionItemProps(suggestion)}
                        direction="row"
                        cursor="pointer"
                        bg={suggestion.active ? 'blue.50' : 'white'}
                        my={2}
                        borderRadius="5px"
                        p="2"
                        transition="all ease .3s"
                      >
                        <Heading size="sm" color="gray.700" isTruncated>
                          {suggestion.description}
                        </Heading>
                      </Flex>
                    </Tooltip>
                  )
                })}
                {loading && <Progress size="xs" isIndeterminate />}
              </Box>
            )}
          </>
        )}
      </PlacesAutocomplete>
      <Divider my={5} />
      <FormControl id="name" mb={3} isRequired>
        <FormLabel>CEP / Código Postal</FormLabel>
        <InputMask
          name="cep"
          value={data.address && data.address.cep}
          onChange={handleAddressChange}
          mask={'99.999-999'}
        >
          {inputProps => <Input {...inputProps} />}
        </InputMask>
      </FormControl>
      <Grid templateColumns="45% calc(35% - 30px) 20%" gap="15px" mb={3}>
        <FormControl id="street" isRequired>
          <FormLabel>Logradouro</FormLabel>
          <Input
            name="street"
            value={data.address && data.address.street}
            onChange={handleAddressChange}
          />
        </FormControl>
        <FormControl id="neighborhood" isRequired>
          <FormLabel>Bairro</FormLabel>
          <Input
            name="neighborhood"
            value={data.address && data.address.neighborhood}
            onChange={handleAddressChange}
          />
        </FormControl>
        <FormControl id="number">
          <FormLabel>Número</FormLabel>
          <Input
            name="number"
            value={data.address && data.address.number}
            onChange={handleAddressChange}
          />
        </FormControl>
      </Grid>
      <Grid templateColumns="repeat(2, 1fr)" gap="15px" mb={3}>
        <FormControl id="city" isRequired>
          <FormLabel>Cidade</FormLabel>
          <Input
            name="city"
            value={data.address && data.address.city}
            onChange={handleAddressChange}
          />
        </FormControl>
        <FormControl id="state" isRequired>
          <FormLabel>Estado</FormLabel>
          <Input
            name="state"
            value={data.address && data.address.state}
            onChange={handleAddressChange}
          />
        </FormControl>
      </Grid>
      <FormControl mb={3}>
        <FormLabel htmlFor="complement">Complemento</FormLabel>
        <Input
          name="complement"
          value={data.address && data.address.complement}
          onChange={handleAddressChange}
        />
      </FormControl>
    </Box>
  )
}

export default ClientAddress
