import React from 'react'
import { HashRouter, Switch, Route } from 'react-router-dom'

import PrivateRoute from './components/PrivateRoute'
import Blog from './pages/Blog'
import BlogEdit from './pages/Blog/edit'
import Clients from './pages/Clients'
import ClientCreate from './pages/Clients/create'
import ClientsInfo from './pages/Clients/info'
import Contacts from './pages/Contacts'
import ContractTemplate from './pages/ContractTemplate'
import Contracts from './pages/Contracts'
import ContractsCreate from './pages/Contracts/create'
import ContractsInfo from './pages/Contracts/info'
import Financial from './pages/Financial'
import FinancialBoletos from './pages/Financial/boletos'
import FinancialComissions from './pages/Financial/comissions'
import FinancialInvoices from './pages/Financial/invoices'
import FinancialMovimentations from './pages/Financial/movimentations'
import FinancialReports from './pages/Financial/reports'
import FinancialShall from './pages/Financial/shall'
import FinancialTransactions from './pages/Financial/transactions'
import FinancialTransfers from './pages/Financial/transfers'
import Home from './pages/Home'
import Login from './pages/Login'
import NotFound from './pages/NotFound'
import Permissions from './pages/Permissions'
import Properties from './pages/Properties'
import PropertiesInfo from './pages/Properties/info'
import PropertiesRegister from './pages/Properties/register'
import PropertiesRequests from './pages/Properties/requests'
import Schedules from './pages/Schedules'
import Support from './pages/Support'
import SupportInfo from './pages/Support/info'
import SystemNotification from './pages/SystemNotification'
import Users from './pages/Users'
import ChangePassword from './pages/ChangePassword'

function Routes() {
  return (
    <HashRouter basename="/">
      <Switch>
        <PrivateRoute exact path="/" component={Home} />

        <PrivateRoute
          exact
          path="/financial"
          component={Financial}
          guard="FINANCIAL_VIEW"
        />
        <PrivateRoute
          exact
          path="/financial/transfers"
          component={FinancialTransfers}
          guard="FINANCIAL_VIEW"
        />
        <PrivateRoute
          exact
          path="/financial/shall"
          component={FinancialShall}
          guard="FINANCIAL_VIEW"
        />
        <PrivateRoute
          exact
          path="/financial/reports"
          component={FinancialReports}
          guard="FINANCIAL_VIEW"
        />
        <PrivateRoute
          exact
          path="/financial/transactions"
          component={FinancialTransactions}
          guard="FINANCIAL_VIEW"
        />
        <PrivateRoute
          exact
          path="/financial/movimentations"
          component={FinancialMovimentations}
          guard="FINANCIAL_VIEW"
        />
        <PrivateRoute
          exact
          path="/financial/boletos"
          component={FinancialBoletos}
          guard="FINANCIAL_VIEW"
        />
        <PrivateRoute
          exact
          path="/financial/invoices"
          component={FinancialInvoices}
          guard="FINANCIAL_VIEW"
        />
        <PrivateRoute
          exact
          path="/financial/comissions"
          component={FinancialComissions}
          guard="FINANCIAL_VIEW"
        />

        <PrivateRoute
          exact
          path="/clients"
          component={Clients}
          guard="CLIENT_VIEW"
        />
        <PrivateRoute
          exact
          path="/clients/info/:id"
          component={ClientsInfo}
          guard="CLIENT_VIEW"
        />
        <PrivateRoute
          exact
          path="/clients/create"
          component={ClientCreate}
          guard="CLIENT_VIEW"
        />

        <PrivateRoute
          exact
          path="/properties"
          component={Properties}
          guard="PROPERTY_VIEW"
        />
        <PrivateRoute
          exact
          path="/properties/register"
          component={PropertiesRegister}
          guard="PROPERTY_CREATE"
        />
        <PrivateRoute
          exact
          path="/properties/requests"
          component={PropertiesRequests}
          guard="PROPERTY_REQUESTS_VIEW"
        />
        <PrivateRoute
          exact
          path="/properties/info/:id"
          component={PropertiesInfo}
          guard="PROPERTY_VIEW"
        />

        <PrivateRoute
          exact
          path="/contracts"
          component={Contracts}
          guard="PROPOSAL_VIEW"
        />
        <PrivateRoute
          exact
          path="/contracts/create"
          component={ContractsCreate}
          guard="PROPOSAL_VIEW"
        />
        <PrivateRoute
          exact
          path="/contracts/info/:id"
          component={ContractsInfo}
          guard="PROPOSAL_VIEW"
        />

        <PrivateRoute
          exact
          path="/schedules"
          component={Schedules}
          guard="SCHEDULE_VIEW"
        />

        <PrivateRoute
          exact
          path="/contacts"
          component={Contacts}
          guard="CONTACT_VIEW"
        />

        <PrivateRoute exact path="/blog" component={Blog} guard="FEED_VIEW" />
        <PrivateRoute
          exact
          path="/blog/:id"
          component={BlogEdit}
          guard="FEED_VIEW"
        />

        <PrivateRoute
          exact
          path="/support"
          component={Support}
          guard="SUPPORT_VIEW"
        />
        <PrivateRoute
          exact
          path="/support/:id"
          component={SupportInfo}
          guard="SUPPORT_VIEW"
        />

        <PrivateRoute
          exact
          path="/settings/users"
          component={Users}
          guard="ADMIN_USER_VIEW"
        />
        <PrivateRoute
          exact
          path="/settings/permissions"
          component={Permissions}
          guard="GROUP_VIEW"
        />
        <PrivateRoute
          exact
          path="/settings/notifications"
          component={SystemNotification}
          guard="SYSTEM_NOTIFICATION_VIEW"
        />
        <PrivateRoute
          exact
          path="/settings/contracts"
          component={ContractTemplate}
          guard="CONTRACT_TEMPLATE_VIEW"
        />
        <PrivateRoute
          exact
          path="/settings/password-change"
          component={ChangePassword}
        />

        <Route exact path="/login" component={Login} />
        <Route path="*" component={NotFound} />
      </Switch>
    </HashRouter>
  )
}

export default Routes
