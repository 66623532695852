import {
  Table,
  Row,
  Col,
  Card,
  Form,
  Select,
  Statistic,
  DatePicker,
  Button,
  Tag
} from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

import AppLayout from '../../layouts/AppLayout'

function FinancialTransactions() {
  const [form] = Form.useForm()
  const [loading, setLoading] = React.useState(false)

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Inquilino',
      dataIndex: 'name',
      key: 'name',
      render: text => <Link to="/">{text}</Link>
    },
    {
      title: 'Contrato',
      dataIndex: 'contract',
      key: 'contract',
      render: text => <Link to="/">{text}</Link>
    },
    {
      title: 'Referência',
      dataIndex: 'reference',
      key: 'reference',
      render: text => <Link to="/">{text}</Link>
    },
    {
      title: 'Valor',
      dataIndex: 'price',
      key: 'price',
      render: text =>
        'R$' +
        parseFloat(text).toLocaleString('pt-BR', { minimumFractionDigits: 2 })
    },
    {
      title: 'Data do Pagamento',
      dataIndex: 'created_at',
      key: 'created_at'
    },
    {
      title: 'Forma de Pagamento',
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      render: text => <Tag color="green">{text}</Tag>
    }
  ]

  const dataTable = [
    {
      key: '3',
      id: '3',
      name: 'João Pedro Machado Nascimento de Paulo',
      contract: '16642',
      reference: '07/06/2020',
      price: 750,
      created_at: new Date().toLocaleString('pt-BR'),
      paymentMethod: 'Cartão de Crédito'
    },
    {
      key: '2',
      id: '2',
      name: 'João Pedro Machado Nascimento de Paulo',
      contract: '16642',
      reference: '07/07/2020',
      price: 750,
      created_at: new Date().toLocaleString('pt-BR'),
      paymentMethod: 'Cartão de Crédito'
    },
    {
      key: '1',
      id: '1',
      name: 'João Pedro Machado Nascimento de Paulo',
      contract: '16642',
      reference: '07/08/2020',
      price: 750,
      created_at: new Date().toLocaleString('pt-BR'),
      paymentMethod: 'Cartão de Crédito'
    }
  ]

  return (
    <AppLayout title="Transações" keyPage="6" subKeyPage="sub1">
      <Card title="Filtrar busca">
        <Form
          layout="inline"
          form={form}
          initialValues={{
            department: '1',
            type: '1'
          }}
          onFinish={() => {
            setLoading(true)
            setTimeout(() => setLoading(false), 2000)
          }}
        >
          <Form.Item label="Departamento" name="department">
            <Select style={{ width: 150 }}>
              <Select.Option value="1">Todos</Select.Option>
              <Select.Option value="2">Aluguéis</Select.Option>
              <Select.Option value="3">Vendas</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Forma de Pagamento" name="type">
            <Select style={{ width: 200 }}>
              <Select.Option value="1">Todos</Select.Option>
              <Select.Option value="2">Boleto</Select.Option>
              <Select.Option value="3">Cartão de Crédito</Select.Option>
              <Select.Option value="4">Baixa Manual</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Selecione o perído" name="dateRange">
            <DatePicker.RangePicker />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Filtrar
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <br />
      <Row gutter={25}>
        <Col md={12}>
          <Card>
            <Statistic
              title="Renda bruta"
              value={264532.15}
              precision={2}
              valueStyle={{ color: '#3498db' }}
            />
          </Card>
        </Col>
        <Col md={12}>
          <Card>
            <Statistic
              title="Renda líquida"
              value={2645.31}
              precision={0}
              valueStyle={{ color: '#2ecc71' }}
            />
          </Card>
        </Col>
      </Row>
      <br />
      <Card title="Histórico de transações">
        <Table columns={columns} dataSource={dataTable} />
      </Card>
    </AppLayout>
  )
}

export default FinancialTransactions
