import { useDisclosure } from '@chakra-ui/react'
import { Table, Card, Input, Popover, message, Button } from 'antd'
import React, { useState, useEffect } from 'react'
import Highlighter from 'react-highlight-words'
import { FiMousePointer } from 'react-icons/fi'
import { Link, useHistory } from 'react-router-dom'

import ClientModal from '../../components/Client/Modal'
import usePage from '../../contexts/hooks/page'
import AppLayout from '../../layouts/AppLayout'
import api from '../../services/api'

const { Search } = Input

function Contracts() {
  const history = useHistory()

  const page = usePage()
  const pageSearch = page.get('contracts', 'search')

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')

  const [selected, setSelected] = useState()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      responsive: ['md'],
      sorter: (a, b) => a.id - b.id,
      render: (text, record) => (
        <Popover
          placement="right"
          content={
            <>
              <h6 className="m-0">Proprietário:</h6>
              <p className="font-weight-light m-0">{record.bank_full_name}</p>
              <h6 className="m-0">Imóvel:</h6>
              <p className="font-weight-light m-0">
                {record.property.neighborhood}, nº {record.property.number} /{' '}
                {record.property.complement} - {record.property.city}
              </p>
            </>
          }
        >
          <FiMousePointer style={{ marginRight: 15 }} />
          {search.length > 0 ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[search]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            text
          )}
        </Popover>
      )
    },
    {
      title: 'Inquilino',
      dataIndex: 'user_id',
      key: 'user_id',
      render: (text, record) =>
        search.length > 0 ? (
          // eslint-disable-next-line
          <a
            href="#"
            onClick={e => {
              e.preventDefault()

              setSelected(record.user.id)
              onOpen()
            }}
          >
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[search]}
              autoEscape
              textToHighlight={
                record.user.name ? record.user.name.toString() : ''
              }
            />
          </a>
        ) : (
          // eslint-disable-next-line
          <a
            href="#"
            onClick={e => {
              e.preventDefault()

              setSelected(record.user.id)
              onOpen()
            }}
          >
            {record.user.name}
          </a>
        )
    },
    {
      title: 'Imóvel',
      dataIndex: 'property_id',
      key: 'property_id',
      render: text =>
        search.length > 0 ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[search]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          <Link to={`/properties/info/${text}`}>{text}</Link>
        )
    },
    {
      title: 'Preço',
      dataIndex: 'price',
      key: 'price',
      responsive: ['md'],
      render: text =>
        search.length > 0 ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[search]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        )
    },
    {
      title: 'Data Inicial',
      dataIndex: 'start_date',
      key: 'start_date',
      render: text =>
        search.length > 0 ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[search]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        )
    },
    {
      title: 'Data Final',
      dataIndex: 'end_date',
      key: 'end_date',
      render: text =>
        search.length > 0 ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[search]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        )
    },
    {
      title: 'Ações',
      render: (text, record) => {
        return <Link to={`/contracts/info/${record.id}`}>Mais detalhes</Link>
      }
    }
  ]

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (pageSearch) {
      setSearch(pageSearch || '')
    }
  }, [pageSearch])

  async function getData() {
    setLoading(true)

    try {
      const { data: proposals } = await api.get('/proposal')

      setData(
        proposals.map(proposal => ({
          ...proposal,
          key: proposal.id,
          price: `R$ ${parseFloat(proposal.price).toLocaleString('pt-BR')}`,
          start_date: new Date(proposal.start_date).toLocaleDateString('pt-BR'),
          end_date: new Date(proposal.end_date).toLocaleDateString('pt-BR')
        }))
      )
    } catch (err) {
      message.error('Ocorreu um erro ao buscar os dados')
    }

    setLoading(false)
  }

  function filtered() {
    return data
      .map(el => ({ ...el, key: el.id }))
      .filter(el =>
        JSON.stringify(el).toLowerCase().includes(search.toLowerCase())
      )
  }

  return (
    <AppLayout title="Contratos" keyPage="12">
      <ClientModal isOpen={isOpen} onClose={onClose} clientId={selected} />
      <Card
        title="Gerencie os contratos"
        extra={
          <Button
            type="primary"
            onClick={() => history.push('/contracts/create')}
          >
            Adicionar contrato
          </Button>
        }
      >
        {!loading && (
          <Search
            placeholder="Buscar..."
            defaultValue={search}
            onSearch={text => {
              setSearch(text)
              page.update('contracts', { search: text })
            }}
            size="large"
          />
        )}
        <br />
        <br />
        <Table
          columns={columns}
          dataSource={
            search.length > 0
              ? data
                ? filtered()
                : []
              : data
              ? data.map(el => ({ key: el.id, ...el }))
              : []
          }
          locale={{ emptyText: 'Não há contratos cadastrados' }}
          loading={loading}
        />
      </Card>
    </AppLayout>
  )
}

export default Contracts
