import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Grid,
  Switch,
  Textarea
} from '@chakra-ui/react'
import React from 'react'

import useProperty from '../../hooks/use-property'

function PropertyInformations() {
  const { data, updateData, setUpdating, setUpdatingInformation } =
    useProperty()

  function handleChange(event) {
    const { name, value } = event.target

    var toAssign = {
      ...JSON.parse(JSON.stringify(data))
    }

    if (!toAssign.information) {
      toAssign.information = {
        [name]: value
      }
    } else {
      toAssign.information[name] = value
    }

    setUpdating(true)
    setUpdatingInformation(true)

    updateData(draft => {
      return Object.assign(draft, toAssign)
    })
  }

  function handleCheck(event) {
    const { name, checked } = event.target

    var toAssign = {
      ...JSON.parse(JSON.stringify(data))
    }

    toAssign.information[name] = checked

    setUpdating(true)
    setUpdatingInformation(true)

    updateData(draft => {
      return Object.assign(draft, toAssign)
    })
  }

  return (
    <Box p="0 5">
      <FormControl id="description" mb={3} isRequired>
        <FormLabel>Descrição do anúncio</FormLabel>
        <Textarea
          type="number"
          name="description"
          onChange={handleChange}
          value={data.information && data.information.description}
        />
      </FormControl>
      <Grid templateColumns="repeat(2, 1fr)" gap="2%" mb={3}>
        <FormControl id="energisa" isRequired>
          <FormLabel>Inscrição Energisa</FormLabel>
          <Input
            type="number"
            name="energisa"
            onChange={handleChange}
            value={data.information && data.information.energisa}
          />
        </FormControl>
        <FormControl id="copasa" isRequired>
          <FormLabel>Inscrição Coposa</FormLabel>
          <Input
            type="number"
            name="copasa"
            onChange={handleChange}
            value={data.information && data.information.copasa}
          />
        </FormControl>
      </Grid>

      <Grid templateColumns="repeat(2, 1fr)" gap="15px" mb={3}>
        <FormControl id="land_area" isRequired>
          <FormLabel>Área do Terreno</FormLabel>
          <Input
            type="number"
            name="land_area"
            value={data.information && data.information.land_area}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl id="building_area" isRequired>
          <FormLabel>Área construída</FormLabel>
          <Input
            type="number"
            name="building_area"
            value={data.information && data.information.building_area}
            onChange={handleChange}
          />
        </FormControl>
      </Grid>

      {data.information && Object.keys(data.information).length > 0 && (
        <Grid templateColumns="repeat(3, 1fr)" gap="15px" mb={3}>
          <Box bg="gray.100" p="4" borderRadius="lg">
            <FormControl
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Switch
                id="pet"
                name="pet"
                defaultChecked={data.information && data.information.pet}
                onChange={handleCheck}
                mb="0"
              />
              <FormLabel ml={3} htmlFor="pet" mb="0">
                Permitido pet?
              </FormLabel>
            </FormControl>
          </Box>
          <Box bg="gray.100" p="4" borderRadius="lg">
            <FormControl
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Switch
                id="furnished"
                name="furnished"
                isChecked={data.information && data.information.furnished}
                onChange={handleCheck}
                mb="0"
              />
              <FormLabel ml={3} htmlFor="furnished" mb="0">
                Mobiliado?
              </FormLabel>
            </FormControl>
          </Box>
          <Box bg="gray.100" p="4" borderRadius="lg">
            <FormControl
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Switch
                id="comercial"
                name="comercial"
                isChecked={data.information && data.information.comercial}
                onChange={handleCheck}
                mb="0"
              />
              <FormLabel ml={3} htmlFor="comercial" mb="0">
                Comércio próximo?
              </FormLabel>
            </FormControl>
          </Box>
        </Grid>
      )}
    </Box>
  )
}

export default PropertyInformations
