import { Form, Card, Button, Input, Radio, Select, message } from 'antd'
import MaskedInput from 'antd-mask-input'
import axios from 'axios'
import React, { useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import AppLayout from '../../layouts/AppLayout'
import api from '../../services/api'

function CreateClient() {
  var numberRef = useRef(null)

  const history = useHistory()

  const [form] = Form.useForm()
  const [document, setDocument] = useState('CPF')
  const [emailRequired, setEmailRequired] = useState(true)

  const [loading, setLoading] = useState(false)

  async function submit(values) {
    if (loading) return false

    setLoading(true)

    try {
      const { data } = await api.post('/user/manual', values)

      history.push('/clients/info/' + data.id)
    } catch (err) {
      message.error(
        err.response.data.message || 'Não foi possível efetuar o cadastro'
      )
    }

    setLoading(false)
  }

  return (
    <AppLayout title="Clientes" keyPage="7">
      <Card title="Cadastrar novo cliente">
        <Form
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          initialValues={{ type: 'TENANT', document: 'CPF', sale_rent: 'RENT' }}
          onFinish={submit}
          onValuesChange={async obj => {
            if (obj.document) {
              setDocument(obj.document)
            }

            if (obj.sale_rent) {
              setEmailRequired(obj.sale_rent === 'RENT')
            }

            if (obj.cep && obj.cep.split('_').length === 1) {
              const { data } = await axios.get(
                `https://viacep.com.br/ws/${obj.cep
                  .replace('.', '')
                  .replace('-', '')}/json/`
              )

              form.setFieldsValue({
                street: data.logradouro,
                neighborhood: data.bairro,
                city: data.localidade,
                state: data.uf
              })

              numberRef.focus()
            }
          }}
        >
          <Form.Item
            label="Nome"
            name="name"
            rules={[{ required: true, message: 'Informe o nome' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Documento" name="document">
            <Radio.Group>
              <Radio.Button value="CPF">CPF</Radio.Button>
              <Radio.Button value="CNPJ">CNPJ</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="Cadastro para" name="sale_rent">
            <Radio.Group>
              <Radio.Button value="RENT">Aluguel</Radio.Button>
              <Radio.Button value="SALE">Venda</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="CPF ou CNPJ"
            name="cpf_cnpj"
            rules={[{ required: true, message: 'Informe o documento' }]}
          >
            <MaskedInput
              mask={
                document === 'CPF' ? '111.111.111-11' : '11.111.111/1111-11'
              }
            />
          </Form.Item>
          <Form.Item
            label="Tipo de cliente"
            name="type"
            rules={[{ required: true, message: 'Informe o tipo' }]}
          >
            <Select>
              <Select.Option value="TENANT">Inquilino</Select.Option>
              <Select.Option value="OWNER">Proprietário</Select.Option>
              <Select.Option value="GUARANTOR">Fiador</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="E-mail"
            name="email"
            rules={[{ required: emailRequired, message: 'Informe o e-mail' }]}
          >
            <Input type="email" />
          </Form.Item>
          <Form.Item
            label="Telefone"
            name="phone"
            rules={[{ required: true, message: 'Informe o telefone' }]}
          >
            <MaskedInput mask="(11) 1 1111-1111" />
          </Form.Item>
          <hr />
          <Form.Item label="CEP" name="cep">
            <MaskedInput mask="11.111-111" />
          </Form.Item>
          <Form.Item label="Endereço">
            <Input.Group compact>
              <Form.Item name="street" style={{ marginRight: 10 }}>
                <Input placeholder="Logradouro" style={{ width: 340 }} />
              </Form.Item>
              <Form.Item name="neighborhood" style={{ marginRight: 10 }}>
                <Input placeholder="Bairro" />
              </Form.Item>
              <Form.Item name="number" style={{ marginRight: 10 }}>
                <Input
                  placeholder="Número"
                  type="number"
                  style={{ width: 100 }}
                  ref={input => {
                    numberRef = input
                  }}
                />
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item label="Endereço">
            <Input.Group compact>
              <Form.Item name="city" style={{ marginRight: 10 }}>
                <Input readOnly disabled placeholder="Cidade" />
              </Form.Item>
              <Form.Item name="state" style={{ marginRight: 10 }}>
                <Input readOnly disabled placeholder="Estado" />
              </Form.Item>
              <Form.Item name="complement" style={{ marginRight: 10 }}>
                <Input placeholder="Complemento" />
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item label="Salvar dados">
            <Button
              htmlType="submit"
              loading={loading}
              disabled={loading}
              type="primary"
            >
              Salvar
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </AppLayout>
  )
}

export default CreateClient
