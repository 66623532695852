import { useToast } from '@chakra-ui/react'
import React, { useCallback, useEffect, useState, createContext } from 'react'
import { useImmer } from 'use-immer'

import InvoicesModal from '../components/Invoices/Modal'
import api from '../services/api'

function InvoiceProvider({ children }) {
  const toast = useToast()

  const [loading, setLoading] = useState(true)
  const [invoiceLoading, setInvoiceLoading] = useState(true)
  const [invoiceData, updateInvoiceData] = useImmer({})

  const [data, setData] = useState([])
  const [meta, setMeta] = useImmer({})

  const [users, setUsers] = useState([])

  const [actionLoading, setActionLoading] = useState(false)
  const [delettingLoading, setDelettingLoading] = useState(false)
  const [error, setError] = useState(false)

  const [updating, setUpdating] = useState(false)

  const [deleteLoading, setDeleteLoading] = useState(false)

  const [selected, setSelected] = useState()

  const [id, setId] = useState()
  const [query, setQuery] = useState()

  const getUsers = useCallback(async () => {
    setLoading(true)

    try {
      const { data } = await api.get('/user')

      setUsers(data)
    } catch {
      toast({
        title: 'Oopss...',
        description: 'Não foi possível retornar os dados de clientes',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }

    setLoading(false)
  }, [toast, setUsers])

  const getData = useCallback(
    async (page = 1, loadUsers = true) => {
      setLoading(true)

      try {
        const {
          data: {
            data,
            lastPage,
            page: currentPage,
            subtotal,
            subtotalDelayed,
            total,
            delayed
          }
        } = await api.get('invoice', {
          params: { page, id, query: encodeURIComponent(query) }
        })

        if (loadUsers) {
          await getUsers()
        }

        setData(data)
        setMeta(() => {
          return {
            last_page: lastPage,
            current_page: currentPage,
            subtotal: subtotal,
            subtotalDelayed: subtotalDelayed,
            total: total,
            delayed
          }
        })
      } catch {
        toast({
          title: 'Oopss...',
          description: 'Ocorreu um erro ao tentar buscar pelas faturas',
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      }

      setLoading(false)
    },
    [id, query, toast, getUsers, setMeta]
  )

  const getInvoiceData = useCallback(async () => {
    setInvoiceLoading(true)

    try {
      const { data: invoiceData } = await api.get(`invoice/${selected}`)

      updateInvoiceData(() => {
        return invoiceData
      })

      setError(false)
    } catch {
      toast({
        title: 'Oopss...',
        description: 'Ocorreu um erro ao tentar buscar pela fatura',
        status: 'error',
        duration: 5000,
        isClosable: true
      })

      setError(true)
    }

    setInvoiceLoading(false)
  }, [toast, selected, updateInvoiceData])

  async function destroy() {
    setDelettingLoading(true)

    try {
      await api.delete(`/financial/invoice/${selected}/delete`)

      toast({
        title: 'Pronto!',
        description: 'A fatura foi deletada',
        status: 'success',
        duration: 5000,
        isClosable: true
      })

      setSelected(null)
      getData(meta.currentPage)
    } catch {
      toast({
        title: 'Oopss...',
        description: 'Não foi possível deletar a fatura',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }

    setDelettingLoading(false)
  }

  async function create() {
    try {
      await api.post('/financial/invoice', {})

      getData()
    } catch (err) {}
  }

  async function refund() {
    setActionLoading(true)

    try {
      await api.delete(`/invoice/${id}/refund`)

      await getData(meta.currentPage)

      toast({
        title: 'Pronto!',
        description: 'A fatura foi estornada.',
        status: 'success'
      })

      updateInvoiceData(draft => {
        return draft.concat({ status: 'PENDING' })
      })
    } catch (err) {
      toast({
        title: 'Oops!',
        description: 'Ocorreu um problema ao tentar estornar a transferência.',
        status: 'warning'
      })
    }

    setActionLoading(false)
  }

  async function markAsPaid(paidAmount, onClose) {
    setActionLoading(true)

    try {
      await api.patch(`/invoice/${selected}/down`, {
        paid_amount: paidAmount
      })

      updateInvoiceData(draft => {
        return Object.assign(draft, {
          status: 'PAID',
          paid_amount: paidAmount
        })
      })

      await getData(meta.currentPage)

      onClose()
    } catch (err) {
      setError(true)
    }

    setActionLoading(false)
  }

  useEffect(() => {
    getData()
  }, [getData, id, query])

  useEffect(() => {
    if (selected) {
      getInvoiceData()
    }
  }, [selected, getInvoiceData])

  return (
    <InvoiceContext.Provider
      value={{
        id,
        setId,
        query,
        setQuery,

        meta,

        create,
        markAsPaid,
        refund,
        destroy,

        loading,
        delettingLoading,
        actionLoading,

        invoiceLoading,

        error,

        data,
        getData,

        users,
        invoiceData,
        updateInvoiceData,
        setSelected,
        updating,
        setUpdating,
        deleteLoading,
        setDeleteLoading
      }}
    >
      {children}
      <InvoicesModal
        isOpen={!!selected}
        onClose={() => {
          setSelected(null)
        }}
      />
    </InvoiceContext.Provider>
  )
}

export const InvoiceContext = createContext({})
export default InvoiceProvider
