import { Table, Card, message, Modal, Form, Select, Upload, Button } from 'antd'
import React, { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { getToken } from '../../guard/auth'
import AppLayout from '../../layouts/AppLayout'
import api from '../../services/api'

function FinancialBoletos() {
  const [loading, setLoading] = useState(true)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [data, setData] = useState([])

  const [users, setUsers] = useState([])
  const [proposals, setProposals] = useState([])
  const [invoices, setInvoices] = useState([])

  const [form] = Form.useForm()

  const [boletoModal, setBoletoModal] = useState(false)

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      responsive: ['md'],
      sorter: (a, b) => a.id - b.id
    },
    {
      title: 'Fatura',
      dataIndex: 'invoice_id',
      key: 'invoice_id'
    },
    {
      title: 'Contrato',
      dataIndex: 'proposal_id',
      key: 'proposal_id'
    },
    {
      title: 'Valor',
      render: (text, record) =>
        `R$${(record.invoice.price + record.invoice.fees).toLocaleString(
          'pt-BR',
          { minimumFractionDigits: 2 }
        )}`
    },
    {
      title: 'Data de emissão',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: text => new Date(text).toLocaleDateString('pt-BR')
    },
    {
      title: 'Data de vencimento',
      dataIndex: 'due_date',
      key: 'due_date',
      render: text => new Date(text).toLocaleDateString('pt-BR')
    },
    {
      title: 'Ações',
      render: (text, record) => {
        return (
          <Button
            type="primary"
            onClick={() =>
              window.open(
                `${
                  process.env.REACT_APP_ENDPOINT_API ||
                  'https://core-api.advqxs.easypanel.host'
                }/invoice/${uuidv4()}/${record.invoice.id}/${uuidv4()}`,
                '_blank'
              )
            }
          >
            Visualizar boleto
          </Button>
        )
      }
    }
  ]

  useEffect(() => {
    getData()
    getUsers()
  }, [])

  async function getData() {
    setLoading(true)

    try {
      const { data: proposals } = await api.get('/boleto')

      setData(
        proposals.map(proposal => ({
          ...proposal,
          key: proposal.id
        }))
      )
    } catch (err) {
      message.error('Ocorreu um erro ao buscar os dados')
    }

    setLoading(false)
  }

  async function getUsers() {
    try {
      const { data } = await api.get('/user')

      setUsers(data)
    } catch (err) {
      message.error('Ocorreu um erro ao tentar buscar os usuários')
    }
  }

  async function getInvoices(proposalId) {
    try {
      const { data } = await api.get(`/invoice/${proposalId}/proposal`)

      setInvoices(data)
    } catch (err) {
      message.error('Ocorreu um erro ao tentar buscar os usuários')
    }
  }

  async function submit(formData) {
    setSubmitLoading(true)

    try {
      await api.post('/boleto/manual', formData)

      setBoletoModal(false)
      form.resetFields()

      await getData()
    } catch (err) {
      message.error(err.response.data.message)
    }

    setSubmitLoading(false)
  }

  return (
    <AppLayout title="Boletos" subKeyPage="sub1" keyPage="24">
      <Card
        title="Boletos gerados pelo sistema"
        extra={
          <>
            <Button
              onClick={() => setBoletoModal(true)}
              type="primary"
              style={{ marginRight: 10 }}
            >
              Gerar boleto
            </Button>
            <Upload
              name="file"
              action={`${
                process.env.REACT_APP_ENDPOINT_API ||
                'https://core-api.advqxs.easypanel.host'
              }/boleto/down`}
              method="PUT"
              headers={{
                Authorization: `Bearer ${getToken()}`
              }}
              accept=".pdf"
              showUploadList={false}
              onChange={({ file }) => {
                if (file.status === 'uploading') {
                  message.loading('Fazendo upload...')
                }

                if (file.status === 'success') {
                  message.loading('Processando...')
                }

                if (file.status === 'done') {
                  message.success(
                    `${file.response.count} boletos liquidados no sistema`
                  )

                  getData()
                }

                if (file.status === 'error') {
                  message.error(
                    'Ocorreu um erro ao tentar processar o retorno dos boletos'
                  )
                }
              }}
            >
              <Button>Efetuar baixa</Button>
            </Upload>
          </>
        }
      >
        <Table
          columns={columns}
          dataSource={data}
          locale={{ emptyText: 'Não há boletos pendentes' }}
          loading={loading}
        />
        <Modal
          title="Gerar boleto manualmente"
          visible={boletoModal}
          onOk={() => form.submit()}
          okText="Salvar"
          onCancel={() => setBoletoModal(false)}
          cancelText="Cancelar"
          okButtonProps={{ loading: submitLoading, disabled: submitLoading }}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={submit}
            onValuesChange={obj => {
              if (obj.user_id) {
                const value = users.find(u => u.id === obj.user_id)

                form.setFieldsValue({ invoice_id: null, proposal_id: null })
                setInvoices([])
                setProposals(value.proposals)
              }

              if (obj.proposal_id) {
                setInvoices([])
                getInvoices(obj.proposal_id)

                form.setFieldsValue({ invoice_id: null })
              }
            }}
          >
            <Form.Item
              label="Selecione o usuário"
              name="user_id"
              rules={[{ required: true, message: 'Informe o usuário' }]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {users.map(el => (
                  <Select.Option key={el.id} value={el.id}>
                    [Cod: {el.id}] {el.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Selecione a proposta"
              name="proposal_id"
              rules={[{ required: true, message: 'Informe o contrato' }]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                readOnly={!proposals.length > 0}
                disabled={!proposals.length > 0}
              >
                {proposals.map(el => (
                  <Select.Option key={el.id} value={el.id}>
                    Contrato #{el.id} - R$
                    {(el.current_price | el.price).toFixed(2)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Selecione a fatura"
              name="invoice_id"
              rules={[{ required: true, message: 'Informe a fatura' }]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                readOnly={!proposals.length > 0}
                disabled={!proposals.length > 0}
              >
                {invoices.map(el => (
                  <Select.Option key={el.id} value={el.id}>
                    Fatura #{el.id} - Valor: R${(el.price + el.fees).toFixed(2)}{' '}
                    Referente à {el.reference}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      </Card>
    </AppLayout>
  )
}

export default FinancialBoletos
