import {
  Grid,
  Flex,
  Heading,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  TableCaption,
  Box,
  Spinner
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { BiCalendarExclamation, BiWallet } from 'react-icons/bi'
import { FaFileInvoiceDollar } from 'react-icons/fa'
import Paginate from 'react-paginate'
import uniqid from 'uniqid'

import useInvoice from '../../hooks/use-invoice'
import ClientModal from '../Client/Modal'
import InvoicesFilters from './Filters'
import InvoicesItem from './TableItem'

function InvoicesContent() {
  const { setId, setQuery, data, meta, loading, getData, setSelected } =
    useInvoice()

  const [clientSelected, setClientSelected] = useState()
  // const [contractSelected, setContractSelected] = useState();

  return (
    <>
      <InvoicesFilters
        onChange={({ id, query }) => {
          if (id) {
            setId(id)
          }

          if (query) {
            setQuery(query)
          }
        }}
      />
      <Grid mt="6" templateColumns="repeat(3, 1fr)" gap="26px">
        <Flex
          bg="white"
          p="5"
          borderRadius="lg"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Heading size="md">
              {loading ? <Spinner size="sm" /> : meta.total}
            </Heading>
            <Text>Faturas geradas</Text>
          </Box>
          <Flex
            alignItems="center"
            justifyContent="center"
            bg="orange.200"
            w="58px"
            h="58px"
            borderRadius="58px"
            fontSize="32px"
            color="blue.900"
          >
            <FaFileInvoiceDollar />
          </Flex>
        </Flex>
        <Flex
          bg="white"
          p="5"
          borderRadius="lg"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Flex alignItems="flex-end">
              <Heading size="md">
                {loading ? <Spinner size="sm" /> : meta.delayed}
              </Heading>
              <Text fontSize="lg" ml={3}>
                / R${' '}
                {loading ? (
                  <Spinner size="sm" />
                ) : (
                  parseFloat(meta.subtotalDelayed).toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })
                )}
              </Text>
            </Flex>
            <Text>Faturas inadimplentes</Text>
          </Box>
          <Flex
            alignItems="center"
            justifyContent="center"
            bg="orange.200"
            w="58px"
            h="58px"
            borderRadius="58px"
            fontSize="32px"
            color="blue.900"
          >
            <BiCalendarExclamation />
          </Flex>
        </Flex>
        <Flex
          bg="white"
          p="5"
          borderRadius="lg"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Heading size="md">
              {loading ? (
                <Spinner size="sm" />
              ) : (
                'R$ ' +
                parseFloat(meta.subtotal).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })
              )}
            </Heading>
            <Text>Subtotal</Text>
          </Box>
          <Flex
            alignItems="center"
            justifyContent="center"
            bg="orange.200"
            w="58px"
            h="58px"
            borderRadius="58px"
            fontSize="32px"
            color="blue.900"
          >
            <BiWallet />
          </Flex>
        </Flex>
      </Grid>
      <ClientModal
        isOpen={!!clientSelected}
        onClose={() => {
          setClientSelected(null)
        }}
        clientId={clientSelected}
      />
      <Box mt="6" bg="white" p="25px" borderRadius="lg">
        <Table variant="simple">
          <TableCaption>
            {loading && <Spinner />}
            {!loading && data && data.length > 0 && (
              <Paginate
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                pageCount={meta && meta.last_page}
                forcePage={meta && meta.current_page - 1}
                pageRangeDisplayed={5}
                marginPagesDisplayed={1}
                previousLabel={'Página anterior'}
                nextLabel={'Próxima página'}
                onPageChange={({ selected }) => {
                  getData(selected + 1)
                }}
              />
            )}
          </TableCaption>
          <Thead>
            <Tr>
              <Th>Fatura</Th>
              <Th>Nº Contrato</Th>
              <Th>Inquilino</Th>
              <Th isNumeric>Valor</Th>
              <Th isNumeric>Taxas extras</Th>
              <Th>Data de vencimento</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.map(invoice => (
              <InvoicesItem
                key={uniqid()}
                data={invoice}
                onClientSelect={setClientSelected}
                onOpen={setSelected}
                onDelete={() => {
                  getData(meta.current_page)
                }}
              />
            ))}
          </Tbody>
        </Table>
      </Box>
    </>
  )
}

export default InvoicesContent
