import {
  Tr,
  Td,
  Flex,
  Text,
  Button,
  Tooltip,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { FiFrown, FiSmile, FiMeh } from 'react-icons/fi'

import useInvoices from '../../hooks/use-invoice'

function InvoicesItem({ data: invoice, onClientSelect }) {
  const { setSelected } = useInvoices()

  const history = useHistory()

  function handleDelayed() {
    const today = dayjs()

    const difference = today.diff(invoice.due_date, 'days')

    if (difference > 0) {
      return {
        color: 'red.100',
        label: `${difference} dia(s) de atraso`,
        icon: <FiFrown size="18px" color="black" />
      }
    } else if (difference === 1) {
      return {
        color: 'orange.100',
        label: 'vence hoje',
        icon: <FiMeh />
      }
    } else {
      var days = difference * -1

      days = days + 1

      return {
        color: days === 1 || days === 0 ? 'orange.100' : 'white',
        label:
          days === 1
            ? 'vence amanhã'
            : days === 0
            ? 'vence hoje'
            : `${days} dias para o vencimento`,
        icon: <FiSmile size="18px" color="black" />
      }
    }
  }

  return (
    <Tr bg={handleDelayed().color}>
      <Td>
        <Tooltip label={handleDelayed().label} aria-label="A tooltip">
          <Flex alignItems="center" justifyContent="flex-start">
            {handleDelayed().icon}
            <Text ml={4}>{invoice.id}</Text>
          </Flex>
        </Tooltip>
      </Td>
      <Td>
        <Popover placement="right">
          <PopoverTrigger>
            <Button
              variant="ghost"
              size="sm"
              _hover={{ bg: 'rgba(0, 0, 0, 0.1)' }}
            >
              {invoice.proposal_id}
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverHeader>Resumo</PopoverHeader>
            <PopoverCloseButton />
            <PopoverBody>
              <Text fontSize="md" fontWeight="bold">
                Endereço:
              </Text>
              <Text fontSize="md" mb={3}>
                {invoice.proposal.property.street},{' '}
                {invoice.proposal.property.number},{' '}
                {invoice.proposal.property.neighborhood}
              </Text>
              <Text fontSize="md">
                <b>Comissão:</b> {invoice.proposal.commission}%
              </Text>
              <Text fontSize="md">
                <b>Preço atual:</b> R${' '}
                {parseFloat(invoice.proposal.current_price).toLocaleString(
                  'pt-BR',
                  { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                )}
              </Text>
              <Text fontSize="md">
                <b>Próximo reajuste:</b>{' '}
                {invoice.proposal.delayed
                  ? 'Não efetuado no período.'
                  : dayjs(invoice.proposal.next_readjustment).format(
                      'DD/MM/YYYY'
                    )}
              </Text>
              <Button
                colorScheme="orange"
                size="sm"
                mt={4}
                onClick={() => {
                  history.push(`/contracts/info/${invoice.proposal_id}`)
                }}
              >
                Abrir contrato
              </Button>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Td>
      <Td>
        <Popover placement="right">
          <PopoverTrigger>
            <Button
              variant="ghost"
              size="sm"
              _hover={{ bg: 'rgba(0, 0, 0, 0.1)' }}
            >
              {invoice.user.name}
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverHeader>Resumo</PopoverHeader>
            <PopoverCloseButton />
            <PopoverBody>
              {invoice.user.email !== '-' && (
                <Text fontSize="md">
                  <b>E-mail:</b> {invoice.user.email}
                </Text>
              )}
              {invoice.user.cpf_cnpj && (
                <Text fontSize="md">
                  <b>CPF / CNPJ:</b> {invoice.user.cpf_cnpj}
                </Text>
              )}
              {invoice.user.phone !== '-' && (
                <Text fontSize="md">
                  <b>Telefone:</b> {invoice.user.phone}
                </Text>
              )}
              <Button
                colorScheme="orange"
                size="sm"
                mt={4}
                onClick={() => {
                  onClientSelect(invoice.user.id)
                }}
              >
                Abrir ficha
              </Button>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Td>
      <Td isNumeric>
        R$
        {parseFloat(invoice.price).toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}
      </Td>
      <Td isNumeric>
        R$
        {parseFloat(invoice.fees).toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}
      </Td>
      <Td>{dayjs(invoice.due_date).format('DD/MM/YYYY')}</Td>
      <Td>
        <Button
          size="sm"
          bg="white"
          variant="ghost"
          onClick={() => {
            setSelected(invoice.id)
          }}
        >
          Abrir
        </Button>
      </Td>
    </Tr>
  )
}

export default InvoicesItem
