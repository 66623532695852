import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  useToast,
  useDisclosure
} from '@chakra-ui/react'
import imageCompression from 'browser-image-compression'
import React, { useCallback, useEffect, useState, createContext } from 'react'
import { useImmer } from 'use-immer'

import api from '../services/api'

function PropertyProvider({ id, children, onCloseModal }) {
  const toast = useToast()

  const {
    isOpen: isOpenDelete,
    onOpen: onDelete,
    onClose: onCloseDelete
  } = useDisclosure()

  const [loading, setLoading] = useState(true)
  const [data, updateData] = useImmer({})

  const [uploading, setUploading] = useState(false)
  const [cloning, setCloning] = useState(false)
  const [updating, setUpdating] = useState(false)
  const [updatingDefault, setUpdatingDefault] = useState(false)
  const [updatingInformation, setUpdatingInformation] = useState(false)
  const [updatingLoading, setUpdatingLoading] = useState(false)

  const [deleteLoading, setDeleteLoading] = useState(false)

  const getData = useCallback(
    async (withLoading = true) => {
      if (withLoading) {
        setLoading(true)
      }

      try {
        const { data } = await api.get(`/property/${id}/admin`)

        updateData(draft => {
          return Object.assign(draft, data)
        })
      } catch {
        toast({
          title: 'Oopss...',
          description: 'Não foi possível retornar os dados do imóvel',
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      }

      setLoading(false)
    },
    [id, toast, updateData]
  )

  async function clone() {
    setCloning(true)

    try {
      await api.post(`/property/${id}/clone`)

      toast({
        title: 'Pronto!',
        description: 'Dados clonados.',
        status: 'success',
        duration: 5000,
        isClosable: true
      })

      getData()
      onCloseModal()
    } catch (err) {
      console.log(err)

      toast({
        title: 'Oopss...',
        description: 'Não foi clonar os dados do imóvel',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }

    setCloning(false)
  }

  async function updateValues() {
    setUpdatingLoading(true)

    try {
      if (updatingDefault) {
        const toUpdate = JSON.parse(JSON.stringify(data))

        delete toUpdate.information
        delete toUpdate.fees
        delete toUpdate.images
        delete toUpdate.features
        delete toUpdate.schedules
        delete toUpdate.relationship
        delete toUpdate.created_at
        delete toUpdate.updated_at

        await api.patch(`/property/${id}`, toUpdate)
      }

      if (updatingInformation) {
        await api.put(`/property/${id}/information`, data.information)
      }

      toast({
        title: 'Pronto!',
        description: 'Dados atualizados',
        status: 'success',
        duration: 5000,
        isClosable: true
      })

      setUpdating(false)
      setUpdatingInformation(false)
      setUpdatingDefault(false)
    } catch (err) {
      console.log(err)

      toast({
        title: 'Oopss...',
        description: 'Não foi possível atualizar os dados do imóvel',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }

    setUpdatingLoading(false)
  }

  async function destroy() {
    setDeleteLoading(true)

    try {
      await api.delete(`/property/${id}`)

      toast({
        title: 'Pronto!',
        description: 'O imóvel foi deletado',
        status: 'success',
        duration: 5000,
        isClosable: true
      })

      onCloseModal()
    } catch {
      toast({
        title: 'Oopss...',
        description: 'Não foi possível deletar o imóvel',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }

    setDeleteLoading(false)
  }

  async function addFeature(name) {
    try {
      await api.put(`/property/${id}/features`, { data: [name] })

      getData(false)
    } catch (err) {
      toast({
        title: 'Oopss...',
        description: 'Não foi possível remover a imagem',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }

  async function addFee({ name, price }) {
    try {
      await api.put(`/property/${id}/fees`, {
        data: [
          {
            name,
            price
          }
        ]
      })

      getData(false)
    } catch (err) {
      toast({
        title: 'Oopss...',
        description: 'Não foi possível remover a imagem',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }

  async function onImageMoves() {
    api
      .patch(`/property/${id}/images/position`, {
        data: data.images
      })
      .catch(() => {
        toast({
          title: 'Oopss...',
          description: 'Não foi possível mover a posição das imagens',
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      })
  }

  async function onImageDelete(imageId) {
    try {
      await api.delete(`/property/${imageId}/images`)

      updateData(draft => {
        draft.images = data.images.filter(el => el.id !== imageId)
      })
    } catch (err) {
      toast({
        title: 'Oopss...',
        description: 'Não foi possível remover a imagem',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }

  async function onFeatureDelete(featureId) {
    try {
      await api.delete(`/property/${featureId}/features`)

      updateData(draft => {
        draft.features = data.features.filter(el => el.id !== featureId)
      })
    } catch (err) {
      toast({
        title: 'Oopss...',
        description: 'Não foi possível remover a característica',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }

  async function onFeeDelete(feeId) {
    try {
      await api.delete(`/property/${feeId}/fees`)

      updateData(draft => {
        draft.fees = data.fees.filter(el => el.id !== feeId)
      })
    } catch (err) {
      toast({
        title: 'Oopss...',
        description: 'Não foi possível remover a taxa',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }

  async function handleUpload(files) {
    setUploading(true)

    const options = {
      maxWidthOrHeight: 1920,
      maxSizeMB: 0.6
    }

    for (const file of files) {
      const formData = new FormData()

      const compressedFile = await imageCompression(file, options)

      formData.append('file', compressedFile)

      await api.put(`/property/${id}/images`, formData).catch(() => {
        toast({
          title: 'Oopss...',
          description: 'Não foi possível enviar uma das imagens',
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      })
    }

    toast({
      title: 'Pronto!',
      description: 'Imagens enviadas com sucesso',
      status: 'success',
      duration: 5000,
      isClosable: true
    })

    getData(false)
    setUploading(false)
  }

  useEffect(() => {
    if (id) {
      getData()
    }
  }, [id, getData])

  return (
    <PropertyContext.Provider
      value={{
        id,
        loading,
        uploading,
        data,
        addFeature,
        updateData,
        updating,
        setUpdating,
        cloning,
        setCloning,
        setUpdatingDefault,
        setUpdatingInformation,
        updatingLoading,
        updateValues,
        onImageMoves,
        onImageDelete,
        handleUpload,
        addFee,
        onFeatureDelete,
        onFeeDelete,
        clone,
        onDelete,
        getData
      }}
    >
      <AlertDialog isOpen={isOpenDelete} onClose={onCloseDelete}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Deletar imóvel
            </AlertDialogHeader>

            <AlertDialogBody>
              Tem certeza que deseja deletar o imóvel? Esta ação não poderá ser
              desfeita
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={onCloseDelete}>Cancelar</Button>
              <Button
                colorScheme="red"
                onClick={destroy}
                ml={3}
                isLoading={deleteLoading}
              >
                Deletar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      {children}
    </PropertyContext.Provider>
  )
}

export const PropertyContext = createContext({})
export default PropertyProvider
