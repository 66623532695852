import {
  Box,
  Grid,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Heading,
  Button,
  Flex,
  useDisclosure
} from '@chakra-ui/react'
import React from 'react'
import DebounceInput from 'react-debounce-input'
import { FiUserPlus } from 'react-icons/fi'

import ClientCreate from './Create'

function ClientFilters({ onChange }) {
  const {
    onOpen: onOpenCreate,
    isOpen: isOpenCreate,
    onClose: onCloseCreate
  } = useDisclosure()

  function handleClick() {
    onOpenCreate()
  }

  return (
    <Box bg="white" boxShadow="xs" p={6} borderRadius={8} mb={10}>
      <ClientCreate isOpen={isOpenCreate} onClose={onCloseCreate} />
      <Flex
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={6}
      >
        <Heading size="lg">Filtro de busca</Heading>
        <Button size="md" leftIcon={<FiUserPlus />} onClick={handleClick}>
          Novo cadastro
        </Button>
      </Flex>
      <Grid templateColumns="140px calc(100% - 160px)" gap="20px">
        <FormControl id="id">
          <FormLabel>ID</FormLabel>
          <Input
            type="number"
            onChange={e => onChange({ id: e.target.value })}
          />
          <FormHelperText>Cód. Interno</FormHelperText>
        </FormControl>
        <FormControl id="query">
          <FormLabel>Nome, e-mail, documento ou telefone</FormLabel>
          <DebounceInput
            element={Input}
            minLength={2}
            debounceTimeout={300}
            onChange={e => onChange({ query: e.target.value || 'NULL' })}
          />
          <FormHelperText>
            Pesquise por um dado de contato do cliente
          </FormHelperText>
        </FormControl>
      </Grid>
    </Box>
  )
}

export default ClientFilters
