import {
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button
} from '@chakra-ui/react'
import React from 'react'

import useClient from '../../hooks/use-client'
import ClientHeader from './Header'
import ClientTabs from './Tabs'

function ClientModalContent({ onClose }) {
  const { updating, updateValues, updatingLoading, onDelete } = useClient()

  return (
    <ModalContent>
      <ModalHeader>Cadastro do Cliente</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <ClientHeader />
        <ClientTabs />
      </ModalBody>
      <ModalFooter>
        <Button variant="ghost" mr={3} onClick={onClose}>
          Fechar
        </Button>
        <Button
          bg="red.100"
          color="red.600"
          _hover={{ bg: 'red.200' }}
          onClick={onDelete}
        >
          Deletar cadastro
        </Button>
        {updating && (
          <Button
            bg="blue.100"
            color="blue.600"
            ml={3}
            transition="all ease .3s"
            onClick={updateValues}
            isLoading={updatingLoading}
            _hover={{ bg: 'blue.200' }}
          >
            Salvar alterações
          </Button>
        )}
      </ModalFooter>
    </ModalContent>
  )
}

export default ClientModalContent
