import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Grid,
  Button,
  Input,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Heading,
  Spinner,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  ButtonGroup,
  Flex,
  IconButton,
  useDisclosure,
  useToast,
  Textarea,
  useClipboard,
  Divider
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import React, { useState, useEffect, useCallback } from 'react'
import { BsCheck } from 'react-icons/bs'
import { IoMdCopy } from 'react-icons/io'
import { useImmer } from 'use-immer'

import banks from '../../helpers/variables/banks'
import api from '../../services/api'
import PropertyModal from '../Property/Modal'
import PropertyPreview from '../Property/Preview'
import TransferAttachments from './Attachments'

function TransferModal({ id, isOpen, onClose, onChange }) {
  const [data, updateData] = useImmer({})

  const [loading, setLoading] = useState(true)
  const [actionLoading, setActionLoading] = useState(false)
  const [refundLoading, setRefundLoading] = useState(false)
  const [error, setError] = useState(false)

  const [selected, setSelected] = useState()

  const toast = useToast()

  const {
    isOpen: isPopOpen,
    onOpen: onPopOpen,
    onClose: onPopClose
  } = useDisclosure()

  const { hasCopied: hasBankNameCopied, onCopy: onBankNameCopy } = useClipboard(
    data.proposal && data.proposal.bank_full_name
  )
  const { hasCopied: hasBankDocumentCopied, onCopy: onBankDocumentCopy } =
    useClipboard(data.proposal && data.proposal.bank_cpf)
  const { hasCopied: hasBankInstitutionCopied, onCopy: onBankInstitutionCopy } =
    useClipboard(data.proposal && getBankName(data.proposal.bank_code))
  const { hasCopied: hasBankAccountCopied, onCopy: onBankAccountCopy } =
    useClipboard(data.proposal && data.proposal.bank_account)
  const { hasCopied: hasBankAgencyCopied, onCopy: onBankAgencyCopy } =
    useClipboard(data.proposal && data.proposal.bank_agency)
  const { hasCopied: hasBankPixCopied, onCopy: onBankPixCopy } = useClipboard(
    data.proposal && data.proposal.bank_pix
  )

  const getData = useCallback(async () => {
    setLoading(true)

    try {
      const { data: result } = await api.get(`/transfer/${id}`)

      setError(false)
      updateData(draft => {
        return Object.assign(draft, result)
      })
    } catch (err) {
      setError(true)
    }

    setLoading(false)
  }, [id, updateData])

  useEffect(() => {
    if (id) {
      getData()
    }
  }, [getData, id])

  function handleTransferAmount(e) {
    updateData(draft => {
      draft.amount = e.target.value
    })
  }

  function getBankName(code) {
    const bank = banks.find(el => el.value === code)

    if (!bank) {
      return 'Não cadastrado'
    }

    return bank.label
  }

  async function refundTransfer() {
    setRefundLoading(true)

    try {
      await api.delete(`/transfer/${id}/refund`)

      toast({
        title: 'Pronto!',
        description: 'A fatura voltou para pendente.',
        status: 'success'
      })

      if (onChange) {
        onChange()
      }

      onClose()
    } catch (err) {
      toast({
        title: 'Oops!',
        description: 'Ocorreu um problema ao tentar estornar a transferência.',
        status: 'warning'
      })
    }

    setRefundLoading(false)
  }

  async function changeStatus(status) {
    setActionLoading(true)

    try {
      await api.patch(`/transfer/${id}`, { status })

      updateData(draft => {
        return Object.assign(draft, { status })
      })

      if (onChange) {
        onChange()
      }
    } catch (err) {
      setError(true)
    }

    setActionLoading(false)
  }

  console.log(data)

  return (
    <>
      <PropertyModal
        isOpen={!!selected}
        onClose={() => {
          setSelected(null)
        }}
        id={selected}
      />
      <Modal isOpen={isOpen} onClose={onClose} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Repasse</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {loading ? (
              <Box
                h="400px"
                d="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Spinner />
              </Box>
            ) : error ? (
              <Box
                h="400px"
                d="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Heading>Não encontramos esse repasse</Heading>
              </Box>
            ) : (
              <Grid templateColumns="2fr 1fr" gap="25px">
                <Box>
                  <Tabs>
                    <TabList>
                      <Tab>Repasse</Tab>
                      <Tab>Anexos</Tab>
                    </TabList>
                    <TabPanels>
                      <TabPanel>
                        <Grid templateColumns="repeat(2, 1fr)" gap="16px">
                          <Box>
                            <Text>Valor</Text>
                            <Input
                              type="number"
                              value={data.amount}
                              onChange={handleTransferAmount}
                              mb={3}
                            />
                          </Box>
                          {data.status === 'DONE' && (
                            <Box>
                              <Text>Data da transferência</Text>
                              <Input
                                value={dayjs(data.updated_at).format(
                                  'DD/MM/YYYY'
                                )}
                              />
                            </Box>
                          )}
                        </Grid>
                        <Divider my={4} />
                        <Heading size="md" fontWeight="400" mb={4}>
                          Dados bancários
                        </Heading>
                        <Grid templateColumns="2fr 1fr" gap="26px" mb={3}>
                          <Box>
                            <Text>Nome completo</Text>
                            <Flex>
                              <Input
                                w="100%"
                                value={
                                  data.proposal.bank_full_name ||
                                  'Não encontrado'
                                }
                                isReadOnly
                              />
                              <IconButton
                                icon={
                                  hasBankNameCopied ? <BsCheck /> : <IoMdCopy />
                                }
                                ml={2}
                                colorScheme={
                                  hasBankNameCopied ? 'whatsapp' : 'gray'
                                }
                                onClick={onBankNameCopy}
                              />
                            </Flex>
                          </Box>
                          <Box>
                            <Text>CPF</Text>
                            <Flex>
                              <Input
                                w="100%"
                                value={data.proposal.bank_cpf}
                                isReadOnly
                              />
                              <IconButton
                                icon={
                                  hasBankDocumentCopied ? (
                                    <BsCheck />
                                  ) : (
                                    <IoMdCopy />
                                  )
                                }
                                ml={2}
                                colorScheme={
                                  hasBankDocumentCopied ? 'whatsapp' : 'gray'
                                }
                                onClick={onBankDocumentCopy}
                              />
                            </Flex>
                          </Box>
                        </Grid>
                        <Box mb={3}>
                          <Text>Banco / Instituição:</Text>
                          <Flex>
                            <Input
                              w="100%"
                              value={`${
                                data.proposal.bank_code
                              } - ${getBankName(data.proposal.bank_code)}`}
                              isReadOnly
                            />
                            <IconButton
                              icon={
                                hasBankInstitutionCopied ? (
                                  <BsCheck />
                                ) : (
                                  <IoMdCopy />
                                )
                              }
                              ml={2}
                              colorScheme={
                                hasBankInstitutionCopied ? 'whatsapp' : 'gray'
                              }
                              onClick={onBankInstitutionCopy}
                            />
                          </Flex>
                        </Box>
                        <Grid
                          templateColumns="repeat(3, 1fr)"
                          gap="26px"
                          mb={3}
                        >
                          <Box>
                            <Text>Nº da Conta</Text>
                            <Flex>
                              <Input
                                w="100%"
                                value={data.proposal.bank_account}
                                isReadOnly
                              />
                              <IconButton
                                icon={
                                  hasBankAccountCopied ? (
                                    <BsCheck />
                                  ) : (
                                    <IoMdCopy />
                                  )
                                }
                                ml={2}
                                colorScheme={
                                  hasBankAccountCopied ? 'whatsapp' : 'gray'
                                }
                                onClick={onBankAccountCopy}
                              />
                            </Flex>
                          </Box>
                          <Box>
                            <Text>Agência</Text>
                            <Flex>
                              <Input
                                w="100%"
                                value={data.proposal.bank_agency}
                                isReadOnly
                              />
                              <IconButton
                                icon={
                                  hasBankAgencyCopied ? (
                                    <BsCheck />
                                  ) : (
                                    <IoMdCopy />
                                  )
                                }
                                ml={2}
                                colorScheme={
                                  hasBankAgencyCopied ? 'whatsapp' : 'gray'
                                }
                                onClick={onBankAgencyCopy}
                              />
                            </Flex>
                          </Box>
                          <Box>
                            <Text>Chave PIX</Text>
                            <Flex>
                              <Input
                                w="100%"
                                value={data.proposal.bank_pix}
                                isReadOnly
                              />
                              <IconButton
                                icon={
                                  hasBankPixCopied ? <BsCheck /> : <IoMdCopy />
                                }
                                ml={2}
                                colorScheme={
                                  hasBankPixCopied ? 'whatsapp' : 'gray'
                                }
                                onClick={onBankPixCopy}
                              />
                            </Flex>
                          </Box>
                        </Grid>
                        <Text>Observações</Text>
                        <Textarea value={data.proposal.bank_obs} />
                      </TabPanel>
                      <TabPanel>
                        <TransferAttachments
                          id={id}
                          receipt={data.receipt}
                          receiptUrl={data.url}
                          defaultAttachments={data.files}
                        />
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </Box>
                <PropertyPreview
                  data={data.property}
                  onSelect={() => {
                    setSelected(data.property.id)
                  }}
                />
              </Grid>
            )}
          </ModalBody>
          <ModalFooter>
            <Popover
              returnFocusOnClose={false}
              isOpen={isPopOpen}
              onOpen={onPopOpen}
              onClose={onPopClose}
              placement="right"
              closeOnBlur={false}
            >
              <PopoverTrigger>
                <Button colorScheme="red" mr={3}>
                  Estornar transferência
                </Button>
              </PopoverTrigger>
              <PopoverContent p={5}>
                <PopoverHeader fontWeight="semibold">Confirmação</PopoverHeader>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverBody py="5">
                  Você tem certeza que deseja estornar essa transação para
                  pendente?
                </PopoverBody>
                <PopoverFooter d="flex" justifyContent="flex-end">
                  <ButtonGroup size="sm">
                    <Button variant="outline" onClick={onPopClose}>
                      Cancelar
                    </Button>
                    <Button
                      colorScheme="red"
                      isLoading={refundLoading}
                      onClick={refundTransfer}
                    >
                      Sim
                    </Button>
                  </ButtonGroup>
                </PopoverFooter>
              </PopoverContent>
            </Popover>
            {data.status !== 'DONE' && (
              <Button
                colorScheme="whatsapp"
                isLoading={actionLoading}
                onClick={() => changeStatus('DONE')}
              >
                Concluir transferência
              </Button>
            )}
            <Button
              variant="outline"
              ml={3}
              onClick={() => {
                window.open(
                  `${'https://core-api.advqxs.easypanel.host'}/financial/${
                    data.id
                  }/receipt`,
                  '_blank'
                )
              }}
            >
              Baixar recibo
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default TransferModal
