import { Box, Badge, Checkbox } from '@chakra-ui/react'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import React, { memo } from 'react'
import { AiOutlineEye } from 'react-icons/ai'
import { useHistory } from 'react-router-dom'

import useProperties from '../../hooks/use-properties'

dayjs.extend(isBetween)

function PropertyPreview({ data, relation, withStatus, onSelect, selectable }) {
  const history = useHistory()
  const properties = useProperties()

  function handleClick() {
    if (onSelect) {
      onSelect()
    } else {
      history.push('/properties/info/' + data.id)
    }
  }

  return (
    <Box
      pos="relative"
      maxW="sm"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      cursor="pointer"
      bg="white"
      transition="all ease .3s"
      _hover={{ transform: 'scale(0.99)' }}
    >
      {selectable && (
        <Box
          pos="absolute"
          zIndex="999"
          bg="white"
          borderRadius="lg"
          p="2"
          top="5px"
          left="5px"
        >
          <Checkbox
            size="lg"
            colorScheme="orange"
            mb="0"
            isChecked={!!properties.selected.find(el => el.id === data.id)}
            onChange={() => properties.selectToShare(data)}
          />
        </Box>
      )}

      <Box
        w="100%"
        h="180px"
        backgroundSize="cover"
        backgroundPosition="center"
        backgroundImage={`url(${
          data && data.images.length > 0
            ? data.images[0].url
            : 'https://via.placeholder.com/728x400.png?text=Sem+imagem'
        })`}
        onClick={handleClick}
      />
      <Box p="6" onClick={handleClick}>
        <Box d="flex" alignItems="baseline">
          {data && !!data.spotlight && (
            <Badge borderRadius="full" px="2" colorScheme="orange" mr="2">
              Destaque
            </Badge>
          )}
          {relation && (
            <>
              {data.pivot.relationship === 'OWNER' ? (
                <Badge borderRadius="full" px="2" colorScheme="blue" mr="2">
                  Proprietário
                </Badge>
              ) : data.pivot.relationship === 'TENANT' ? (
                <Badge borderRadius="full" px="2" colorScheme="green" mr="2">
                  Inquilino
                </Badge>
              ) : (
                <Badge borderRadius="full" px="2" colorScheme="purple" mr="2">
                  Fiador
                </Badge>
              )}
            </>
          )}
          {withStatus &&
            (data.status === 'UNAVAILABLE' || data.status === 'RENTED') && (
              <Badge borderRadius="full" px="2" colorScheme="orange" mr="2">
                Indisponível
              </Badge>
            )}
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
          >
            {data && data.bedrooms} Qua. &bull; {data && data.bathrooms} Ban.
            &bull; {data && data.garage} Gar.
          </Box>
        </Box>

        <Box
          mt="1"
          fontWeight="semibold"
          as="h4"
          lineHeight="tight"
          isTruncated
        >
          {data &&
            `${data.street}, ${data.number}, ${data.neighborhood}, ${data.city}`}
        </Box>

        <Box fontSize="22px" color="orange.300" mt={3}>
          {data && `R$${Number(data.price).toLocaleString('pt-BR')}`}{' '}
        </Box>

        <Box d="flex" mt="3" alignItems="center" justifyContent="space-between">
          <Box d="flex" alignItems="center">
            <AiOutlineEye color={'teal.500'} />
            <Box as="span" ml="2" color="gray.600" fontSize="sm">
              {data && data.views} visualizações
            </Box>
          </Box>
          <Badge>ID {data.id}</Badge>
        </Box>
      </Box>
    </Box>
  )
}

export default memo(PropertyPreview)
