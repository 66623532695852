import { ArrowUpOutlined } from '@ant-design/icons'
import { useDisclosure } from '@chakra-ui/react'
import fullcalendarLocaleBr from '@fullcalendar/core/locales/pt-br'
import fullcalendarDayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import FullCalendar from '@fullcalendar/react'
import {
  Table,
  Tag,
  Row,
  Col,
  Card,
  Modal,
  Statistic,
  Button,
  Input,
  Select,
  Form,
  DatePicker,
  Popover,
  message
} from 'antd'
import Search from 'antd/lib/input/Search'
import React, { useState } from 'react'
import { FiMousePointer } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import TransferModal from '../../components/Transfer/Modal'
import usePage from '../../contexts/hooks/page'
import AppLayout from '../../layouts/AppLayout'
import api from '../../services/api'

function FinancialTransfers() {
  const [form] = Form.useForm()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selected, setSelected] = useState()

  const page = usePage()
  const pageSearch = page.get('transfers', 'search')

  const [owners, setOwners] = useState([])
  const [owner, setOwner] = useState(null)

  const [formLoading, setFormLoading] = useState(false)

  const [modalAdd, setModalAdd] = useState(false)

  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)

  const [search, setSearch] = useState('')

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (text, record) => (
        <Popover
          placement="right"
          content={
            <>
              <h6 className="m-0">Inquilino:</h6>
              <p className="font-weight-light m-0">
                {record.proposal.user.name}
              </p>
              <h6 className="m-0">Imóvel:</h6>
              <p className="font-weight-light m-0">
                {record.property.neighborhood}, nº {record.property.number} /{' '}
                {record.property.complement} - {record.property.city}
              </p>
            </>
          }
        >
          <FiMousePointer style={{ marginRight: 15 }} />
          {text}
        </Popover>
      )
    },
    {
      title: 'Proprietário',
      render: (text, record) => (
        <Link to={`/clients/info/${record.user.id}`}>{record.user.name}</Link>
      )
    },
    {
      title: 'Contrato',
      dataIndex: 'proposal_id',
      key: 'proposal_id',
      render: text => <Link to={`/contracts/info/${text}`}>{text}</Link>
    },
    {
      title: 'Valor',
      dataIndex: 'amount',
      key: 'amount',
      render: text =>
        'R$' +
        parseFloat(text).toLocaleString('pt-BR', { minimumFractionDigits: 2 })
    },
    {
      title: 'Referência',
      dataIndex: 'reference',
      key: 'reference',
      render: (text, record) => record.invoice.reference
    },
    {
      title: 'Prazo de Repasse',
      dataIndex: 'due_date',
      key: 'due_date',
      render: text => new Date(text).toLocaleDateString('pt-BR')
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: () => <Tag color="orange">Pendente</Tag>
    },
    {
      title: 'Ações',
      render: (_text, record) => (
        <>
          <Button
            type="primary"
            onClick={() => {
              onOpen()
              setSelected(record.id)
            }}
          >
            Abrir
          </Button>
          {/*
          <Upload
            name="file"
            action={`${process.env.REACT_APP_ENDPOINT_API}/financial/transfers/${record.id}/receipt`}
            method="PUT"
            headers={{
              Authorization: `Bearer ${getToken()}`,
            }}
            showUploadList={false}
            onChange={({ file }) => {
              if (file.status === "uploading") {
                message.loading("Fazendo upload...");
              }

              if (file.status === "success") {
                message.loading("Processando...");
              }

              if (file.status === "done") {
                message.success("Recibo de transferência anexado com sucesso!");

                getData();
              }

              if (file.status === "error") {
                message.error(
                  "Ocorreu um erro ao tentar processar o retorno dos boletos"
                );
              }
            }}
          >
            <Button type="primary">Transferido</Button>
          </Upload> */}
        </>
      )
    }
  ]

  React.useEffect(() => {
    getData()
    getOwners()
  }, [])

  async function getData() {
    setLoading(true)

    try {
      const { data: resultData } = await api.get('/financial/transfers')

      setData(resultData)
    } catch (err) {
      message.error('Ocorreu um erro ao tentar recuperar dados')
    }

    setLoading(false)
  }

  async function updateEvent(id, date) {
    try {
      await api.patch(`/financial/transfers/${id}/date`, { date })

      message.success('Repasse reagendado')
    } catch (err) {
      message.error('Ocorreu um erro ao tentar recuperar dados')
    }
  }

  async function getOwners() {
    try {
      const { data } = await api.get('/user/owners')
      setOwners(data)
    } catch (err) {
      console.log('Erro interno do servidor, tente novamente mais tarde.')
    }
  }

  async function create() {
    setFormLoading(true)

    try {
      await api.post('/financial/transfers', form.getFieldsValue())

      setModalAdd(false)
    } catch (err) {
      message.error('Ocorreu um erro ao tentar adicionar manualmente')
    }

    setFormLoading(false)
  }

  function filtered() {
    return data.table
      .map(el => ({ ...el, key: el.id }))
      .filter(el =>
        JSON.stringify(el).toLowerCase().includes(search.toLowerCase())
      )
  }

  React.useEffect(() => {
    if (pageSearch) {
      setSearch(pageSearch || '')
    }
  }, [pageSearch])

  return (
    <AppLayout title="Resumo Financeiro" keyPage="3" subKeyPage="sub1">
      <TransferModal
        id={selected}
        isOpen={isOpen}
        onClose={onClose}
        onChange={getData}
      />
      <Row gutter={25}>
        <Col md={8}>
          <Card loading={loading}>
            <Statistic
              title="Repasses pendentes"
              value={data && data.pending}
              precision={0}
              valueStyle={{ color: '#00a8ff' }}
            />
          </Card>
        </Col>
        <Col md={8}>
          <Card loading={loading}>
            <Statistic
              title="Valor total de repasse"
              value={
                data &&
                parseFloat(data.total).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })
              }
              precision={2}
              valueStyle={{ color: '#3f8600' }}
              prefix={<ArrowUpOutlined />}
            />
          </Card>
        </Col>
        <Col md={8}>
          <Card loading={loading}>
            <Statistic
              title="Repasses atrasados"
              value={data && data.late}
              precision={0}
              valueStyle={{ color: '#e84118' }}
            />
          </Card>
        </Col>
      </Row>
      <br />
      <Card loading={loading}>
        <FullCalendar
          defaultView="dayGridMonth"
          plugins={[fullcalendarDayGridPlugin, interactionPlugin]}
          editable
          droppable
          weekends={true}
          locale={fullcalendarLocaleBr}
          height={500}
          customButtons={{
            update: {
              text: 'Atualizar calendário',
              click: getData
            },
            addTransfer: {
              text: 'Adicionar manual',
              click: () => setModalAdd(true)
            }
          }}
          header={{
            left: 'prev,next today',
            center: 'title',
            right: 'addTransfer, update'
          }}
          events={data && data.events}
          eventLimit={10}
          eventLimitText="mostrar mais"
          eventClick={({ event }) => {
            setSelected(event.id)
            onOpen()
          }}
          eventDrop={e => {
            const { id, start } = e.event
            updateEvent(id, start)
          }}
        />
      </Card>
      <br />
      <Card title="Repasses pendentes">
        {!loading && (
          <Search
            placeholder="Buscar..."
            defaultValue={pageSearch}
            onSearch={text => {
              setSearch(text)
              page.update('transfers', { search: text })
            }}
            size="large"
          />
        )}
        <br />
        <br />
        <Table
          columns={columns}
          dataSource={
            search.length > 0
              ? data
                ? filtered()
                : []
              : data
              ? data.table.map(el => ({ key: el.id, ...el }))
              : []
          }
          locale={{ emptyText: 'Não há repasses pendentes' }}
          loading={loading}
        />
      </Card>
      <Modal
        title="Adicionar manualmente"
        visible={modalAdd}
        onOk={create}
        onCancel={() => setModalAdd(false)}
        okText="Salvar"
        okButtonProps={{ loading: formLoading }}
        cancelText="Cancelar"
      >
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          layout="horizontal"
          onValuesChange={obj => {
            if (obj.user_id) {
              setOwner(obj.user_id)
            }

            if (obj.property_id) {
              const property = owners[
                owners.findIndex(el => el.id === owner)
              ].properties.find(el => el.id === obj.property_id)
              const discount = property.price * 0.1

              form.setFieldsValue({
                amount: (property.price - discount).toFixed(2)
              })
            }
          }}
        >
          <Form.Item
            label="Proprietário"
            name="user_id"
            rules={[{ required: true, message: 'Informe o proprietário' }]}
          >
            <Select
              showSearch
              filterOption={(input, option) =>
                option.children
                  .toString()
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {owners.map(el => (
                <Select.Option key={el.id} value={el.id}>
                  [{el.id}] {el.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Imóvel"
            name="property_id"
            rules={[{ required: true, message: 'Informe o imóvel' }]}
          >
            <Select
              disabled={!owner}
              showSearch
              filterOption={(input, option) =>
                option.children
                  .toString()
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {owner &&
                owners[owners.findIndex(el => el.id === owner)].properties.map(
                  el => {
                    if (el.status === 'RENTED') {
                      return (
                        <Select.Option key={el.id} value={el.id}>
                          {el.street}, {el.number} - {el.neighborhood} R$
                          {el.price}
                        </Select.Option>
                      )
                    }

                    return null
                  }
                )}
            </Select>
          </Form.Item>
          <Form.Item
            label="Valor"
            name="amount"
            rules={[{ required: true, message: 'Informe o valor' }]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            label="Data"
            name="due_date"
            rules={[{ required: true, message: 'Informe a data de repasse' }]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            label="Status"
            name="status"
            rules={[{ required: true, message: 'Informe o tipo' }]}
          >
            <Select>
              <Select.Option value="PENDING">Pendente</Select.Option>
              <Select.Option value="DONE">Efetuado</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </AppLayout>
  )
}

export default FinancialTransfers
