import {
  Box,
  Grid,
  FormControl,
  FormLabel,
  FormHelperText,
  Select,
  Input,
  Heading,
  Flex,
  Button,
  ButtonGroup,
  IconButton,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import React from 'react'
import DebounceInput from 'react-debounce-input'
import { AiOutlineClose, AiOutlineShareAlt } from 'react-icons/ai'
import slugify from 'slugify'
import uniqid from 'uniqid'

import useProperties from '../../hooks/use-properties'
import PropertyCreate from './Create'

const locations = [
  ['APARTMENT', 'Apartamento'],
  ['ROOF', 'Cobertura'],
  ['HOUSE', 'Casa'],
  ['FARM', 'Fazenda'],
  ['CLINIC', 'Consultório'],
  ['SHED', 'Galpão'],
  ['GARAGE', 'Garagem'],
  ['KITNET', 'Kitnet'],
  ['LOT', 'Lote'],
  ['STORE', 'Loja'],
  ['COMERCIAL', 'Sala Comercial'],
  ['SITE', 'Sítio']
]

function PropertyFilters({ onChange, onCreate }) {
  const {
    onOpen: onOpenCreate,
    isOpen: isOpenCreate,
    onClose: onCloseCreate
  } = useDisclosure()

  const toast = useToast()

  const { selected, clearAll } = useProperties()

  function copy() {
    const links = selected.map(el => {
      return `https://mibimobiliaria.com.br/imovel/${
        el.type === 'RENT' ? 'alugar' : 'comprar'
      }/${el.id}/${slugify((el.street + ' ' + el.city).toLowerCase())}`
    })

    navigator.clipboard.writeText(links.join('\n\r'))

    toast({
      title: 'Pronto! Agora é só colar',
      description: 'Os links foram copiados para sua área de transferência',
      status: 'info',
      duration: 5000,
      isClosable: true
    })
  }

  return (
    <Box bg="white" boxShadow="xs" p={6} borderRadius={8} mb={10}>
      <PropertyCreate
        isOpen={isOpenCreate}
        onClose={onCloseCreate}
        onCreate={onCreate}
      />
      <Flex
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={6}
      >
        <Heading size="lg">Filtro de busca</Heading>
        <Box>
          {selected.length > 0 && (
            <ButtonGroup size="md" isAttached variant="outline" mr={3}>
              <Button
                leftIcon={<AiOutlineShareAlt />}
                mr="-px"
                colorScheme="blackAlpha"
                onClick={copy}
              >
                Compartilhar {selected.length} anúncios
              </Button>
              <IconButton
                colorScheme="blackAlpha"
                aria-label="Limpar seleções"
                icon={<AiOutlineClose />}
                onClick={clearAll}
              />
            </ButtonGroup>
          )}
          <Button colorScheme="orange" onClick={onOpenCreate}>
            Novo imóvel
          </Button>
        </Box>
      </Flex>
      <Grid
        templateColumns="100px 25% 10% 15% 15% calc(35% - 200px)"
        gap="20px"
      >
        <FormControl id="id">
          <FormLabel>ID</FormLabel>
          <Input
            type="number"
            onChange={e => onChange({ id: e.target.value || 'NULL' })}
          />
          <FormHelperText>Cód. Interno</FormHelperText>
        </FormControl>
        <FormControl id="query">
          <FormLabel>Endereço</FormLabel>
          <DebounceInput
            element={Input}
            minLength={2}
            debounceTimeout={300}
            onChange={e => onChange({ query: e.target.value || 'NULL' })}
          />
          <FormHelperText>
            Pesquise por um bairro, rua, cidade...
          </FormHelperText>
        </FormControl>
        <FormControl id="transaction">
          <FormLabel>Transação</FormLabel>
          <Select
            size="md"
            onChange={e => onChange({ transaction: e.target.value })}
          >
            <option value="ALL">Todos</option>
            <option value="SALE">Venda</option>
            <option value="RENT">Aluguel</option>
          </Select>
        </FormControl>
        <FormControl id="category">
          <FormLabel>Categoria</FormLabel>
          <Select
            size="md"
            onChange={e => onChange({ category: e.target.value })}
          >
            <option value="ALL">Todas</option>
            {locations.map(category => (
              <option key={uniqid()} value={category[0]}>
                {category[1]}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl id="status">
          <FormLabel>Status</FormLabel>
          <Select
            size="md"
            onChange={e => onChange({ available: e.target.value })}
          >
            <option value="ALL">Todos</option>
            <option value="AVAILABLE">Disponível</option>
            <option value="UNAVAILABLE">Não disponível</option>
          </Select>
        </FormControl>
        <FormControl id="priceRange">
          <FormLabel>Ordernar por</FormLabel>
          <Select size="md" onChange={e => onChange({ order: e.target.value })}>
            <option value="id:desc">Mais recentes</option>
            <option value="price:asc">Menor preço</option>
            <option value="price:desc">Maior preço</option>
          </Select>
        </FormControl>
      </Grid>
    </Box>
  )
}

export default PropertyFilters
