import {
  Box,
  Center,
  Grid,
  Spinner,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'

import useClient from '../../../hooks/use-client'
import api from '../../../services/api'
import BanksCreateModal from './CreateModal'
import BanksItem from './Item'

function ClientBanks() {
  const { clientId, banks, updateBanks } = useClient()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()

  const [loading, setLoading] = useState(false)

  const getBanks = useCallback(async () => {
    setLoading(true)

    try {
      const { data } = await api.get(`/user/${clientId}/banks`)

      updateBanks(draft => {
        return Object.assign(draft, data)
      })
    } catch {
      toast({
        title: 'Oopss...',
        description: 'Não foi possível retornar os dados de clientes',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }

    setLoading(false)
  }, [toast, clientId, updateBanks])

  useEffect(() => {
    getBanks()
  }, [getBanks, clientId])

  return (
    <Box p="15px 0">
      <BanksCreateModal isOpen={isOpen} onClose={onClose} />
      <Grid templateColumns="repeat(3, 1fr)" gap={8}>
        {loading && (
          <Center>
            <Spinner size="sm" />
          </Center>
        )}

        {!loading &&
          banks.length > 0 &&
          banks.map(bank => <BanksItem key={bank.id} bank={bank} />)}
        <Box
          d="flex"
          flexDir="row"
          alignItems="center"
          justifyContent="center"
          bg="gray.100"
          p="5"
          borderRadius="8px"
          cursor="pointer"
          height="150px"
          onClick={onOpen}
          _hover={{ bg: 'gray.200' }}
        >
          <Text>Adicionar nova conta</Text>
        </Box>
      </Grid>
    </Box>
  )
}

export default ClientBanks
