import { Box, Grid, useDisclosure } from '@chakra-ui/react'
import React, { useState } from 'react'
import uniqid from 'uniqid'

import useClient from '../../hooks/use-client'
import PropertyModal from '../Property/Modal'
import PropertyPreview from '../Property/Preview'

function ClientViewProperties() {
  const { data } = useClient()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [selected, setSelected] = useState()

  return (
    <Box p="15px 0">
      <PropertyModal isOpen={isOpen} onClose={onClose} id={selected} />
      <Grid templateColumns="repeat(3, 1fr)" gap={8}>
        {data.properties &&
          data.properties.map(property => (
            <PropertyPreview
              key={uniqid()}
              data={property}
              withStatus
              relation
              onSelect={() => {
                setSelected(property.id)
                onOpen()
              }}
            />
          ))}
      </Grid>
    </Box>
  )
}

export default ClientViewProperties
