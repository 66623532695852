import { Table, Button, Card, Row, Col, Form, Input, message } from 'antd'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import ReactSummernote from 'react-summernote'
import 'react-summernote/dist/react-summernote.css' // import styles

import 'bootstrap/js/src/modal'
import 'bootstrap/js/src/dropdown'
import 'bootstrap/js/src/tooltip'
import 'bootstrap/dist/css/bootstrap.css'

import AppLayout from '../../layouts/AppLayout'
import api from '../../services/api'

function ContractTemplate() {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [body, setBody] = useState('')

  const [form] = Form.useForm()

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      responsive: ['md'],
      sorter: (a, b) => a.id - b.id
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Ações',
      render: (text, record) => {
        return (
          <>
            <Link to="/">Editar</Link>
            <Button
              style={{ marginLeft: 20 }}
              type="danger"
              onClick={() => destroy(record.id)}
            >
              Deletar
            </Button>
          </>
        )
      }
    }
  ]

  useEffect(() => {
    getData()
  }, [])

  async function destroy(id) {
    try {
      await api.delete(`/contract/template/${id}`)
      getData()
    } catch (err) {
      message.error('Não foi possível deletar este usuário')
    }
  }

  async function getData() {
    setLoading(true)

    try {
      const { data: templates } = await api.get('/contract/templates')

      setData(templates.map(template => ({ ...template, key: template.id })))
    } catch (err) {
      message.error('Ocorreu um erro ao buscar os dados')
    }

    setLoading(false)
  }

  async function create(values) {
    message.loading('Cadastrando...')

    values = { ...values, body }

    try {
      await api.post('/contract/template', values)

      message.success('Modelo cadastrado!')
      getData()
    } catch (err) {
      message.error(
        'Ocorreu um erro ao tentar criar um novo modelo de contrato'
      )
    }
  }

  return (
    <AppLayout title="Modelos de contrato" keyPage="20" subKeyPage="sub3">
      <Row gutter={25}>
        <Col md={14}>
          <Card title="Cadastre um modelo novo">
            <Form
              name="create-template"
              form={form}
              onFinish={create}
              autoComplete="off"
            >
              <Form.Item
                name="name"
                rules={[
                  { required: true, message: 'Informe o nome do contrato!' }
                ]}
              >
                <Input placeholder="Nome" />
              </Form.Item>
              <Form.Item>
                <ReactSummernote
                  value={body}
                  options={{
                    height: 350,
                    dialogsInBody: true,
                    toolbar: [
                      ['style', ['style']],
                      ['font', ['bold', 'underline', 'clear']],
                      ['fontname', ['fontname']],
                      ['para', ['ul', 'ol', 'paragraph']],
                      ['table', ['table']],
                      ['insert', ['link']],
                      ['view', ['fullscreen', 'codeview']]
                    ]
                  }}
                  onChange={setBody}
                />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Cadastrar
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col md={10}>
          <Card title="Modelos cadastrados">
            <Table
              columns={columns}
              dataSource={data}
              locale={{ emptyText: 'Não há modelos cadastrados' }}
              loading={loading}
            />
          </Card>
        </Col>
      </Row>
    </AppLayout>
  )
}

export default ContractTemplate
