import { Table, Tabs, Card, Tag, Button, message } from 'antd'
import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'

import 'react-image-lightbox/style.css'

import AppLayout from '../../layouts/AppLayout'
import api from '../../services/api'

const { TabPane } = Tabs

const status = [
  {
    name: 'OPENED',
    color: 'purple',
    translate: 'Aberto'
  },
  {
    name: 'REPLIED',
    color: 'green',
    translate: 'Respondido'
  },
  {
    name: 'AWAITING',
    color: 'geekblue',
    translate: 'Aguardando resposta'
  },
  {
    name: 'CLOSED',
    color: 'red',
    translate: 'Fechado'
  }
]

const levels = [
  {
    name: 'LOW',
    color: 'purple',
    translate: 'Baixa'
  },
  {
    name: 'MEDIUM',
    color: 'green',
    translate: 'Média'
  },
  {
    name: 'HIGH',
    color: 'geekblue',
    translate: 'Alta'
  }
]

function Support() {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)

  const history = useHistory()

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      responsive: ['md'],
      sorter: (a, b) => a.id - b.id,
      render: text => text
    },
    {
      title: 'Imóvel',
      dataIndex: 'property_id',
      key: 'property_id',
      render: text => <Link to={`/properties/info/${text}`}>{text}</Link>
    },
    {
      title: 'Usuário',
      render: (text, record) => (
        <Link to={`/clients/info/${record.user.id}`}>
          {record.user.name.split(' ')[0] +
            ' ' +
            record.user.name.split(' ')[1] || ''}
        </Link>
      )
    },
    {
      title: 'Título',
      dataIndex: 'title',
      key: 'title',
      render: text => new Date(text).toLocaleDateString('pt-BR')
    },
    {
      title: 'Prioridade',
      dataIndex: 'level',
      key: 'level',
      responsive: ['md'],
      render: text => (
        <Tag color={levels.find(el => el.name === text).color}>
          {levels.find(el => el.name === text).translate}
        </Tag>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: text => (
        <Tag color={status.find(el => el.name === text).color}>
          {status.find(el => el.name === text).translate}
        </Tag>
      )
    },
    {
      title: 'Última atualização',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: text => new Date(text).toLocaleString('pt-BR')
    },
    {
      title: 'Ações',
      render: (text, record) => {
        return (
          <>
            <Button
              type="primary"
              className="mr-2"
              onClick={() => history.push(`/support/${record.id}`)}
            >
              Abrir
            </Button>
            <Button type="danger" onClick={() => destroy(record.id)}>
              Deletar
            </Button>
          </>
        )
      }
    }
  ]

  useEffect(() => {
    getData()
  }, [])

  async function getData() {
    setLoading(true)

    try {
      const { data: schedules } = await api.get('/chat')

      setData(schedules)
    } catch (err) {
      message.error('Ocorreu um erro ao buscar os dados')
    }

    setLoading(false)
  }

  async function destroy(id) {
    setLoading(true)

    try {
      await api.delete(`/chat/${id}`)

      message.success('Ticket deletado!')
      getData()
    } catch (err) {
      message.error('Não foi possível deletar o ticket')
    }

    setLoading(false)
  }

  return (
    <AppLayout title="Suporte" keyPage="14">
      <Card title="Gerencie os suportes">
        <Tabs defaultActiveKey="1">
          <TabPane tab="Em andamento" key="1">
            <Table
              columns={columns}
              dataSource={
                data && data.opened.map(el => ({ ...el, key: el.id }))
              }
              locale={{ emptyText: 'Não há tickets em andamento' }}
              loading={loading}
            />
          </TabPane>
          <TabPane tab="Histórico" key="2">
            <Table
              columns={columns}
              dataSource={
                data && data.history.map(el => ({ ...el, key: el.id }))
              }
              locale={{ emptyText: 'Não há histórico de tickets' }}
              loading={loading}
            />
          </TabPane>
        </Tabs>
      </Card>
    </AppLayout>
  )
}

export default Support
