import React, { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Textarea,
  Box,
  Grid,
  Select,
  Input,
  Text,
  useToast
} from '@chakra-ui/react'
import { useImmer } from 'use-immer'

import api from '../../services/api'

function MovimentationsModal({
  id,
  isOpen,
  onClose,
  transaction,
  onUpdate,
  defaultData
}) {
  const toast = useToast()

  const [loading, setLoading] = useState(false)

  const [form, updateForm] = useImmer(
    defaultData || {
      description: '',
      type: 'INPUT',
      price: ''
    }
  )

  const handleInputChange = e => {
    const { name, value } = e.target

    updateForm(draft => {
      draft[name] = value
    })
  }

  const handleSave = async () => {
    if (!form.description && !form.price)
      return toast({
        title: 'Opss...',
        description: 'Informe a descrição e preço'
      })

    setLoading(true)

    try {
      if (id) {
        await api.patch(`/movimentation/${id}`, form)
      } else {
        await api.post('/movimentation', {
          ...form,
          subtype: transaction === 'sales' ? 'SALE' : 'OTHER'
        })
      }

      updateForm(draft => {
        draft.description = ''
        draft.price = ''
      })

      onClose()
      onUpdate()
    } catch {
      toast({
        title: 'Oopss...',
        description: 'Não foi possível salvar a movimentação',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }

    setLoading(false)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {id ? 'Editar movimentação' : 'Nova movimentação'} de{' '}
          {transaction === 'sales' ? 'venda' : 'despesa'}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>Descrição</Text>
          <Textarea
            rows={2}
            placeholder="Descreva a movimentação"
            name="description"
            value={form.description}
            onChange={handleInputChange}
            mb={2}
          />
          <Grid templateColumns="2fr 1fr" gap={2}>
            <Box>
              <Text>Movimentação</Text>
              <Select
                name="type"
                value={form.type}
                onChange={handleInputChange}
              >
                <option value="INPUT">Entrada</option>
                <option value="OUTPUT">Saída</option>
              </Select>
            </Box>
            <Box>
              <Text>Valor</Text>
              <Input
                name="price"
                value={form.price}
                onChange={handleInputChange}
                type="number"
              />
            </Box>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancelar
          </Button>
          <Button
            colorScheme="orange"
            onClick={handleSave}
            isLoading={loading}
            isDisabled={loading}
          >
            {id ? 'Salvar edições' : 'Salvar'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default MovimentationsModal
