import { Card, Row, Col, Form, Input, Button, message as msg } from 'antd'
import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'

import 'react-image-lightbox/style.css'

import AppLayout from '../../layouts/AppLayout'
import api from '../../services/api'

const status = [
  {
    name: 'OPENED',
    color: 'purple',
    translate: 'Aberto'
  },
  {
    name: 'REPLIED',
    color: 'green',
    translate: 'Respondido'
  },
  {
    name: 'AWAITING',
    color: 'geekblue',
    translate: 'Aguardando resposta'
  },
  {
    name: 'CLOSED',
    color: 'red',
    translate: 'Fechado'
  }
]

const levels = [
  {
    name: 'LOW',
    color: 'purple',
    translate: 'Baixa'
  },
  {
    name: 'MEDIUM',
    color: 'green',
    translate: 'Média'
  },
  {
    name: 'HIGH',
    color: 'geekblue',
    translate: 'Alta'
  }
]

function SupportInfo() {
  const { id } = useParams()

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)
  const [message, setMessage] = useState('')

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [])

  async function getData() {
    setLoading(true)

    try {
      const { data } = await api.get(`/chat/${id}/admin`)

      setData(data)
      scrollToBottom()
    } catch (err) {
      msg.error('Ocorreu um erro ao buscar os dados')
    }

    setLoading(false)
  }

  function scrollToBottom() {
    var div = document.querySelector('.chat')

    if (div) {
      div.scrollTop = div.scrollHeight
    }
  }

  async function close() {
    setLoading(true)

    try {
      await api.patch(`/chat/${id}`, { status: 'CLOSED' })

      await getData()
    } catch (err) {
      msg.error(err.response.data.message)
    }

    setLoading(false)
  }

  async function submit() {
    if (!message) {
      return msg.info('Informe uma mensagem')
    }

    setLoading(true)

    try {
      await api.put(`/chat/${id}`, { message })

      setMessage('')
      await getData()
    } catch (err) {
      message.error('Não foi possível responder o ticket')
    }

    setLoading(false)
  }

  return (
    <AppLayout title="Suporte" keyPage="14">
      <Row gutter={16}>
        <Col md={8}>
          <Card
            title="Detalhes do ticket"
            className="mt-4"
            bordered={false}
            loading={loading}
          >
            <p>
              <b>Usuário :</b>{' '}
              <Link to={`/clients/${data && data.user.id}`} target="_blank">
                {data && data.user.name}
              </Link>
            </p>
            <p>
              <b>Imóvel :</b>{' '}
              <Link
                to={`/properties/info/${data && data.property_id}`}
                target="_blank"
              >
                {data && data.property_id}
              </Link>
            </p>
            <p>
              <b>Status:</b>{' '}
              {data && status.find(el => el.name === data.status).translate}
            </p>
            <p>
              <b>Prioridade:</b>{' '}
              {data && levels.find(el => el.name === data.level).translate}
            </p>
            <p>
              <b>Última atualização:</b>{' '}
              {new Date(data ? data.updatedAt : '').toLocaleString('pt-BR')}
            </p>
            <p>
              <b>Criado em:</b>{' '}
              {new Date(data ? data.createdAt : '').toLocaleString('pt-BR')}
            </p>
            {data && data.status !== 'CLOSED' && (
              <Button className="w-100" type="danger" onClick={close}>
                Encerrar ticket
              </Button>
            )}
          </Card>
        </Col>
        <Col md={16}>
          <Card
            title={`Chat - ${data && data.title}`}
            className="mt-4"
            bordered={false}
            loading={loading}
          >
            <div className="chat">
              {data &&
                data.messages.map(el => (
                  <div
                    key={el.id}
                    className={`chat-message ${
                      el.author !== 'STAFF' && 'chat-me'
                    }`}
                  >
                    <div className="chat-message-body">{el.data}</div>
                    <div className="chat-message-time">
                      {new Date(el.created_at).toLocaleString('pt-br')}
                    </div>
                  </div>
                ))}
            </div>
            <div>
              <Form.Item>
                <Input.TextArea
                  rows={4}
                  onChange={e => setMessage(e.target.value)}
                  value={message}
                />
              </Form.Item>
              <Form.Item>
                <Button loading={loading} onClick={submit} type="primary">
                  Responder
                </Button>
              </Form.Item>
            </div>
          </Card>
        </Col>
      </Row>
    </AppLayout>
  )
}

export default SupportInfo
