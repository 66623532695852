import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  IconButton,
  Badge,
  useDisclosure
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import 'dayjs/locale/pt-br'
import { BsThreeDotsVertical } from 'react-icons/bs'
import uniqid from 'uniqid'

import TransferModal from '../../components/Transfer/Modal'

dayjs.locale('pt-br')

function ClientProposalTransactions({ isOpen, onClose, selected }) {
  const {
    isOpen: isTransferOpen,
    onOpen: onTransferOpen,
    onClose: onTransferClose
  } = useDisclosure()
  const [transferSelected, setTransferSelected] = useState()

  if (!selected) {
    return <div />
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="5xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Contas do contrato</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb="6">
          <Tabs variant="soft-rounded" colorScheme="orange" isFitted>
            <TabList>
              <Tab>Faturas</Tab>
              <Tab>Transferências</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Table variant="simple">
                  <Thead>
                    <Th>M. Referência</Th>
                    <Th>Valor</Th>
                    <Th>Vencimento</Th>
                    <Th>Pago em</Th>
                    <Th>Situação</Th>
                    <Th isNumeric>Recibo do inquilino</Th>
                  </Thead>
                  <Tbody>
                    {selected &&
                      selected.invoices.map(invoice => (
                        <Tr key={uniqid()}>
                          <Td>
                            {dayjs(`${invoice.reference}-01`)
                              .subtract(1, 'M')
                              .format('MMM [de] YYYY')}
                          </Td>
                          <Td>
                            R$
                            {parseFloat(
                              invoice.paid_amount |
                                (invoice.price + invoice.fees)
                            ).toLocaleString('pt-BR', {
                              minimumFractionDigits: 2
                            })}
                          </Td>
                          <Td>
                            {dayjs(invoice.due_date).format('DD/MM/YYYY')}
                          </Td>
                          <Td>
                            {invoice.status === 'PAID'
                              ? dayjs(invoice.pay_day).format('DD/MM/YYYY')
                              : 'Não efetuado.'}
                          </Td>
                          <Td>
                            <Badge
                              colorScheme={
                                invoice.status === 'PAID' ? 'green' : 'orange'
                              }
                            >
                              {invoice.status === 'PAID' ? 'Pago' : 'Pendente'}
                            </Badge>
                          </Td>
                          <Td isNumeric>
                            <Button
                              size="sm"
                              colorScheme="whatsapp"
                              // isDisabled={invoice.status === "PENDING"}
                              isDisabled={true}
                              onClick={() => {
                                window.open(
                                  `${
                                    process.env.REACT_APP_ENDPOINT_API ||
                                    'https://core-api.advqxs.easypanel.host'
                                  }/financial/${invoice.id}/receipt/client`,
                                  '_blank'
                                )
                              }}
                            >
                              Recibo indisponível
                            </Button>
                          </Td>
                        </Tr>
                      ))}
                  </Tbody>
                </Table>
              </TabPanel>
              <TabPanel>
                <TransferModal
                  id={transferSelected}
                  isOpen={isTransferOpen}
                  onClose={onTransferClose}
                  onChange={() => {}}
                />
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>M. de Referência</Th>
                      <Th>Valor transferido</Th>
                      <Th>Transferido em</Th>
                      <Th>Situação</Th>
                      <Th isNumeric>Mais</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {selected &&
                      selected.transfers.map(transfer => (
                        <Tr key={uniqid()}>
                          <Td>
                            {dayjs(`${transfer.invoice.reference}-01`)
                              .subtract(1, 'M')
                              .format('MMM [de] YYYY')}
                          </Td>
                          <Td>
                            R$
                            {parseFloat(transfer.amount).toLocaleString(
                              'pt-BR',
                              {
                                minimumFractionDigits: 2
                              }
                            )}
                          </Td>

                          <Td>
                            {transfer.status === 'DONE'
                              ? dayjs(transfer.updated_at).format('DD/MM/YYYY')
                              : 'Não efetuado.'}
                          </Td>
                          <Td>
                            <Badge
                              colorScheme={
                                transfer.status === 'DONE' ? 'green' : 'orange'
                              }
                            >
                              {transfer.status === 'DONE'
                                ? 'Efetuado'
                                : 'Pendente'}
                            </Badge>
                          </Td>
                          <Td isNumeric>
                            <Button
                              size="sm"
                              colorScheme="whatsapp"
                              isDisabled={transfer.status === 'PENDING'}
                              onClick={() => {
                                window.open(
                                  `${process.env.REACT_APP_ENDPOINT_API}/financial/${transfer.id}/receipt`,
                                  '_blank'
                                )
                              }}
                            >
                              Recibo
                            </Button>
                            <IconButton
                              icon={<BsThreeDotsVertical />}
                              size="sm"
                              ml={3}
                              variant="ghost"
                              onClick={() => {
                                onTransferOpen()
                                setTransferSelected(transfer.id)
                              }}
                            />
                          </Td>
                        </Tr>
                      ))}
                  </Tbody>
                </Table>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ClientProposalTransactions
