import {
  Grid,
  Flex,
  CircularProgress,
  Progress,
  IconButton
} from '@chakra-ui/react'
import React from 'react'
import { FiTrash } from 'react-icons/fi'
import { MdCheckCircle, MdError, MdLink } from 'react-icons/md'
import { ReactSortable } from 'react-sortablejs'
import styled from 'styled-components'
import uniqid from 'uniqid'

import useProperty from '../../hooks/use-property'

const FileInfo = styled.div`
  display: flex;
  align-items: center;
  div {
    display: flex;
    flex-direction: column;
    span {
      font-size: 12px;
      color: #999;
      margin-top: 5px;
      button {
        border: 0;
        background: transparent;
        color: #e57878;
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }
`

const Preview = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 5px;
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  margin-right: 10px;
`

function PropertyImages() {
  const { data, uploading, updateData, onImageMoves, onImageDelete } =
    useProperty()

  function handleSortableImages(data) {
    updateData(draft => {
      draft.images = data
    })
  }

  return (
    <>
      {uploading && <Progress size="xs" isIndeterminate my={5} />}
      <Grid
        as={ReactSortable}
        mt={5}
        templateColumns={['100%', '100%', 'repeat(2, 1fr)']}
        gap="15px"
        list={data.images}
        setList={handleSortableImages}
        onEnd={onImageMoves}
      >
        {data.images.map(uploadedFile => (
          <Flex
            key={uploadedFile.id || uniqid()}
            bg="gray.50"
            p="12px"
            borderRadius="lg"
            justify="space-between"
            alignItems="center"
          >
            <FileInfo>
              <Preview src={uploadedFile.preview || uploadedFile.url} />
              <div>
                <strong>{uploadedFile.name || uploadedFile.source}</strong>
                <span>{uploadedFile.readableSize} </span>
              </div>
            </FileInfo>

            <Flex as="div" alignItems="center" justifyContent="flex-start">
              {uploadedFile.uploading &&
                !uploadedFile.uploaded &&
                !uploadedFile.error && (
                  <CircularProgress
                    size="40px"
                    value={uploadedFile.progress}
                    color="green.400"
                  />
                )}

              {uploadedFile.url && (
                <a
                  href={uploadedFile.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <MdLink style={{ marginRight: 8 }} size={24} color="#222" />
                </a>
              )}

              {uploadedFile.uploaded && (
                <MdCheckCircle size={24} color="#78e5d5" />
              )}
              {uploadedFile.error && <MdError size={24} color="#e57878" />}
              {!!uploadedFile.url && (
                <IconButton
                  size="md"
                  icon={<FiTrash />}
                  bg="white"
                  color="red.400"
                  isRound
                  onClick={() => onImageDelete(uploadedFile.id)}
                />
              )}
            </Flex>
          </Flex>
        ))}
      </Grid>
    </>
  )
}

export default PropertyImages
