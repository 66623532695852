import {
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton
} from '@chakra-ui/react'
import React from 'react'
// import { useHistory } from "react-router-dom";

import useProperty from '../../hooks/use-property'
import PropertyHeader from './Header'
import PropertyTabs from './Tabs'

function PropertyModalContent({ onClose }) {
  // const router = useHistory();
  const {
    id,
    data,
    updating,
    updateValues,
    updatingLoading,
    clone,
    cloning,
    onDelete
  } = useProperty()

  return (
    <ModalContent>
      <ModalHeader>
        ID: {id}{' '}
        {data.platform === 'IMOVELINTEGRADO' &&
          `- ${data.external_id} (IMOVEL INTEGRADO)`}{' '}
        - Cadastro do imóvel
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <PropertyHeader />
        <PropertyTabs />
      </ModalBody>
      <ModalFooter>
        <Button variant="ghost" mr={3} onClick={onClose}>
          Fechar
        </Button>
        <Popover>
          <PopoverTrigger>
            <Button variant="ghost" mr={3}>
              Clonar imóvel
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader>Confirmação</PopoverHeader>
            <PopoverBody>Tem certeza que deseja clonar o imóvel?</PopoverBody>
            <PopoverFooter>
              <Button
                size="sm"
                colorScheme="whatsapp"
                onClick={clone}
                isLoading={cloning}
                isDisabled={cloning}
              >
                Prosseguir
              </Button>
            </PopoverFooter>
          </PopoverContent>
        </Popover>
        <Button
          bg="red.100"
          color="red.600"
          _hover={{ bg: 'red.200' }}
          onClick={onDelete}
        >
          Deletar imóvel
        </Button>
        {updating && (
          <Button
            bg="blue.100"
            color="blue.600"
            ml={3}
            transition="all ease .3s"
            onClick={updateValues}
            isLoading={updatingLoading}
            _hover={{ bg: 'blue.200' }}
          >
            Salvar alterações
          </Button>
        )}
      </ModalFooter>
    </ModalContent>
  )
}

export default PropertyModalContent
