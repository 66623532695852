import { extendTheme, theme } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'
import { createGlobalStyle } from 'styled-components'

const themeOverride = {
  styles: {
    global: props => ({
      body: {
        color: mode('gray.800', 'whiteAlpha.900')(props),
        bg: mode('white', 'gray.800')(props),
        lineHeight: 'base',
        WebkitTextSizeAdjust: '100%',
        WebkitTapHighlightColor: 'transparent'
      },
      '.sortable-ghost': {
        bg: 'orange.100'
      },
      '.sortable-chosen': {
        bg: 'orange.100'
      },
      '.sortable-drag': {
        bg: 'blue.200',
        transition: 'transfrom 0.6s linear'
      },
      '#nprogress .bar': {
        background: '#ED8936'
      },
      '.mb-select__control--is-focused': {
        borderColor: 'var(--chakra-colors-orange-400)!important',
        boxShadow: '0 0 0 1px var(--chakra-colors-orange-400)!important'
      },
      '.mb-calendar': {
        border: 'none',
        '.react-calendar__tile--hasActive': {
          bg: 'orange.400',
          color: 'white'
        },
        '.react-calendar__tile': {
          transition: 'all linear .2s',

          '.react-calendar__tile--active': {
            bg: 'orange.400',
            color: 'white'
          },

          '.react-calendar__tile--rangeStart': {
            bg: 'orange.400',
            color: 'white'
          },

          '.react-calendar__tile--rangeEnd': {
            bg: 'orange.400',
            color: 'white'
          },

          _hover: {
            bg: 'orange.400',
            color: 'white'
          },
          _disabled: {
            _hover: {
              bg: 'orange.100',
              color: 'black',
              cursor: 'not-allowed'
            }
          }
        },
        '.react-calendar__tile--active': {
          bg: 'orange.300',
          color: 'black'
        }
      }
    })
  },
  theme: {
    ...theme,
    shadows: {
      ...theme.shadows,
      outline: 'none'
    }
  },
  components: {
    Input: {
      defaultProps: {
        size: 'md',
        variant: 'outline',
        focusBorderColor: 'orange.400'
      }
    },
    Select: {
      defaultProps: {
        size: 'md',
        variant: 'outline',
        focusBorderColor: 'orange.400'
      }
    },
    Textarea: {
      defaultProps: {
        size: 'md',
        variant: 'outline',
        focusBorderColor: 'orange.400'
      }
    },
    PinInput: {
      defaultProps: {
        size: 'md',
        variant: 'outline',
        focusBorderColor: 'orange.400'
      }
    },
    Button: {
      defaultProps: {
        size: 'md'
      }
    }
  }
}

export const GlobalStyles = createGlobalStyle`
  *:focus, *:active {
    outline: none
  }

  .orange-background-class {
    background-color: #FEFCBF;
  }

  .wysiwyg-editor {
    border: solid 1px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 0 10px;
  }

  .wysiwyg-toolbar {
    border-radius: 8px;
  }

  *:focus {
    box-shadow: none !important;
  }

  .react-daterange-picker__wrapper {
    border: 1px solid rgba(0, 0, 0, 0.1);
    height: 2.5rem;
    border-radius: 0.375rem;
    padding: 0 10px;
    transition: all ease 0.3s;
  }

  table > tbody > tr {
    transition: all ease 0.3s;
    cursor: pointer;
  }
  table > tbody > tr:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  .pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    list-style: none;
    margin: 0;
    padding: 10px 0;
    background: #fff;
    width: 100%;
    margin-bottom: 35px;

    li {
      margin: 0 5px;

      a {
        padding: 5px 10px;
        border-radius: 5px;
        background: rgba(0, 0, 0, 0.1);
        color: #000;
        transition: all ease 0.4s;

        &:hover {
          background: #ed8936;
          color: white;
        }
      }

      &.active {
        a {
          background: #ed8936;
          color: white;
        }
      }
    }

    .previous {
      margin-right: auto;

      a {
        padding: 5px 10px;
        border-radius: 5px;
        background: #ed8936;
        color: white;
      }

      &.disabled a {
        background: #fbd38d;
        color: #ed8936;
        cursor: not-allowed;
      } 
    }

    .next {
      margin-left: auto;

      a {
        padding: 5px 10px;
        border-radius: 5px;
        background: #ed8936;
        color: white;
      }

      &.disabled a {
        background: rgba(0, 0, 0, 0.1);
        color: #ed8936;
        cursor: not-allowed;
      }
    }
  }

  .CodeMirror {
    height: calc(100vh - 109px);
  }

  .react-contexify {
    background: #2D3748;
    box-shadow: none;
  }

  .react-contexify__item {
    font-size: 12px;
    padding: 4px 0;
  }

  .react-contexify__item:not(.react-contexify__item--disabled):hover > .react-contexify__item__content,
  .react-contexify__item:not(.react-contexify__item--disabled):focus > .react-contexify__item__content {
    background: #1A202C;
    color: #fff;
  }

  .react-contexify__item__content {
    color: #fff;
    transition: all ease .3s;
  }

`

export default extendTheme(themeOverride)
