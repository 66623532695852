import { Line } from '@ant-design/charts'
import {
  ArrowUpOutlined,
  PercentageOutlined,
  BankOutlined
} from '@ant-design/icons'
import { Typography, Table, Tag, Row, Col, Card, Statistic } from 'antd'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import AppLayout from '../../layouts/AppLayout'
import api from '../../services/api'

const { Title } = Typography

function Financial() {
  const [data, setData] = useState({ graph: [] })
  const [loading, setLoading] = useState(true)

  const config = {
    data: data.graph,
    title: {
      visible: false
    },
    xField: 'date',
    yField: 'value',
    seriesField: 'type',
    responsive: true
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Inquilino',
      dataIndex: 'name',
      key: 'name',
      render: text => <Link to="/">{text}</Link>
    },
    {
      title: 'Contrato',
      dataIndex: 'contract',
      key: 'contract',
      render: text => <Link to="/">{text}</Link>
    },
    {
      title: 'Forma de Pagamento',
      dataIndex: 'paymentMethod',
      key: 'paymentMethod'
    },
    {
      title: 'Valor',
      dataIndex: 'price',
      key: 'price',
      render: text =>
        'R$' +
        parseFloat(text).toLocaleString('pt-BR', { minimumFractionDigits: 2 })
    },
    {
      title: 'Data da Transação',
      dataIndex: 'created_at',
      key: 'created_at'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: () => <Tag color="green">Pago</Tag>
    }
  ]

  // const dataTable = [
  //   {
  //     key: '1',
  //     id: '1',
  //     name: 'João Pedro Machado Nascimento de Paulo',
  //     contract: '16642',
  //     paymentMethod: 'Cartão de Crédito',
  //     price: 750,
  //     created_at: new Date().toLocaleString('pt-BR')
  //   }
  // ]

  React.useEffect(() => {
    getData()
  }, [])

  async function getData() {
    setLoading(true)

    try {
      const { data: resultData } = await api.get('/financial/resume')

      setData(resultData)
    } catch (err) {
      alert('Ocorreu um erro ao tentar recuperar dados')
    }

    setLoading(false)
  }

  return (
    <AppLayout title="Resumo Financeiro" keyPage="2" subKeyPage="sub1">
      <Row gutter={25}>
        <Col md={8}>
          <Card loading={loading}>
            <Statistic
              title="Renda Bruta"
              value={data.grossIncome || 0}
              precision={2}
              valueStyle={{ color: '#00a8ff' }}
              prefix={<BankOutlined />}
            />
          </Card>
        </Col>
        <Col md={8}>
          <Card loading={loading}>
            <Statistic
              title="Renda Liquida"
              value={data.netIncome || 0}
              precision={2}
              valueStyle={{ color: '#3f8600' }}
              prefix={<ArrowUpOutlined />}
            />
          </Card>
        </Col>
        <Col md={8}>
          <Card loading={loading}>
            <Statistic
              title="Taxas"
              value={data.feesIncome || 0}
              precision={2}
              valueStyle={{ color: '#e84118' }}
              prefix={<PercentageOutlined />}
            />
          </Card>
        </Col>
      </Row>
      <br />
      <Card>
        <Title>Geral</Title>
        <Line {...config} />
      </Card>
      <br />
      <Card title="Últimas Transações">
        <Table columns={columns} dataSource={[]} />
      </Card>
    </AppLayout>
  )
}

export default Financial
