import {
  DashboardOutlined,
  UserOutlined,
  BankOutlined,
  HomeOutlined,
  AuditOutlined,
  ScheduleOutlined,
  MessageOutlined,
  BoldOutlined,
  MailOutlined,
  SettingOutlined
} from '@ant-design/icons'
import { Center, Badge } from '@chakra-ui/react'
import { Layout, Menu } from 'antd'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation, Link, useHistory } from 'react-router-dom'

import { Styles } from '../../components'
import { clear, getData } from '../../guard/auth'
import permissions from '../../helpers/variables/permissions'
import api from '../../services/api'

const { Header, Content, Footer, Sider } = Layout
const { SubMenu } = Menu

function AppLayout({ title, children, keyPage, subKeyPage }) {
  const history = useHistory()
  const location = useLocation()

  const { name } = getData()

  const [badges, setBadges] = React.useState({
    chats: 0,
    contacts: 0
  })

  React.useEffect(() => {
    ;(async () => {
      try {
        const { data } = await api.get('/dashboard/badges')

        setBadges(data)
      } catch {}
    })()
  }, [location.pathname])

  return (
    <React.Fragment>
      <Helmet>
        <title>{title} - MB Imibiliária</title>
      </Helmet>
      <Styles />
      <Layout>
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          onBreakpoint={broken => {
            console.log(broken)
          }}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type)
          }}
          style={{
            minHeight: '100vh'
          }}
        >
          <div className="logo">
            <Center>
              <img
                src={require('../../assets/images/logo-white.png')}
                alt="MB Imobiliária - Logo"
              />
            </Center>
          </div>
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={[keyPage]}
            defaultOpenKeys={[subKeyPage]}
          >
            <Menu.Item key="1" icon={<DashboardOutlined />}>
              <Link to="/" />
              Dashboard
            </Menu.Item>
            {permissions.has('FINANCIAL_VIEW') && (
              <SubMenu key="sub1" icon={<BankOutlined />} title="Financeiro">
                <Menu.Item key="23">
                  <Link to="/financial/movimentations" />
                  Caixa
                </Menu.Item>
                <Menu.Item key="25">
                  <Link to="/financial/invoices" />
                  Faturas
                </Menu.Item>
                <Menu.Item key="3">
                  <Link to="/financial/transfers" />
                  Repasses
                </Menu.Item>
                {/* <Menu.Item key="4">
                  <Link to="/financial/shall" />
                  Inadimplências
                </Menu.Item>
                <Menu.Item key="5">
                  <Link to="/financial/reports" />
                  Relatórios
                </Menu.Item>
                <Menu.Item key="6">
                  <Link to="/financial/transactions" />
                  Transações
                </Menu.Item>
                <Menu.Item key="24">
                  <Link to="/financial/boletos" />
                  Boletos
                </Menu.Item> */}
                <Menu.Item key="26">
                  <Link to="/financial/comissions" />
                  Comissões
                </Menu.Item>
              </SubMenu>
            )}
            {(permissions.has('CLIENT_VIEW') ||
              permissions.has('USER_VIEW')) && (
              <Menu.Item key="7" icon={<UserOutlined />}>
                <Link to="/clients" />
                Clientes
              </Menu.Item>
            )}
            {permissions.has('PROPERTY_VIEW') && (
              <Menu.Item key="sub2" icon={<HomeOutlined />}>
                <Link to="/properties" />
                Imóveis
              </Menu.Item>
            )}
            {permissions.has('PROPOSAL_VIEW') && (
              <Menu.Item key="12" icon={<AuditOutlined />}>
                <Link to="/contracts" />
                Contratos
              </Menu.Item>
            )}
            {permissions.has('SCHEDULE_VIEW') && (
              <Menu.Item key="13" icon={<ScheduleOutlined />}>
                <Link to="/schedules" />
                Agendamentos
                <Badge
                  h="16px"
                  ml="10px"
                  lineHeight="16px"
                  bg="orange.400"
                  color="black"
                >
                  {badges.schedules}
                </Badge>
              </Menu.Item>
            )}
            {permissions.has('SUPPORT_VIEW') && (
              <Menu.Item key="14" icon={<MessageOutlined />}>
                <Link to="/support" />
                Suporte
                <Badge
                  h="16px"
                  ml="10px"
                  lineHeight="16px"
                  bg="orange.400"
                  color="black"
                >
                  {badges.chats}
                </Badge>
              </Menu.Item>
            )}
            {permissions.has('FEED_VIEW') && (
              <Menu.Item key="15" icon={<BoldOutlined />}>
                <Link to="/blog" />
                Blog
              </Menu.Item>
            )}
            {permissions.has('CONTACT_VIEW') && (
              <Menu.Item key="16" icon={<MailOutlined />}>
                <Link to="/contacts" />
                Contatos
                <Badge
                  h="16px"
                  ml="10px"
                  lineHeight="16px"
                  bg="orange.400"
                  color="black"
                >
                  {badges.contacts}
                </Badge>
              </Menu.Item>
            )}
            <SubMenu
              key="sub3"
              icon={<SettingOutlined />}
              title="Configurações"
            >
              {permissions.has('ADMIN_USER_VIEW') && (
                <Menu.Item key="17">
                  <Link to="/settings/users" />
                  Usuários
                </Menu.Item>
              )}
              {permissions.has('GROUP_VIEW') && (
                <Menu.Item key="18">
                  <Link to="/settings/permissions" />
                  Permissões
                </Menu.Item>
              )}
              {permissions.has('SYSTEM_NOTIFICATION_VIEW') && (
                <Menu.Item key="19">
                  <Link to="/settings/notifications" />
                  Notificações personalizadas
                </Menu.Item>
              )}
              {permissions.has('PROPOSAL_VIEW') && (
                <Menu.Item key="20">
                  <Link to="/settings/contracts" />
                  Modelos de contrato
                </Menu.Item>
              )}
              <Menu.Item key="21">
                <Link to="/settings/password-change" />
                Trocar senha
              </Menu.Item>
            </SubMenu>
          </Menu>
        </Sider>
        <Layout>
          <Header
            className="site-layout-sub-header-background"
            style={{ padding: 0 }}
          >
            <ul>
              <li>
                <Link to="/">Bem-vindo(a), {name.split(' ')[0]}</Link>
              </li>
              <li>
                <a
                  href="!#"
                  onClick={() => {
                    clear()
                    history.push('/login')
                  }}
                >
                  Sair da conta
                </a>
              </li>
            </ul>
          </Header>
          <Content style={{ margin: '24px 16px 0' }}>{children}</Content>
          <Footer style={{ textAlign: 'center' }}>
            MB Imobiliária &copy; Desenvolvido por Kodesky
          </Footer>
        </Layout>
      </Layout>
    </React.Fragment>
  )
}

export default AppLayout
