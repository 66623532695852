import { Box, Flex, Heading, Spinner, Text } from '@chakra-ui/react'
import React from 'react'

function TellerCard({ title, value, icon, iconBg, iconColor, isLoading }) {
  return (
    <Flex
      p={4}
      borderRadius="lg"
      alignItems="center"
      justifyContent="flex-start"
      bg="white"
      border="solid 1px"
      borderColor="gray.200"
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        mr={4}
        w="32px"
        h="32px"
        borderRadius="32px"
        bg={iconBg}
        color={iconColor}
      >
        {icon}
      </Flex>
      <Box>
        <Text>{title}</Text>
        {isLoading ? <Spinner /> : <Heading size="md">{value}</Heading>}
      </Box>
    </Flex>
  )
}

export default TellerCard
