import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Center,
  Spinner
} from '@chakra-ui/react'
import React from 'react'

import useProperty from '../../hooks/use-property'
import Address from './Address'
import Features from './Features'
import Fees from './Fees'
import Form from './Form'
import Images from './Images'
import Informations from './Informations'
import Upload from './Upload'

function PropertyTabs() {
  const { loading } = useProperty()

  return loading ? (
    <Center>
      <Spinner size="lg" mt="40px" />
    </Center>
  ) : (
    <Tabs mt={7} isFitted>
      <TabList>
        <Tab>Dados gerais</Tab>
        <Tab>Endereço</Tab>
        <Tab>Informações</Tab>
        <Tab>Características</Tab>
        <Tab>Taxas</Tab>
        <Tab>Imagens</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <Form />
        </TabPanel>
        <TabPanel>
          <Address />
        </TabPanel>
        <TabPanel>
          <Informations />
        </TabPanel>
        <TabPanel>
          <Features />
        </TabPanel>
        <TabPanel>
          <Fees />
        </TabPanel>
        <TabPanel>
          <Upload />
          <Images />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

export default PropertyTabs
