import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Card,
  Button,
  Select,
  Input,
  Radio,
  Form,
  Space,
  InputNumber,
  DatePicker,
  Modal,
  message
} from 'antd'
import MaskedInput from 'antd-mask-input'
import axios from 'axios'
import moment from 'moment'
import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import propertyTranslate from '../../helpers/translate/properties'
import banks from '../../helpers/variables/banks'
import AppLayout from '../../layouts/AppLayout'
import api from '../../services/api'

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 14 }
}

const tailLayout = {
  wrapperCol: { offset: 5, span: 14 }
}

function ContractsCreate() {
  var numberRef = useRef(null)

  const [users, setUsers] = useState([])
  const [properties, setProperties] = useState([])
  const [loading, setLoading] = useState(false)
  const [guarantee, setGuarantee] = useState('')

  const [clientModal, setClientModal] = useState(false)

  const history = useHistory()

  const [form] = Form.useForm()
  const [formClient] = Form.useForm()

  const [clientLoading, setClientLoading] = useState(false)

  const [document, setDocument] = useState('CPF')
  const [emailRequired, setEmailRequired] = useState(true)

  useEffect(() => {
    getUsers()
    getProperties()
  }, [])

  async function getUsers() {
    const { data } = await api.get('/user').catch(() => {
      message.error('Não foi possível buscar os proprietários')
    })

    setUsers(data)

    return data
  }

  async function getProperties() {
    const { data } = await api.get('/property').catch(() => {
      message.error('Não foi possível buscar as propriedades')
    })

    setProperties(data)

    return data
  }

  async function onSubmit(values) {
    setLoading(true)

    try {
      const {
        data: { id }
      } = await api.post('/proposal/manual', values)

      history.push(`/contracts/info/${id}`)
    } catch (err) {
      message.error(
        err.response.data[0].message ||
          'Ocorreu um erro ao tentar fazer cadastro do imóvel, revise as informações'
      )
    }

    setLoading(false)
  }

  async function submitClient(values) {
    if (loading) return false

    setClientLoading(true)

    try {
      const { data } = await api.post('/user/manual', values)

      await getUsers()

      formClient.resetFields()
      setClientModal(false)

      message.info(`Usuário adicionado, o código dele é ${data.id}`)
    } catch (err) {
      message.error(
        err.response.data.message || 'Não foi possível efetuar o cadastro'
      )
    }

    setClientLoading(false)
  }

  return (
    <AppLayout title="Contratos" keyPage="12">
      <Card
        title="Adicionar novo contrato"
        extra={
          <Button onClick={() => setClientModal(true)}>
            Adicionar novo usuário
          </Button>
        }
      >
        <Form
          {...layout}
          name="register"
          form={form}
          onFinish={onSubmit}
          autoComplete="off"
          scrollToFirstError
          onValuesChange={obj => {
            if (obj.guarantee) {
              setGuarantee(obj.guarantee)
            }

            if (obj.property_id) {
              const property = properties.find(el => el.id === obj.property_id)
              form.setFieldsValue({
                discount: 0,
                commission: 10,
                current_price: property.price,
                start_date: moment()
              })
            }
          }}
        >
          <Form.Item
            label="Inquilino"
            name="user_id"
            rules={[{ required: true, message: 'Informe o inquilino' }]}
          >
            <Select
              showSearch
              filterOption={(input, option) =>
                option.children
                  .toString()
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {users.map(user => (
                <Select.Option key={`user-${user.id}`} value={user.id}>
                  [Cod {user.id}] {user.name} {user.cpf_cpnj}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Imóvel"
            name="property_id"
            rules={[{ required: true, message: 'Informe o imóvel' }]}
          >
            <Select
              showSearch
              filterOption={(input, option) =>
                option.children
                  .toString()
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {properties.map(property => (
                <Select.Option
                  key={`property-${property.id}`}
                  value={property.id}
                >
                  [Cod {property.id}]{' '}
                  {propertyTranslate.location(property.location)}{' '}
                  {property.street}, nº {property.number} /{' '}
                  {property.complement} -{' '}
                  {propertyTranslate.type(property.type)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <hr />
          <h5 className="text-center font-weight-light">
            Dados Bancários do Proprietário
          </h5>
          <Form.Item
            label="Banco"
            name="bank_code"
            rules={[
              { required: true, message: 'Informe a quantidade de quartos' }
            ]}
          >
            <Select
              showSearch
              filterOption={(input, option) =>
                option.children
                  .toString()
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {banks.map((el, idx) => (
                <Select.Option key={idx} value={el.value}>
                  {el.value} - {el.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Input type="hidden" name="bank_name" />
          <Form.Item
            label="Titular"
            name="bank_full_name"
            rules={[{ required: true, message: 'Informe o nome do titular' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Conta"
            name="bank_account"
            rules={[{ required: true, message: 'Informe a conta' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Agência"
            name="bank_agency"
            rules={[{ required: true, message: 'Informe a agência' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Observações" name="bank_obs">
            <Input />
          </Form.Item>
          <hr />
          <h5 className="text-center font-weight-light">Outros dados</h5>
          <Form.Item
            label="Garantia"
            name="guarantee"
            rules={[{ required: true, message: 'Informe a garantia' }]}
          >
            <Select>
              <Select.Option value="SECURITY_DEPOSIT">Caução</Select.Option>
              <Select.Option value="BONDSMAN">Fiador</Select.Option>
              <Select.Option value="BAIL_INSURANCE">
                Seguro Fiança
              </Select.Option>
              <Select.Option value="CREDPAGO">CredPago</Select.Option>
              <Select.Option value="OTHER">
                Garantia da Imobiliária
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Comissão"
            name="commission"
            rules={[{ required: true, message: 'Informe a quantidade' }]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="Preço atual"
            name="current_price"
            rules={[
              { required: true, message: 'Informe o preço atual de cobrança' }
            ]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="Dia do Pagamento"
            name="transfer_date"
            rules={[{ required: true, message: 'Informe o dia do pagamento' }]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="Data inicial"
            name="start_date"
            rules={[
              { required: true, message: 'Informe a data final do contrato' }
            ]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            label="Data final"
            name="end_date"
            rules={[
              { required: true, message: 'Informe a data final do contrato' }
            ]}
          >
            <DatePicker />
          </Form.Item>
          <hr />
          {guarantee === 'BONDSMAN' && (
            <>
              <Form.Item label="Fiadores">
                <Form.List name="guarantors">
                  {(fields, { add, remove }) => {
                    return (
                      <div>
                        {fields.map(field => (
                          <Space key={field.key} align="center">
                            <Form.Item
                              {...field}
                              name={[field.name, 'id']}
                              fieldKey={[field.fieldKey, 'id']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Selecione o fiador'
                                }
                              ]}
                            >
                              <Select
                                showSearch
                                style={{ width: 350 }}
                                filterOption={(input, option) =>
                                  option.children
                                    .toString()
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {users.map(user => (
                                  <Select.Option
                                    key={`user-${user.id}`}
                                    value={user.id}
                                  >
                                    [Cod {user.id}] {user.name} {user.cpf_cpnj}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                            <MinusCircleOutlined
                              onClick={() => {
                                remove(field.name)
                              }}
                            />
                          </Space>
                        ))}

                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => {
                              add()
                            }}
                            block
                          >
                            <PlusOutlined /> Adicionar fiador
                          </Button>
                        </Form.Item>
                      </div>
                    )
                  }}
                </Form.List>
              </Form.Item>
              <hr />
            </>
          )}
          <Form.Item {...tailLayout}>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              disabled={loading}
              loading={loading}
            >
              Cadastrar
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <Modal
        title="Adicionar novo cliente"
        visible={clientModal}
        width={640}
        onOk={() => formClient.submit()}
        okButtonProps={{ loading: clientLoading, disabled: clientLoading }}
        onCancel={() => setClientModal(false)}
        cancelText="Fechar"
        okText="Salvar cliente"
      >
        <Form
          form={formClient}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          layout="horizontal"
          initialValues={{ type: 'OWNER', document: 'CPF', sale_rent: 'RENT' }}
          onFinish={submitClient}
          onValuesChange={async obj => {
            if (obj.document) {
              setDocument(obj.document)
            }

            if (obj.sale_rent) {
              setEmailRequired(obj.sale_rent === 'RENT')
            }

            if (obj.cep && obj.cep.split('_').length === 1) {
              const { data } = await axios.get(
                `https://viacep.com.br/ws/${obj.cep
                  .replace('.', '')
                  .replace('-', '')}/json/`
              )

              formClient.setFieldsValue({
                street: data.logradouro,
                neighborhood: data.bairro,
                city: data.localidade,
                state: data.uf
              })

              numberRef.focus()
            }
          }}
        >
          <Form.Item
            label="Nome"
            name="name"
            rules={[{ required: true, message: 'Informe o nome' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Documento" name="document">
            <Radio.Group>
              <Radio.Button value="CPF">CPF</Radio.Button>
              <Radio.Button value="CNPJ">CNPJ</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="Cadastro para" name="sale_rent">
            <Radio.Group>
              <Radio.Button value="RENT">Aluguel</Radio.Button>
              <Radio.Button value="SALE">Venda</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="CPF ou CNPJ"
            name="cpf_cnpj"
            rules={[{ required: true, message: 'Informe o documento' }]}
          >
            <MaskedInput
              mask={
                document === 'CPF' ? '111.111.111-11' : '11.111.111/1111-11'
              }
            />
          </Form.Item>
          <Form.Item
            label="Tipo de cliente"
            name="type"
            rules={[{ required: true, message: 'Informe o tipo' }]}
          >
            <Select>
              <Select.Option value="OWNER">Proprietário</Select.Option>
              <Select.Option value="TENANT">Inquilino</Select.Option>
              <Select.Option value="GUARANTOR">Inquilino</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="E-mail"
            name="email"
            rules={[{ required: emailRequired, message: 'Informe o e-mail' }]}
          >
            <Input type="email" />
          </Form.Item>
          <Form.Item
            label="Telefone"
            name="phone"
            rules={[{ required: true, message: 'Informe o telefone' }]}
          >
            <MaskedInput mask="(11) 1 1111-1111" />
          </Form.Item>
          <hr />
          <Form.Item label="CEP" name="cep">
            <MaskedInput mask="11.111-111" />
          </Form.Item>
          <Form.Item label="Endereço">
            <Input.Group compact>
              <Form.Item name="street" style={{ marginRight: 10 }}>
                <Input placeholder="Logradouro" style={{ width: 340 }} />
              </Form.Item>
              <Form.Item name="neighborhood" style={{ marginRight: 10 }}>
                <Input placeholder="Bairro" />
              </Form.Item>
              <Form.Item name="number" style={{ marginRight: 10 }}>
                <Input
                  placeholder="Número"
                  type="number"
                  style={{ width: 100 }}
                  ref={input => {
                    numberRef = input
                  }}
                />
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item label="Endereço">
            <Input.Group compact>
              <Form.Item name="city" style={{ marginRight: 10 }}>
                <Input readOnly disabled placeholder="Cidade" />
              </Form.Item>
              <Form.Item name="state" style={{ marginRight: 10 }}>
                <Input readOnly disabled placeholder="Estado" />
              </Form.Item>
              <Form.Item
                name="complement"
                style={{ marginRight: 10, width: '80%' }}
              >
                <Input placeholder="Complemento" />
              </Form.Item>
            </Input.Group>
          </Form.Item>
        </Form>
      </Modal>
    </AppLayout>
  )
}

export default ContractsCreate
