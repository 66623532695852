import { Button, Card, Row, Col, Form, Input, message } from 'antd'
import React, { useState } from 'react'

import AppLayout from '../../layouts/AppLayout'
import api from '../../services/api'

function ChangePassword() {
  const [isLoading, setLoading] = useState(false)

  const [form] = Form.useForm()

  async function create(values) {
    message.loading('Atualizando...')

    setLoading(true)

    try {
      await api.patch('/user/admin/password', values)

      message.success('Senha alterada com sucesso')
    } catch (err) {
      message.error('Ocorreu um erro ao tentar alterar sua senha')
    }

    setLoading(false)
  }

  return (
    <AppLayout title="Alterar senha" keyPage="21" subKeyPage="sub3">
      <Row gutter={25}>
        <Col md={8}>
          <Card title="Altere sua senha">
            <Form
              name="change-password"
              form={form}
              onFinish={create}
              autoComplete="off"
            >
              <Form.Item
                name="password"
                rules={[{ required: true, message: 'Informe sua nova senha' }]}
              >
                <Input type="password" placeholder="Nova senha" />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={isLoading}>
                  Alterar
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </AppLayout>
  )
}

export default ChangePassword
