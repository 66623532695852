import { Table, Card, Input, message } from 'antd'
import React, { useState, useEffect } from 'react'
import Highlighter from 'react-highlight-words'
import { Link } from 'react-router-dom'

import propertiesTranslate from '../../helpers/translate/properties'
import AppLayout from '../../layouts/AppLayout'
import api from '../../services/api'

const { Search } = Input

function PropertiesRequests() {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      responsive: ['md'],
      sorter: (a, b) => a.id - b.id,
      render: text =>
        search.length > 0 ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[search]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        )
    },
    {
      title: 'Endereço',
      dataIndex: 'address',
      key: 'address',
      render: text =>
        search.length > 0 ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[search]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        )
    },
    {
      title: 'CEP',
      dataIndex: 'cep',
      key: 'cep',
      render: text =>
        search.length > 0 ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[search]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        )
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
      responsive: ['md'],
      render: text =>
        search.length > 0 ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[search]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        )
    },
    {
      title: 'Locação',
      dataIndex: 'location',
      key: 'location',
      render: text =>
        search.length > 0 ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[search]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        )
    },
    {
      title: 'Preço',
      dataIndex: 'price',
      key: 'price',
      responsive: ['md'],
      render: text =>
        search.length > 0 ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[search]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        )
    },
    {
      title: 'Ações',
      render: (text, record) => {
        return <Link to={`/properties/info/${record.id}`}>Mais detalhes</Link>
      }
    }
  ]

  useEffect(() => {
    getData()
  }, [])

  async function getData() {
    setLoading(true)

    try {
      const { data: properties } = await api.get('/property/requests')

      setData(
        properties.map(property => ({
          ...property,
          key: property.id,
          address: `${property.street}, ${property.number}, ${property.neighborhood} - ${property.city}`,
          status:
            property.status === 'PENDING'
              ? 'Pendente'
              : propertiesTranslate.status(property.status),
          location: propertiesTranslate.location(property.location),
          type: propertiesTranslate.type(property.type),
          price: `R$${property.price.toLocaleString({
            maximumFractionDigits: 2
          })}`
        }))
      )
    } catch (err) {
      message.error('Ocorreu um erro ao buscar os dados')
    }

    setLoading(false)
  }

  function filtered() {
    return data.filter(el => JSON.stringify(el).includes(search))
  }

  return (
    <AppLayout title="Imóveis" keyPage="10" subKeyPage="sub2">
      <Card title="Gerencie os imóveis">
        <Search
          placeholder="Procurar por um imóvel"
          onSearch={setSearch}
          size="large"
        />
        <br />
        <br />
        <Table
          columns={columns}
          dataSource={search.length > 0 ? filtered() : data}
          locale={{ emptyText: 'Não há imóveis cadastrados' }}
          loading={loading}
        />
      </Card>
    </AppLayout>
  )
}

export default PropertiesRequests
