import axios from 'axios'

import { getToken } from '../guard/auth'

const api = axios.create({
  // baseURL: 'https://core-api.advqxs.easypanel.host'
  baseURL: 'https://core-api.advqxs.easypanel.host'
})

api.interceptors.request.use(async config => {
  const token = getToken()

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

export default api
