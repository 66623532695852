import {
  Box,
  Badge,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  SimpleGrid,
  TableCaption,
  Heading,
  IconButton,
  Spinner,
  HStack,
  Center,
  useToast
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import React, { useState } from 'react'
// import Chart from 'react-apexcharts'
import { AiOutlineEye } from 'react-icons/ai'
import { BsFillCaretDownFill, BsFillCaretUpFill, BsTrash } from 'react-icons/bs'
import { FaMinus } from 'react-icons/fa'
import ReactPaginate from 'react-paginate'

import TellerCard from './Card'

import api from '../../services/api'

function TellerTable({ data, setPage, transaction, loading, onUpdate }) {
  // const [chartWidth, setChartWidth] = useState()

  // useEffect(() => {
  //   const clientWidth = document.querySelector('#teller-resume').clientWidth

  //   setTimeout(() => {
  //     setChartWidth(clientWidth)
  //   }, 100)
  // }, [])

  const [isLoading, setLoading] = useState()

  const toast = useToast()

  const handleDelete = async id => {
    setLoading(id)

    try {
      await api.delete(`/movimentation/${id}`)

      onUpdate()
    } catch {
      toast({
        title: 'Oopss...',
        description: 'Não foi possível deletar a movimentação',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }

    setLoading(null)
  }

  return (
    <Box maxW="100%">
      {data && data.invoices && (
        <SimpleGrid columns={3} spacing={4} mb={6} maxW="100%">
          <TellerCard
            title="Entrada"
            value={`R$ ${
              data &&
              data.invoices.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })
            }`}
            icon={<BsFillCaretUpFill />}
            iconBg="green.100"
            iconColor="green.600"
            isLoading={loading}
          />
          <TellerCard
            title="Saída"
            value={`R$ ${
              data &&
              data.transfers.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })
            }`}
            icon={<BsFillCaretDownFill />}
            iconBg="red.100"
            iconColor="red.600"
            isLoading={loading}
          />
          <TellerCard
            title="Saldo"
            value={`R$ ${
              data &&
              (data.invoices - data.transfers).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })
            }`}
            icon={<FaMinus />}
            iconBg="blue.100"
            iconColor="blue.600"
            isLoading={loading}
          />
        </SimpleGrid>
      )}
      {/* <Box
        mb={6}
        pos="relative"
        bg="white"
        p={8}
        id="teller-resume"
        borderRadius="lg"
        border="solid 1px"
        borderColor="gray.200"
      >
        <Heading size="md" fontWeight={400} mb={4}>
          Resumo
        </Heading>
        {chartWidth && (
          <Chart
            options={{
              chart: {
                sparkline: {
                  enabled: true
                }
              },
              xaxis: {
                categories: ['01/Ago', '02/Ago', '03/Ago', '05/Ago', '06/Ago']
              },
              colors: ['#48BB78', '#F56565'],
              stroke: {
                dashArray: [0, 5],
                curve: 'smooth'
              },
              grid: {
                borderColor: '#000'
              },
              tooltip: {
                fixed: {
                  enabled: false
                },
                x: {
                  show: true
                },
                y: {
                  title: {
                    formatter: function (data) {
                      return data === 'Saída' ? '- R$' : 'R$'
                    }
                  }
                },
                marker: {
                  show: false
                }
              }
            }}
            series={[
              {
                name: 'Entrada',
                data: [1000, 4343, 5332, 9389, 1345]
              },
              {
                name: 'Saída',
                data: [500, 1354, 1423, 5476, 3463]
              }
            ]}
            type="line"
            height="100px"
          />
        )}
      </Box> */}
      <Box
        pos="relative"
        bg="white"
        p={8}
        borderRadius="lg"
        border="solid 1px"
        borderColor="gray.200"
      >
        <Heading size="md" fontWeight={400} mb={4}>
          Movimentações
        </Heading>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>{transaction === 'rents' ? 'DOC' : 'ID'}</Th>
              <Th>Descrição</Th>
              <Th isNumeric>Valor</Th>
              <Th isNumeric>Data</Th>
              <Th isNumeric>Ações</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data &&
              !loading &&
              data.data.map(item => (
                <Tr key={`${item.id}-${item.type}`}>
                  <Td>{item.doc || item.id}</Td>
                  <Td fontSize="sm">{item.description}</Td>
                  <Td isNumeric fontSize="sm">
                    <Badge
                      colorScheme={item.type === 'INPUT' ? 'green' : 'red'}
                    >
                      {item.type === 'INPUT' ? '+' : '-'}{' '}
                      {item.amount ||
                        'R$' +
                          item.price.toLocaleString('pt-BR', {
                            minimumFractionDigits: 2
                          })}
                    </Badge>
                  </Td>
                  <Td isNumeric fontSize="sm">
                    {dayjs(item.date || item.created_at).format('DD/MM/YYYY')}
                  </Td>
                  <Td>
                    <HStack justifyContent="flex-end">
                      <IconButton
                        size="sm"
                        icon={<AiOutlineEye />}
                        isDisabled
                      />
                      {transaction !== 'rents' && (
                        <IconButton
                          size="sm"
                          icon={<BsTrash />}
                          colorScheme="red"
                          onClick={() => handleDelete(item.id)}
                          isLoading={isLoading === item.id}
                        />
                      )}
                    </HStack>
                  </Td>
                </Tr>
              ))}
            {loading && (
              <Tr>
                <Td colSpan="5">
                  <Center>
                    <Spinner />
                  </Center>
                </Td>
              </Tr>
            )}
          </Tbody>
          <TableCaption>
            {/* {data.length === 0 ? (
            `Não há transações nesse período`
          ) : ( */}
            <ReactPaginate
              previousLabel={'Anterior'}
              nextLabel={'Próximo'}
              pageCount={data ? data.pages : 1}
              forcePage={data ? data.page - 1 : 0}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              activeClassName={'active'}
              containerClassName="pagination"
              onPageChange={i => setPage(i.selected + 1)}
            />
            {/* )} */}
          </TableCaption>
        </Table>
      </Box>
    </Box>
  )
}

export default TellerTable
