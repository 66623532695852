import {
  Flex,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Button,
  Input,
  Text,
  Grid,
  Spinner,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  ButtonGroup,
  Table,
  TableCaption,
  Thead,
  Tbody,
  Tr,
  Th,
  useDisclosure
  // Td,
  // FormControl,
  // FormLabel,
  // Switch,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import React, { useState, useEffect } from 'react'

import useInvoices from '../../hooks/use-invoice'

function InvoicesModal({ isOpen, onClose }) {
  const {
    loading,
    invoiceData: data,
    destroy,
    delettingLoading,
    error,
    actionLoading,
    refund,
    markAsPaid
  } = useInvoices()

  const {
    isOpen: isPopOpen,
    onOpen: onPopOpen,
    onClose: onPopClose
  } = useDisclosure()

  const {
    isOpen: isAlertOpen,
    onOpen: onAlertOpen,
    onClose: onAlertClose
  } = useDisclosure()

  const [fees, setFees] = useState(0)
  const [trafficTicket, setTrafficTicket] = useState(0)
  const [totalFees, setTotalFees] = useState(0)

  useEffect(() => {
    if (data.status) {
      const { price, fees, due_date: dueDate } = data

      const daysOfDelay = dayjs().diff(dueDate, 'day', false)
      const total = parseFloat(price) + parseFloat(fees)

      const calcFees =
        daysOfDelay - 5 === 0 ? 0 : ((total * 0.03) / 100) * (daysOfDelay - 5)
      const calcTrafficTicket = total * 0.1

      if (daysOfDelay > 5) {
        setFees(calcFees)
        setTrafficTicket(calcTrafficTicket)
      } else if (daysOfDelay > 4) {
        setFees(0)
        setTrafficTicket(calcTrafficTicket)
      } else {
        setFees(0)
        setTrafficTicket(0)
      }
    }
  }, [data])

  useEffect(() => {
    const calcTrafficTicket = parseFloat(
      typeof trafficTicket === 'number'
        ? trafficTicket
        : trafficTicket.replace(',', '.')
    )

    const calcFees = parseFloat(
      typeof fees === 'number' ? fees : fees.replace(',', '.')
    )

    console.log('HAAH', calcTrafficTicket, fees)

    setTotalFees(calcTrafficTicket + calcFees)
  }, [fees, trafficTicket])

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose()
          onPopClose()
        }}
        size="4xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Detalhes da fatura</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {loading ? (
              <Box
                h="400px"
                d="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Spinner />
              </Box>
            ) : error ? (
              <Box
                h="400px"
                d="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Heading>Não encontramos a fatura</Heading>
              </Box>
            ) : (
              <Box>
                <Grid templateColumns="2fr 1fr" gap="16px">
                  <Box borderRight="solid 1px" borderColor="gray.100" pr="5">
                    <Heading size="sm" mb={2}>
                      Taxas extras
                    </Heading>
                    <Table variant="simple" mb={6}>
                      <Thead>
                        <Tr>
                          <Th>Descrição</Th>
                          <Th isNumeric>Valor</Th>
                          <Th></Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {/* <Tr>
                        <Td>Condomínio</Td>
                        <Td isNumeric>R$0,00</Td>
                        <Td isNumeric>
                          <Button size="sm">Remover</Button>
                        </Td>
                      </Tr> */}
                      </Tbody>
                      <TableCaption>Não há taxas extras</TableCaption>
                    </Table>
                    <Flex
                      mb={2}
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Heading size="sm">Deduções e acréscimos</Heading>
                      <Button size="sm" variant="outline">
                        Aplicar novo
                      </Button>
                    </Flex>
                    <Table variant="simple" mb={6}>
                      <Thead>
                        <Tr>
                          <Th>Descrição</Th>
                          <Th>Influência</Th>
                          <Th>Tipo</Th>
                          <Th isNumeric>Valor</Th>
                          <Th></Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {/* <Tr>
                        <Td>Condomínio</Td>
                        <Td isNumeric>R$0,00</Td>
                        <Td isNumeric>
                          <Button size="sm">Remover</Button>
                        </Td>
                      </Tr> */}
                      </Tbody>
                      <TableCaption>Não há deduções e acréscimos</TableCaption>
                    </Table>
                  </Box>
                  <Box pl="5">
                    <Heading size="lg" mb={4}>
                      Resumo
                    </Heading>
                    <Grid templateColumns="1fr 1fr" gap="8px" mb={3}>
                      <Box>
                        <Text>Valor do aluguel</Text>
                        <Input type="number" isReadOnly value={data.price} />
                      </Box>
                      <Box>
                        <Text>Taxas</Text>
                        <Input type="number" isReadOnly value={data.fees} />
                      </Box>
                    </Grid>
                    <Grid templateColumns="1fr 1fr" gap="8px">
                      <Box>
                        <Text>Multa</Text>
                        <Input
                          mb={3}
                          type="number"
                          value={
                            typeof trafficTicket === 'number'
                              ? trafficTicket.toFixed(2)
                              : trafficTicket
                          }
                          onChange={e => setTrafficTicket(e.target.value)}
                        />
                      </Box>
                      <Box>
                        <Text>Juros</Text>
                        <Input
                          mb={5}
                          type="number"
                          value={
                            typeof fees === 'number' ? fees.toFixed(2) : fees
                          }
                          onChange={e => setFees(e.target.value)}
                        />
                      </Box>
                    </Grid>
                  </Box>
                </Grid>
              </Box>
            )}
          </ModalBody>
          <ModalFooter>
            <Text fontSize="2xl" mr="auto">
              Total R$
              {(
                parseFloat(data.price) +
                parseFloat(data.fees) +
                totalFees
              ).toLocaleString('pt-br', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}
            </Text>
            {data.status === 'PENDING' && (
              <Popover
                returnFocusOnClose={false}
                isOpen={isPopOpen}
                onOpen={onPopOpen}
                onClose={onPopClose}
                placement="bottom"
                closeOnBlur={false}
              >
                <PopoverTrigger>
                  <Button
                    mr="3"
                    size="md"
                    colorScheme="red"
                    isLoading={delettingLoading}
                  >
                    Deletar
                  </Button>
                </PopoverTrigger>
                <PopoverContent p={5}>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverBody py="5">
                    Você tem certeza que deseja deletar essa fatura? Essa ação é
                    irreversível.
                  </PopoverBody>
                  <PopoverFooter d="flex" justifyContent="flex-end">
                    <ButtonGroup size="sm">
                      <Button variant="outline" onClick={onPopClose}>
                        Cancelar
                      </Button>
                      <Button
                        colorScheme="red"
                        isLoading={delettingLoading}
                        onClick={async () => {
                          await destroy()
                          onPopClose()
                        }}
                      >
                        Sim
                      </Button>
                    </ButtonGroup>
                  </PopoverFooter>
                </PopoverContent>
              </Popover>
            )}
            {data.status === 'PAID' && (
              <Button colorScheme="yellow" mx={3} isDisabled>
                Baixar comprovante
              </Button>
            )}
            {data.status === 'PAID' ? (
              <Button
                colorScheme="red"
                mr={2}
                isLoading={actionLoading}
                onClick={refund}
              >
                Estornar
              </Button>
            ) : (
              <Button colorScheme="blue" onClick={onAlertOpen}>
                Marcar como pago
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AlertDialog isOpen={isAlertOpen} onClose={onAlertClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirmar baixa manual
            </AlertDialogHeader>

            <AlertDialogBody>
              Você tem certeza que deseja efetuar a baixa manual?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button size="md" onClick={onAlertClose}>
                Cancelar
              </Button>
              <Button
                ml={3}
                size="md"
                colorScheme="whatsapp"
                isLoading={actionLoading}
                isDisabled={actionLoading}
                onClick={() => {
                  markAsPaid(
                    parseFloat(data.price) + parseFloat(data.fees) + totalFees,
                    onAlertClose
                  )
                }}
              >
                Confirmar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default InvoicesModal
