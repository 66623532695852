import {
  Box,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Grid,
  Select
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import React from 'react'
import InputMask from 'react-input-mask'

import useClient from '../../hooks/use-client'

function ClientDetails() {
  const { data, updateData, setUpdating, setUpdatingInformation } = useClient()

  function handleInformationChange(event) {
    const { name, value } = event.target

    var toAssign = {
      ...JSON.parse(JSON.stringify(data))
    }

    if (!toAssign.information) {
      toAssign.information = {}
    }

    toAssign.information[name] = value

    setUpdating(true)
    setUpdatingInformation(true)

    updateData(draft => {
      return Object.assign(draft, toAssign)
    })
  }

  return (
    <Grid templateColumns="repeat(2, 1fr)" gap="40px">
      <Box p="0 5">
        <Heading size="md" mb={4}>
          Dados gerais
        </Heading>
        <Grid templateColumns="2fr 1fr" gap="15px">
          <FormControl id="rg" mb={2} isRequired>
            <FormLabel>RG</FormLabel>
            <Input
              name="rg"
              value={data.information && data.information.rg}
              onChange={handleInformationChange}
            />
          </FormControl>
          <FormControl id="birth_date" mb={2}>
            <FormLabel>Data de Nascimento</FormLabel>
            <Input
              type="date"
              name="birth_date"
              value={
                data.information &&
                data.information.birth_date &&
                dayjs(data.information.birth_date).format('YYYY-MM-DD')
              }
              onChange={handleInformationChange}
            />
          </FormControl>
        </Grid>
        <Grid templateColumns="repeat(3, 1fr)" gap="15px">
          <FormControl id="personDefaultType" isRequired>
            <FormLabel>Pessoa</FormLabel>
            <Select
              name="person_type"
              value={data.information && data.information.person_type}
              onChange={handleInformationChange}
            >
              <option value="FISICA">Pessoa Física</option>
              <option value="JURICA">Pessoa Jurídica</option>
            </Select>
          </FormControl>
          <FormControl id="profession" isRequired>
            <FormLabel>Profissão</FormLabel>
            <Input
              name="profession"
              value={data.information && data.information.profession}
              onChange={handleInformationChange}
            />
          </FormControl>
          <FormControl id="marital_status" isRequired>
            <FormLabel>Estado Civil</FormLabel>
            <Select
              name="marital_status"
              value={data.information && data.information.marital_status}
              onChange={handleInformationChange}
            >
              <option value="SOLTEIRO">Solteiro(a)</option>
              <option value="CASADO">Casado(a)</option>
              <option value="DIVORCIADO">Divorciado(a)</option>
              <option value="VIUVO">Viúvo(a)</option>
              <option value="SEPARADO">Separado(a)</option>
            </Select>
          </FormControl>
        </Grid>
      </Box>
      {((data.information && data.information.person_type === 'PJ') ||
        (data.information && data.information.marital_status === 'CASADO')) && (
        <Box p="0 5">
          <Heading size="md" mb={4}>
            Dados do{' '}
            {data.information && data.information.person_type === 'PJ'
              ? 'sócio'
              : 'cônjugue'}
          </Heading>
          <FormControl id="spounce_name" mb={2}>
            <FormLabel>Nome completo</FormLabel>
            <Input
              name="spounce_name"
              value={data.information && data.information.spounce_name}
              onChange={handleInformationChange}
            />
          </FormControl>
          <Grid templateColumns="repeat(3, 1fr)" gap="15px">
            <FormControl id="spounce_rg" mb={2}>
              <FormLabel>RG</FormLabel>
              <Input
                name="spounce_rg"
                value={data.information && data.information.spounce_rg}
                onChange={handleInformationChange}
              />
            </FormControl>
            <FormControl id="spounce_cpf" mb={2}>
              <FormLabel>CPF</FormLabel>
              <InputMask
                name="spounce_cpf"
                value={data.information && data.information.spounce_cpf}
                onChange={handleInformationChange}
                mask={'999.999.999-99'}
              >
                {inputProps => <Input {...inputProps} />}
              </InputMask>
            </FormControl>
            <FormControl id="spouse_profession" mb={2}>
              <FormLabel>Profissão</FormLabel>
              <Input
                name="spouse_profession"
                value={data.information && data.information.spouse_profession}
                onChange={handleInformationChange}
              />
            </FormControl>
          </Grid>
        </Box>
      )}
    </Grid>
  )
}

export default ClientDetails
