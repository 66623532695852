import { ChakraProvider } from '@chakra-ui/react'
import React from 'react'
import ReactDOM from 'react-dom'
import GoogleFontLoader from 'react-google-font-loader'

import 'antd/dist/antd.min.css'
import './assets/styles/fullcalendar-core.css'
import './assets/styles/fullcalendar-daygrid.css'
import './assets/styles/fullcalendar-theme.css'

import theme from './config/theme'
import Providers from './contexts'
import Routes from './routes'

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <Providers>
      <GoogleFontLoader
        fonts={[
          {
            font: 'Source Sans Pro',
            weights: [200, 300, 400, 600, 700, 900]
          }
        ]}
      />
      <Routes />
    </Providers>
  </ChakraProvider>,
  document.getElementById('root')
)
