import {
  Grid,
  Flex,
  Box,
  Heading,
  Text,
  Input,
  Button,
  IconButton
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { FiTrash } from 'react-icons/fi'
import uniqid from 'uniqid'

import useProperty from '../../hooks/use-property'

function PropertyFees() {
  const [name, setName] = useState('')
  const [price, setPrice] = useState('')

  const { data, addFee, onFeeDelete } = useProperty()

  return (
    <Box>
      <Flex alignItems="center" mb={4}>
        <Input
          placeholder="Informe o nome da taxa"
          value={name}
          mr={3}
          onChange={e => setName(e.target.value)}
        />
        <Input
          placeholder="Informe o valor"
          value={price}
          type="number"
          step={2}
          mr={3}
          onChange={e => setPrice(e.target.value)}
        />
        <Button
          colorScheme="orange"
          px="8"
          onClick={() => {
            addFee({ name, price })

            setName('')
            setPrice('')
          }}
        >
          Adicionar
        </Button>
      </Flex>
      <Grid
        mt={5}
        templateColumns={['100%', '100%', 'repeat(2, 1fr)']}
        gap="15px"
      >
        {data.fees.map(fee => (
          <Flex
            key={uniqid()}
            bg="gray.50"
            p="12px"
            borderRadius="lg"
            justify="space-between"
            alignItems="center"
          >
            <Box>
              <Heading size="sm">{fee.name}</Heading>
              <Text>
                R$
                {parseFloat(fee.price).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2
                })}
              </Text>
            </Box>

            <Flex as="div" alignItems="center" justifyContent="flex-start">
              <IconButton
                size="md"
                icon={<FiTrash />}
                bg="white"
                color="red.400"
                isRound
                onClick={() => onFeeDelete(fee.id)}
              />
            </Flex>
          </Flex>
        ))}
      </Grid>
    </Box>
  )
}

export default PropertyFees
