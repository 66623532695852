import { useDisclosure } from '@chakra-ui/react'
import {
  Card,
  InputNumber,
  Tabs,
  Row,
  Col,
  Form,
  Select,
  DatePicker,
  Input,
  Button,
  Statistic,
  Upload,
  Modal,
  Table,
  Popconfirm,
  message
} from 'antd'
import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import ClientModal from '../../components/Client/Modal'
import PropertyModal from '../../components/Property/Modal'
import { getToken } from '../../guard/auth'
import propertyTranslate from '../../helpers/translate/properties'
import banks from '../../helpers/variables/banks'
import AppLayout from '../../layouts/AppLayout'
import api from '../../services/api'

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 16 }
}
const tailLayout = {
  wrapperCol: { offset: 5, span: 16 }
}

function ContractsInfo() {
  const { id } = useParams()

  const history = useHistory()

  const [form] = Form.useForm()
  const [extraForm] = Form.useForm()
  const [guarantorForm] = Form.useForm()

  const [modalOpened, setModalOpened] = useState(false)

  const [fileType, setFileType] = useState('')

  const [data, setData] = useState(null)
  const [owner, setOwner] = useState(null)
  const [users, setUsers] = useState([])
  const [properties, setProperties] = useState([])
  const [loading, setLoading] = useState(false)
  const [updating, setUpdating] = useState(false)

  const [selected, setSelected] = useState()
  const [propertySelected, setPropertySelected] = useState()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isPropertyOpen,
    onOpen: onPropertyOpen,
    onClose: onPropertyClose
  } = useDisclosure()

  async function getUsers() {
    const { data } = await api.get('/user').catch(() => {
      message.error('Não foi possível buscar os proprietários')
    })

    setUsers(data)
  }

  const guarantorsColumns = [
    {
      title: 'ID',
      render: (_text, record) => record.user_id
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'RG',
      dataIndex: 'rg',
      key: 'rg'
    },
    {
      title: 'CPF / CNPJ',
      dataIndex: 'cpf_cnpj',
      key: 'cpf_cnpj'
    },
    {
      title: 'Telefone',
      dataIndex: 'phone',
      key: 'phone'
    },
    {
      title: 'Ações',
      render: (_text, record) => (
        <Button type="danger" onClick={() => removeGuarantor(record.id)}>
          Remover
        </Button>
      )
    }
  ]

  const documentColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Documento',
      dataIndex: 'source',
      key: 'source'
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: 'Ações',
      render: (text, record) => {
        return (
          <>
            <Button size="small" onClick={() => download(record.url)}>
              Baixar
            </Button>
            <Button
              size="small"
              type="danger"
              className="ml-2"
              onClick={() => removeDocument(record.id)}
            >
              Excluir
            </Button>
          </>
        )
      }
    }
  ]

  const invoiceColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Valor',
      dataIndex: 'price',
      key: 'price'
    },
    {
      title: 'Taxas',
      dataIndex: 'fees',
      key: 'fees'
    },
    {
      title: 'Data do pagamento',
      dataIndex: 'pay_day',
      key: 'pay_day',
      render: text => new Date(text).toLocaleDateString('pt-BR')
    },
    {
      title: 'Vencimento',
      dataIndex: 'due_date',
      key: 'due_date',
      render: text => new Date(text).toLocaleDateString('pt-BR')
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: text => (text === 'PAID' ? 'Pago' : 'Pendente')
    }
  ]

  const transfersColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Repasse',
      dataIndex: 'amount',
      key: 'amount'
    },
    {
      title: 'Data da transferência',
      dataIndex: 'due_date',
      key: 'due_date',
      render: text => new Date(text).toLocaleDateString('pt-BR')
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: text => (text === 'DONE' ? 'Efetuado' : 'Pendente')
    }
  ]

  const extraColumns = [
    {
      title: 'Descrição',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Valor',
      dataIndex: 'amount',
      key: 'amount',
      render: text =>
        'R$' +
        parseFloat(text).toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
      render: text => (text === 'CREDIT' ? 'Crédito' : 'Débito')
    },
    {
      title: 'Boleto',
      dataIndex: 'boleto',
      key: 'boleto',
      render: text => (text ? 'Sim' : 'Não')
    },
    {
      title: 'Influencia na comissão',
      dataIndex: 'influence',
      key: 'influence',
      render: text => (text === 'RENT' ? 'Sim' : 'Não')
    },
    {
      title: 'Incluso no repasse',
      dataIndex: 'transfer',
      key: 'transfer',
      render: text => (text ? 'Sim' : 'Não')
    },
    {
      title: 'Remover',
      render: record => (
        <Button
          type="danger"
          size="small"
          onClick={() => destroyExtra(record.id)}
        >
          Deletar
        </Button>
      )
    }
  ]

  async function getData() {
    setLoading(true)

    try {
      var {
        data: { owner, ...data }
      } = await api.get(`/proposal/${id}`)

      data.start_date = moment(data.start_date)
      data.end_date = moment(data.end_date)

      if (data.next_readjustment) {
        data.next_readjustment = moment(data.next_readjustment)
      }

      setOwner(owner)
      setData(data)
    } catch (err) {
      console.log(err)
      message.error('Ocorreu um erro ao tentar buscar os dados')
    }

    setLoading(false)
  }

  useEffect(() => {
    getData()
    getUsers()
    getProperties()
    // eslint-disable-next-line
  }, [])

  async function update(values) {
    if (updating) {
      return false
    }

    setUpdating(true)

    values.start_date = moment(values.start_date).format('YYYY-MM-DD')
    values.end_date = moment(values.end_date).format('YYYY-MM-DD')
    values.next_readjustment = moment(values.next_readjustment).format(
      'YYYY-MM-DD'
    )

    message.loading('Aguarde...')

    try {
      await api.patch(`/proposal/${id}`, values)
      message.success('Contrato atualizado')

      getData()
    } catch (err) {
      console.log(err.response)
      message.error('Não foi possível atualizar os dados')
    }

    setUpdating(false)
  }

  async function remove() {
    message.loading('Aguarde...')

    try {
      await api.delete(`/proposal/${id}`)

      message.success('Contrato deletado permanentemente')
      history.push('/contracts')
    } catch (err) {
      console.log(err.response)
      message.error('Não foi possível deletar o contrato')
    }
  }

  async function download(url) {
    const link = document.createElement('a')
    link.href = url
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  async function removeDocument(dId) {
    message.loading('Aguarde...')

    try {
      await api.delete(`/proposal/${dId}/document`)

      message.success('Documento deletado')

      getData()
    } catch (err) {
      console.log(err.response)
      message.error('Não foi possível deletar o documento')
    }
  }

  async function close() {
    message.loading('Aguarde...')

    try {
      await api.patch(`/proposal/${id}/close`)

      message.success('Contrato encerrado')

      await getData()
    } catch (err) {
      console.log(err.response)
      message.error('Não foi possível encerrar o contrato')
    }
  }

  async function destroyExtra(id) {
    try {
      await api.delete(`/proposal/${id}/extra`)
      message.success('Pronto!')

      await getData()
    } catch (err) {
      message.error(
        'Ocorreu um erro ao tentar deletar a(o) dedução / acréscimo'
      )
    }
  }

  async function submitExtra(values) {
    try {
      values.boleto = values.boleto === 'true'
      values.transfer = values.transfer === 'true'

      await api.put(`/proposal/${id}/extra`, values)
      message.success('Pronto!')

      await getData()
    } catch (err) {
      message.error(
        'Ocorreu um erro ao tentar cadastrar nova dedução / acréscimo'
      )
    }
  }

  async function getProperties() {
    setLoading(true)

    try {
      const { data } = await api.get('/property')

      setProperties(data)
    } catch (err) {
      message.error('Ocorreu um erro ao buscar os dados')
    }

    setLoading(false)
  }

  async function removeGuarantor(id) {
    message.loading('Aguarde...')

    try {
      await api.delete(`/proposal/${id}/guarantor`)

      message.success('Fiador removido do contrato!')

      getData()
    } catch {
      message.error('Não foi possível remover o fiador')
    }
  }

  return (
    <AppLayout title="Detalhes do contrato" keyPage="12">
      <ClientModal isOpen={isOpen} onClose={onClose} clientId={selected} />
      <PropertyModal
        isOpen={isPropertyOpen}
        onClose={onPropertyClose}
        id={propertySelected}
      />
      <Card
        title={`Contrato #${id}`}
        className="mb-3"
        loading={loading}
        extra={
          <Popconfirm
            title="Tem certeza que deseja excluir este contrato?"
            onConfirm={remove}
            okText="Sim, tenho certeza"
            cancelText="Não"
          >
            <Button size="small" type="danger">
              Deletar contrato
            </Button>
          </Popconfirm>
        }
      >
        <Row gutter={25}>
          <Col md={4}>
            <h6 className="text-uppercase font-weight-light">Ações rápidas</h6>
            {data && data.status === 'APPROVED' && (
              <>
                <Button type="ghost" className="mr-3">
                  Prorrogar
                </Button>
                <Popconfirm
                  title="Tem certeza que deseja encerrar este contrato?"
                  onConfirm={close}
                  okText="Sim, tenho certeza"
                  cancelText="Não"
                >
                  <Button type="danger">Encerrar</Button>
                </Popconfirm>
              </>
            )}
          </Col>
          <Col md={4}>
            <Statistic
              title="Situação"
              value={
                data && data.status === 'APPROVED'
                  ? 'Em andamento'
                  : 'Encerrado'
              }
            />
          </Col>
          <Col md={4}>
            <Statistic
              title="Inquilino"
              value={data && data.user.name.split(' ')[0]}
            />
            {/* eslint-disable-next-line */}
            <a
              href="#"
              onClick={e => {
                e.preventDefault()

                setSelected(data && data.user_id)
                onOpen()
              }}
            >
              visualizar perfil
            </a>
          </Col>
          <Col md={4}>
            <Statistic
              title="Proprietário"
              value={owner && owner.name.split(' ')[0]}
            />
            {/* eslint-disable-next-line */}
            <a
              href="#"
              onClick={e => {
                e.preventDefault()

                setSelected(data && owner.id)
                onOpen()
              }}
            >
              visualizar perfil
            </a>
          </Col>
          <Col md={4}>
            <Statistic title="Imóvel" value={data && data.property_id} />
            {/* eslint-disable-next-line */}
            <a
              href="#"
              onClick={e => {
                e.preventDefault()

                setPropertySelected(data && data.property_id)
                onPropertyOpen()
              }}
            >
              visualizar imóvel
            </a>
          </Col>
          <Col md={4}>
            <Statistic
              title="Próximo reajuste"
              value={
                data &&
                new Date(data.next_readjustment).toLocaleDateString('pt-BR')
              }
            />
          </Col>
        </Row>
      </Card>
      <Card loading={loading}>
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Detalhes do contrato" key="1">
            {data && (
              <Form
                {...layout}
                form={form}
                onFinish={update}
                initialValues={data}
                autoComplete="off"
                scrollToFirstError
              >
                <h5 className="text-center font-weight-light">
                  Dados Bancários do Proprietário
                </h5>
                <Form.Item
                  label="Banco"
                  name="bank_code"
                  rules={[
                    {
                      required: true,
                      message: 'Informe a quantidade de quartos'
                    }
                  ]}
                >
                  <Select
                    showSearch
                    style={{ width: '80%' }}
                    filterOption={(input, option) =>
                      option.children
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {banks.map((el, idx) => (
                      <Select.Option key={idx} value={el.value}>
                        {el.value} - {el.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Input type="hidden" name="bank_name" />
                <Form.Item
                  label="Titular"
                  name="bank_full_name"
                  rules={[
                    { required: true, message: 'Informe o nome do titular' }
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Conta"
                  name="bank_account"
                  rules={[{ required: true, message: 'Informe a conta' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Agência"
                  name="bank_agency"
                  rules={[{ required: true, message: 'Informe a agência' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="CPF"
                  name="bank_cpf"
                  rules={[{ required: true, message: 'Informe o CPF' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item label="Chave PIX" name="bank_pix">
                  <Input />
                </Form.Item>
                <Form.Item label="Observações" name="bank_obs">
                  <Input />
                </Form.Item>
                <hr />
                <h5 className="text-center font-weight-light">Outros dados</h5>
                <Form.Item
                  label="Garantia"
                  name="guarantee"
                  rules={[{ required: true, message: 'Informe a garantia' }]}
                >
                  <Select>
                    <Select.Option value="SECURITY_DEPOSIT">
                      Caução
                    </Select.Option>
                    <Select.Option value="BONDSMAN">Fiador</Select.Option>
                    <Select.Option value="BAIL_INSURANCE">
                      Seguro Fiança
                    </Select.Option>
                    <Select.Option value="CREDPAGO">CredPago</Select.Option>
                    <Select.Option value="OTHER">
                      Garantia da Imobiliária
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Recebe comissão"
                  name="with_commission"
                  rules={[
                    { required: true, message: 'Informe se recebe comissão' }
                  ]}
                >
                  <Select>
                    <Select.Option value={1}>Sim</Select.Option>
                    <Select.Option value={0}>Não</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Comissão"
                  name="commission"
                  rules={[
                    {
                      required: true,
                      message: 'Informe a quantidade de quartos'
                    }
                  ]}
                >
                  <InputNumber />
                </Form.Item>
                <Form.Item
                  label="Preço atual"
                  name="current_price"
                  rules={[
                    {
                      required: true,
                      message: 'Informe o preço atual de cobrança'
                    }
                  ]}
                >
                  <InputNumber />
                </Form.Item>
                <Form.Item
                  label="Dia do Pagamento"
                  name="transfer_date"
                  rules={[
                    { required: true, message: 'Informe o dia do pagamento' }
                  ]}
                >
                  <InputNumber />
                </Form.Item>
                <Form.Item
                  label="Próximo reajuste"
                  name="next_readjustment"
                  rules={[
                    {
                      required: true,
                      message: 'Informe a data do próximo reajuste'
                    }
                  ]}
                >
                  <DatePicker />
                </Form.Item>
                <Form.Item label="Data inicial" name="start_date">
                  <DatePicker />
                </Form.Item>
                <Form.Item
                  label="Data final"
                  name="end_date"
                  rules={[
                    {
                      required: true,
                      message: 'Informe a data final do contrato'
                    }
                  ]}
                >
                  <DatePicker />
                </Form.Item>
                <hr />
                <h5 className="text-center font-weight-light">Relação</h5>
                <Form.Item
                  label="Inquilino"
                  name="user_id"
                  rules={[{ required: true, message: 'Informe o inquilino' }]}
                >
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {users.map(user => (
                      <Select.Option key={`user-${user.id}`} value={user.id}>
                        [Cod {user.id}] {user.name} {user.cpf_cpnj}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Imóvel"
                  name="property_id"
                  rules={[{ required: true, message: 'Informe o imóvel' }]}
                >
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {properties.map(property => (
                      <Select.Option
                        key={`property-${property.id}`}
                        value={property.id}
                      >
                        [Cod {property.id}]{' '}
                        {propertyTranslate.location(property.location)}{' '}
                        {property.street}, {property.number} -{' '}
                        {propertyTranslate.type(property.type)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <hr />
                <Form.Item {...tailLayout}>
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    loading={updating}
                    disabled={updating}
                  >
                    Salvar alterações
                  </Button>
                </Form.Item>
              </Form>
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Fiadores" key="2">
            <Form
              layout="inline"
              form={guarantorForm}
              onFinish={async values => {
                message.loading('Aguarde...')

                try {
                  await api.put(`/proposal/${id}/guarantor`, values)

                  message.success('Fiador adicionado com sucesso!')
                  getData()
                } catch {
                  message.error('Não foi possível adicionar o fiador')
                }
              }}
            >
              <Form.Item
                label="Informe o fiador"
                name="user_id"
                rules={[{ required: true, message: 'Informe o fiador' }]}
              >
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  style={{
                    width: 420
                  }}
                >
                  {users.map(user => (
                    <Select.Option key={`user-${user.id}`} value={user.id}>
                      [Cod {user.id}] {user.name} {user.cpf_cpnj}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Adicionar
                </Button>
              </Form.Item>
            </Form>
            <Table
              columns={guarantorsColumns}
              dataSource={
                data && data.guarantors.map(el => ({ key: el.id, ...el }))
              }
              locale={{
                emptyText: 'Não há fiadores vinculados a este contrato'
              }}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Documentos" key="3">
            <Button onClick={() => setModalOpened(true)}>
              Fazer upload de arquivos
            </Button>
            <br />
            <br />
            <Table
              columns={documentColumns}
              dataSource={
                data && data.documents.map(el => ({ key: el.id, ...el }))
              }
              locale={{
                emptyText: 'Não há documentos vinculados a este contrato'
              }}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Faturas" key="4">
            <Table
              columns={invoiceColumns}
              dataSource={
                data && data.invoices.map(el => ({ key: el.id, ...el }))
              }
              locale={{
                emptyText: 'Não há faturas vinculadas a este contrato'
              }}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Transferências" key="5">
            <Table
              columns={transfersColumns}
              dataSource={
                data && data.transfers.map(el => ({ key: el.id, ...el }))
              }
              locale={{
                emptyText: 'Não há transferências vinculadas a este contrato'
              }}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Deduções e Acréscimos" key="6">
            <Form form={extraForm} layout="inline" onFinish={submitExtra}>
              <Form.Item
                name="description"
                label="Descrição"
                rules={[{ required: true, message: 'Informe a descrição' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="amount"
                label="Valor"
                rules={[{ required: true, message: 'Informe o valor' }]}
              >
                <InputNumber />
              </Form.Item>
              <Form.Item
                name="type"
                label="Tipo"
                rules={[{ required: true, message: 'Informe o tipo' }]}
              >
                <Select style={{ minWidth: 80 }}>
                  <Select.Option value="CREDIT">Crédito</Select.Option>
                  <Select.Option value="DEBIT">Débito</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="boleto" label="No boleto?">
                <Select style={{ minWidth: 80 }}>
                  <Select.Option value="true">Sim</Select.Option>
                  <Select.Option value="false">Não</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="influence"
                label="Influencia na comissão"
                rules={[{ required: true, message: 'Informe abaixo' }]}
              >
                <Select style={{ minWidth: 80 }}>
                  <Select.Option value="EXPENSE">Não</Select.Option>
                  <Select.Option value="RENT">Sim</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="transfer" label="Incluir no repasse">
                <Select style={{ minWidth: 80 }}>
                  <Select.Option value="true">Sim</Select.Option>
                  <Select.Option value="false">Não</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Salvar
                </Button>
              </Form.Item>
            </Form>
            <br />
            <Table
              columns={extraColumns}
              dataSource={
                data && data.extraValues.map(el => ({ key: el.id, ...el }))
              }
              locale={{
                emptyText:
                  'Não há deduções ou acréscimos vinculados a este contrato'
              }}
            />
          </Tabs.TabPane>
        </Tabs>
        <Modal
          visible={modalOpened}
          title="Upload de arquivos"
          onOk={() => {
            getData()
            setModalOpened(false)
          }}
          okText="Fechar e atualizar"
          cancelText="Cancelar"
          onCancel={() => setModalOpened(false)}
        >
          <Select
            onChange={setFileType}
            style={{ width: '100%' }}
            value={fileType}
            placeholder="Selecione o tipo"
          >
            <Select.Option value="RG_FRONT">Frente do RG</Select.Option>
            <Select.Option value="RG_BACK">Verso do RG</Select.Option>
            <Select.Option value="MARITAL_CERTIFICATE">
              Certificado de Casamento
            </Select.Option>
            <Select.Option value="PROOF_OF_INCOME">
              Comprovante de Renda
            </Select.Option>
            <Select.Option value="GUARANTOR">Dados do Fiador</Select.Option>
            <Select.Option value="CONTRACT">Contrato</Select.Option>
            <Select.Option value="OTHER">Outro</Select.Option>
          </Select>
          <br />
          {fileType !== '' && (
            <Upload.Dragger
              multiple={false}
              name="file"
              action={`${
                process.env.REACT_APP_ENDPOINT_API ||
                'https://core-api.advqxs.easypanel.host'
              }/proposal/${id}/${fileType}`}
              method="PUT"
              headers={{
                Authorization: `Bearer ${getToken()}`
              }}
              onChange={info => {
                const { status } = info.file

                if (status === 'done') {
                  message.success('Arquivo enviado!')
                }
              }}
            >
              <p className="ant-upload-text">
                Clique ou arraste para fazer upload
              </p>
              <p className="ant-upload-hint">Suporta imagens ou PDF</p>
            </Upload.Dragger>
          )}
        </Modal>
      </Card>
    </AppLayout>
  )
}

export default ContractsInfo
