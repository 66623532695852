/* eslint-disable react/prop-types */
import { useDisclosure } from '@chakra-ui/react'
import {
  Table,
  Card,
  Tabs,
  Form,
  Select,
  DatePicker,
  Button,
  Menu,
  Dropdown,
  Input,
  Row,
  Col,
  Popconfirm,
  Statistic,
  message,
  Tag
} from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, { useState, useEffect } from 'react'
import InputMask from 'react-input-mask'
import { Link, useParams, useHistory } from 'react-router-dom'

import TransferModal from '../../components/Transfer/Modal'
import propertiesTranslate from '../../helpers/translate/properties'
import AppLayout from '../../layouts/AppLayout'
import api from '../../services/api'

const { TabPane } = Tabs

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 14 }
}

const tailLayout = {
  wrapperCol: { offset: 5, span: 14 }
}

function ClientsInfo() {
  const history = useHistory()

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({
    user: null,
    address: null,
    information: null,
    bank: null,
    properties: [],
    proposals: [],
    invoices: [],
    transfers: []
  })

  const [transferSelected, setTransferSelected] = useState()

  const [formInfo] = Form.useForm()
  const [formAddress] = Form.useForm()

  const [marital, setMarital] = useState('')
  const [personType, setPersonType] = useState('')

  const { isOpen, onOpen, onClose } = useDisclosure()
  const { id } = useParams()

  useEffect(() => {
    getClient()
    // eslint-disable-next-line
  }, [id])

  async function getClient() {
    setLoading(true)

    try {
      const { data } = await api.get(`/user/${id}`)

      if (data.information) {
        data.information.birth_date = moment(data.information.birth_date)
      }

      setMarital(data.information ? data.information.marital_status : '')
      setPersonType(data.information ? data.information.person_type : '')

      setData(data)
    } catch (err) {
      console.log(err)
      message.error('Ocorreu um erro ao buscar os dados')
    }

    setLoading(false)
  }

  async function deleteUser() {
    message.loading('Aguarde...')

    try {
      await api.delete(`/user/${id}/admin`)

      message.success('Usuário deletado')
      history.push('/clients')
    } catch (err) {
      message.error('Não foi possível deletar o usuário')
    }
  }

  const propertiesColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Endereço',
      dataIndex: 'address',
      key: 'address'
    },
    {
      title: 'Transação',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: 'Tipo',
      dataIndex: 'location',
      key: 'location'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status'
    },
    {
      title: 'Relação',
      dataIndex: 'relationship',
      key: 'relationship'
    },
    {
      title: 'Mais detalhes',
      render: (text, record) => {
        return <Link to={`/properties/info/${record.id}`}>Abrir imóvel</Link>
      }
    }
  ]

  const proposalsColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: text => <Link to={`/contracts/info/${text}`}>{text}</Link>
    },
    {
      title: 'Imóvel',
      dataIndex: 'property_id',
      key: 'property_id',
      render: text => <Link to={`/properties/info/${text}`}>{text}</Link>
    },
    {
      title: 'Preço',
      dataIndex: 'current_price',
      key: 'current_price'
    },
    {
      title: 'Data Inicial',
      dataIndex: 'start_date',
      key: 'start_date'
    },
    {
      title: 'Data Final',
      dataIndex: 'end_date',
      key: 'end_date'
    }
  ]

  const invoicesColumns = [
    {
      title: 'Fatura',
      dataIndex: 'id',
      key: 'id',
      render: text => `#${text}`
    },
    {
      title: 'Contrato',
      dataIndex: 'proposal_id',
      key: 'proposal_id',
      render: text => <Link to={`/contracts/info/${text}`}>{text}</Link>
    },
    {
      title: 'Valor',
      dataIndex: 'price',
      key: 'price',
      render: text => `R$ ${parseFloat(text).toLocaleString('pt-BR')}`
    },
    {
      title: 'Honorários',
      dataIndex: 'fees',
      key: 'fees',
      render: text => `R$ ${parseFloat(text || 0).toLocaleString('pt-BR')}`
    },
    {
      title: 'Vencimento',
      dataIndex: 'due_date',
      key: 'due_date',
      render: text => new Date(text).toLocaleDateString('pt-BR')
    },
    {
      title: 'Data do Pagamento',
      dataIndex: 'pay_day',
      key: 'pay_day',
      render: text =>
        text ? new Date(text).toLocaleDateString('pt-BR') : '-/-'
    },
    {
      title: 'Situação',
      key: 'status',
      dataIndex: 'status',
      render: (status, record) => (
        <Tag color={record.color}>{status.toUpperCase()}</Tag>
      )
    }
  ]

  const transfersColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'ID Contrato',
      dataIndex: 'proposal_id',
      key: 'proposal_id',
      render: text => <Link to={`/contracts/info/${text}`}>{text}</Link>
    },
    {
      title: 'ID Imóvel',
      dataIndex: 'property_id',
      key: 'property_id',
      render: text => <Link to={`/properties/info/${text}`}>{text}</Link>
    },
    {
      title: 'Valor',
      dataIndex: 'amount',
      key: 'amount',
      render: text => `R$ ${parseFloat(text).toLocaleString('pt-BR')}`
    },
    {
      title: 'Previsão de depósito',
      dataIndex: 'due_date',
      key: 'due_date',
      render: text => new Date(text).toLocaleDateString('pt-BR')
    },
    {
      title: 'Recibo',
      render: (_text, record) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${
            process.env.REACT_APP_ENDPOINT_API ||
            'https://core-api.advqxs.easypanel.host'
          }/financial/${record.id}/receipt`}
        >
          Visualizar recibo
        </a>
      )
    },
    {
      title: 'Situação',
      key: 'status',
      dataIndex: 'status',
      render: (status, record) => (
        <Tag color={record.color}>{status.toUpperCase()}</Tag>
      )
    },
    {
      title: 'Detalhes',
      key: 'details',
      render: (status, record) => (
        <Button
          onClick={() => {
            setTransferSelected(record.id)
            onOpen()
          }}
        >
          Abrir
        </Button>
      )
    }
  ]

  async function update(values, model) {
    message.loading('Aguarde...')

    if (model === 'information') {
      values = {
        ...values,
        birth_date: moment(values.birth_date).format('YYYY-MM-DD')
      }
    }

    try {
      await api.patch(`/user/${id}/${model}`, values)

      message.success('Dados atualizados!')
    } catch (err) {
      message.error('Ocorreu um erro ao atualizar os dados do usuário')
    }
  }

  async function updateColumn(column, value) {
    message.loading('Aguarde...')

    try {
      await api.patch(`/user/${id}/update/${column}`, { value })

      message.success('Dados atualizados!')
    } catch (err) {
      message.error('Ocorreu um erro ao atualizar os dados do usuário')
    }
  }

  const EnterInput = ({ defaultValue, column }) => {
    const [value, setValue] = useState(defaultValue)

    return (
      <Input
        value={value}
        style={{ width: 250, marginLeft: 15, padding: 3 }}
        onChange={e => setValue(e.target.value)}
        onKeyDown={e => e.key === 'Enter' && updateColumn(column, value)}
      />
    )
  }

  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          updateColumn('type', 'TENANT')
          setData({
            ...data,
            user: {
              ...data.user,
              type: 'TENANT'
            }
          })
        }}
      >
        Inquilino
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          updateColumn('type', 'OWNER')
          setData({
            ...data,
            user: {
              ...data.user,
              type: 'OWNER'
            }
          })
        }}
      >
        Proprietário
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          updateColumn('type', 'GUARANTOR')
          setData({
            ...data,
            user: {
              ...data.user,
              type: 'GUARANTOR'
            }
          })
        }}
      >
        Fiador
      </Menu.Item>
    </Menu>
  )

  return (
    <AppLayout title="Clientes" keyPage="7">
      <TransferModal
        id={transferSelected}
        isOpen={isOpen}
        onClose={onClose}
        onChange={() => {
          setTransferSelected(null)
        }}
      />
      <Card
        title="Informações do Cliente"
        loading={loading}
        extra={
          <Popconfirm
            title="Tem certeza que deseja excluir este contrato?"
            onConfirm={deleteUser}
            okText="Sim, tenho certeza"
            cancelText="Não"
          >
            <Button type="danger" size="small">
              Deletar cadastro
            </Button>
          </Popconfirm>
        }
      >
        {!data.user ? (
          <p>Recuperando dados...</p>
        ) : (
          <Row gutter={25}>
            <Col md={8}>
              <p>
                <b>Nome:</b>{' '}
                <EnterInput defaultValue={data.user.name} column="name" />
              </p>
              <p>
                <b>E-mail:</b>{' '}
                <EnterInput defaultValue={data.user.email} column="email" />
              </p>
              <p>
                <b>CPF/CNPJ:</b>{' '}
                <EnterInput
                  defaultValue={data.user.cpf_cnpj}
                  column="cpf_cnpj"
                />
              </p>
              <p>
                <b>Telefone:</b>{' '}
                <EnterInput defaultValue={data.user.phone} column="phone" />
              </p>
              <p>
                <b>Data de cadastro:</b>{' '}
                {new Date(data.user.created_at).toLocaleString('pt-BR')}
              </p>
            </Col>
            <Col md={4} />
            <Col md={4}>
              <Statistic
                title="Tipo"
                value={
                  data.user.type === 'TENANT'
                    ? 'Inquilino'
                    : data.user.type === 'OWNER'
                    ? 'Proprietário'
                    : 'Fiador'
                }
              />
              <Dropdown overlay={menu} placement="bottomCenter">
                <Button>Alterar tipo</Button>
              </Dropdown>
            </Col>
            <Col md={4}>
              <Statistic title="Faturas" prefix="R$" value={0.0} />
            </Col>
            <Col md={4}>
              <Statistic title="Mensagens" value={0} />
            </Col>
          </Row>
        )}
      </Card>
      <br />
      <Card loading={loading}>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Mais detalhes" key="1">
            <Form
              {...layout}
              name="informations"
              form={formInfo}
              initialValues={data.information}
              onFinish={values => update(values, 'information')}
              autoComplete="off"
              onValuesChange={e => {
                if (e.marital_status) {
                  setMarital(e.marital_status)
                }
                if (e.person_type) {
                  setPersonType(e.person_type)
                }
              }}
              scrollToFirstError
            >
              <Form.Item
                label="Data de Nascimento"
                name="birth_date"
                rules={[
                  { required: true, message: 'Informe a data de nascimento' }
                ]}
              >
                <DatePicker />
              </Form.Item>
              <Form.Item
                label="RG"
                name="rg"
                rules={[{ required: true, message: 'Informe o RG' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Pessoa"
                name="person_type"
                rules={[
                  { required: true, message: 'Informe o tipo de pessoa' }
                ]}
              >
                <Select>
                  <Select.Option value="FISICA">Pessoa Física</Select.Option>
                  <Select.Option value="JURIDICA">
                    Pessoa Juridica
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item label="Profissão" name="profession">
                <Input />
              </Form.Item>
              {personType !== 'JURIDICA' && (
                <Form.Item
                  label="Estado Civil"
                  name="marital_status"
                  rules={[
                    { required: true, message: 'Informe o estado civil' }
                  ]}
                >
                  <Select>
                    <Select.Option value="SOLTEIRO">Solteiro(a)</Select.Option>
                    <Select.Option value="CASADO">Casado(a)</Select.Option>
                    <Select.Option value="DIVORCIADO">
                      Divorciado(a)
                    </Select.Option>
                    <Select.Option value="VIUVO">Viúvo(a)</Select.Option>
                    <Select.Option value="SEPARADO">Separado(a)</Select.Option>
                  </Select>
                </Form.Item>
              )}
              {(marital === 'CASADO' || personType === 'JURIDICA') && (
                <>
                  <hr />
                  <h5 className="text-center font-weight-light">
                    Dados do{' '}
                    {data.information && personType === 'FISICA'
                      ? 'cônjugue'
                      : 'sócio'}
                  </h5>
                  <Form.Item label="Nome completo" name="spounce_name">
                    <Input />
                  </Form.Item>
                  <Form.Item label="RG" name="spounce_rg">
                    <Input />
                  </Form.Item>
                  <Form.Item label="CPF" name="spounce_cpf">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Profissão" name="spouse_profession">
                    <Input />
                  </Form.Item>
                  <hr />
                </>
              )}
              <Form.Item {...tailLayout}>
                <Button size="large" type="primary" htmlType="submit">
                  Salvar
                </Button>
              </Form.Item>
            </Form>
          </TabPane>
          <TabPane tab="Endereço" key="2">
            <Form
              {...layout}
              name="address"
              form={formAddress}
              initialValues={data.address}
              onFinish={values => update(values, 'address')}
              autoComplete="off"
              scrollToFirstError
            >
              <Form.Item
                label="CEP"
                name="cep"
                rules={[{ required: true, message: 'Informe o CEP' }]}
              >
                <InputMask
                  mask="99.999-999"
                  onChange={async e => {
                    if (!e.target.value.includes('_')) {
                      var unformatted = e.target.value

                      unformatted = unformatted
                        .split('.')
                        .join('')
                        .split('-')
                        .join('')

                      const { data } = await axios.get(
                        `https://viacep.com.br/ws/${unformatted}/json/`
                      )

                      formAddress.setFieldsValue({
                        street: data.logradouro,
                        neighborhood: data.bairro,
                        city: data.localidade,
                        state: data.uf,
                        latitude: '',
                        longitude: ''
                      })

                      formAddress.scrollToField('number')
                    }
                  }}
                >
                  {inputProps => <Input {...inputProps} />}
                </InputMask>
              </Form.Item>
              <Form.Item label="Logradouro" name="street">
                <Input />
              </Form.Item>
              <Form.Item label="Bairro" name="neighborhood">
                <Input />
              </Form.Item>
              <Form.Item
                label="Cidade"
                name="city"
                rules={[{ required: true, message: 'Informe a Cidade' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Estado"
                name="state"
                rules={[{ required: true, message: 'Informe o Estado' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Número"
                name="number"
                rules={[{ required: true, message: 'Informe o Número' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item label="Complemento" name="complement">
                <Input />
              </Form.Item>
              <Form.Item {...tailLayout}>
                <Button size="large" type="primary" htmlType="submit">
                  Salvar
                </Button>
              </Form.Item>
            </Form>
          </TabPane>
          <TabPane tab="Imóveis" key="3">
            <Table
              columns={propertiesColumns}
              dataSource={data.properties.map(el => ({
                ...el,
                address: `${el.street}, n° ${el.number}${
                  el.complement && ` / ${el.complement}`
                }, ${el.neighborhood} - ${el.city}`,
                status: propertiesTranslate.status(el.status),
                location: propertiesTranslate.location(el.location),
                type: propertiesTranslate.type(el.type),
                relationship:
                  el.pivot.relationship === 'TENANT'
                    ? 'Inquilino'
                    : 'Proprietário'
              }))}
              locale={{
                emptyText: 'Não há propriedades vinculadas a este cliente'
              }}
            />
          </TabPane>
          <TabPane tab="Contratos" key="4">
            <Table
              columns={proposalsColumns}
              dataSource={data.proposals.map(el => ({
                ...el,
                key: el.id
              }))}
              locale={{
                emptyText: 'Não há contratos vinculados a este cliente'
              }}
            />
          </TabPane>
          <TabPane tab="Faturas" key="7">
            <Table
              columns={invoicesColumns}
              dataSource={data.invoices.map(el => ({
                ...el,
                key: el.id,
                status:
                  el.status === 'PAID'
                    ? 'Pago'
                    : new Date().getTime() > new Date(el.due_date).getTime()
                    ? 'Atrasado'
                    : 'Pendente',
                color:
                  el.status === 'PAID'
                    ? 'green'
                    : el.status === 'PENDING'
                    ? 'gold'
                    : 'red'
              }))}
              locale={{ emptyText: 'Não há faturas vinculadas a este cliente' }}
            />
          </TabPane>
          <TabPane tab="Repasses" key="8">
            <Table
              columns={transfersColumns}
              dataSource={data.transfers.map(el => ({
                ...el,
                key: el.id,
                status: el.status === 'DONE' ? 'Efetuado' : 'Pendente',
                color: el.status === 'DONE' ? 'green' : 'gold'
              }))}
              locale={{
                emptyText: 'Não há transferências vinculadas a este cliente'
              }}
            />
          </TabPane>
        </Tabs>
      </Card>
    </AppLayout>
  )
}

export default ClientsInfo
