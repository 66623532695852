import { ArrowDownOutlined, BarcodeOutlined } from '@ant-design/icons'
import {
  Table,
  Row,
  Col,
  Card,
  Statistic,
  Button,
  Popconfirm,
  message
} from 'antd'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

import AppLayout from '../../layouts/AppLayout'
import api from '../../services/api'

function FinancialShall() {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Inquilino',
      render: (text, record) => (
        <Link to={`/clients/info/${record.user.id}`}>{record.user.name}</Link>
      )
    },
    {
      title: 'Contrato',
      render: (text, record) => (
        <Link to={`/contract/info/${record.proposal.id}`}>
          {record.proposal.id}
        </Link>
      )
    },
    {
      title: 'Valor',
      dataIndex: 'price',
      key: 'price',
      render: text =>
        'R$' +
        parseFloat(text).toLocaleString('pt-BR', { minimumFractionDigits: 2 })
    },
    {
      title: 'Taxas',
      dataIndex: 'fees',
      key: 'fees',
      render: text =>
        'R$' +
        (text > 0
          ? parseFloat(text).toLocaleString('pt-BR', {
              minimumFractionDigits: 2
            })
          : '0,00')
    },
    {
      title: 'Referência',
      dataIndex: 'reference',
      key: 'reference'
    },
    {
      title: 'Atrasado desde',
      dataIndex: 'due_date',
      key: 'due_date',
      render: text => new Date(text).toLocaleDateString()
    },
    {
      title: 'Ações',
      render: (text, record) => (
        <>
          <Popconfirm
            placement="top"
            title="Deseja emitir o boleto?"
            okText="Sim"
            cancelText="Não"
            onConfirm={() => {
              window.open(
                `${
                  process.env.REACT_APP_ENDPOINT_API ||
                  'https://core-api.advqxs.easypanel.host'
                }/invoice/${uuidv4()}/${record.id}/${uuidv4()}`,
                '_blank'
              )
            }}
          >
            <Button type="dashed" style={{ marginRight: 10 }}>
              <BarcodeOutlined />
            </Button>
          </Popconfirm>
          {/* {record.user.email !== '-' && (
            <Button type="ghost" style={{ marginRight: 10 }}>Enviar notificação</Button>
          )} */}
          <Popconfirm
            placement="top"
            title="Tem certeza?"
            okText="Sim, foi pago"
            cancelText="Não"
            onConfirm={() => manual(record.id)}
          >
            <Button type="primary">Baixa manual</Button>
          </Popconfirm>
        </>
      )
    }
  ]

  React.useEffect(() => {
    getData()
  }, [])

  async function manual(id) {
    try {
      await api.patch(`/financial/shall/${id}/manual`)

      getData()
    } catch (err) {
      message.error('Ocorreu um problema ao tentar efetuar baixa manual')
    }
  }

  async function getData() {
    setLoading(true)

    try {
      const { data: resultData } = await api.get('/financial/late')

      setData(resultData)

      console.log(resultData)
    } catch (err) {
      console.log(err)

      message.error('Ocorreu um erro ao tentar recuperar dados')
    }

    setLoading(false)
  }

  return (
    <AppLayout title="Inadimplências" keyPage="4" subKeyPage="sub1">
      <Row gutter={25}>
        <Col md={12}>
          <Card loading={loading}>
            <Statistic
              title="Valor devedor"
              value={
                data &&
                data.amount.toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })
              }
              precision={2}
              valueStyle={{ color: '#e67e22' }}
              prefix={<ArrowDownOutlined />}
            />
          </Card>
        </Col>
        <Col md={12}>
          <Card loading={loading}>
            <Statistic
              title="Pagamentos atrasados"
              value={data && data.total}
              precision={0}
              valueStyle={{ color: '#e84118' }}
            />
          </Card>
        </Col>
      </Row>
      <br />
      <Card title="Pagamento atrasados">
        {!loading && (
          <Table
            columns={columns}
            dataSource={data && data.lates.map(el => ({ key: el.id, ...el }))}
            loading={loading}
          />
        )}
      </Card>
    </AppLayout>
  )
}

export default FinancialShall
