import {
  FormControl,
  FormLabel,
  Input,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  Grid,
  Select,
  useToast
} from '@chakra-ui/react'
import React, { useState } from 'react'
import InputMask from 'react-input-mask'

import api from '../../services/api'

function ClientCreate({ isOpen, onClose, onCreate }) {
  const toast = useToast()

  const [loading, setLoading] = useState(false)

  const [name, setName] = useState()
  const [email, setEmail] = useState()
  const [phone, setPhone] = useState()
  const [cellphone, setCellphone] = useState()
  const [document, setDocument] = useState()
  const [personType, setPersonType] = useState('PF')
  const [type, setType] = useState('')

  async function create() {
    setLoading(true)

    try {
      const { data } = await api.post('user/manual', {
        name,
        email,
        phone: phone || cellphone,
        cpf_cnpj: document,
        type
      })

      if (onCreate) {
        onCreate(data)
      }

      setName('')
      setEmail('')
      setPhone('')
      setDocument('')
      setPersonType('CPF')

      onClose()
    } catch (err) {
      const { message } = err.response.data

      toast({
        title: 'Oopss...',
        description: message,
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }

    setLoading(false)
  }

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Novo cliente</DrawerHeader>
          <DrawerBody>
            <FormControl id="name" mb={3} isRequired>
              <FormLabel>Nome Completo</FormLabel>
              <Input value={name} onChange={e => setName(e.target.value)} />
            </FormControl>
            <Grid templateColumns="180px auto" gap="15px" mb={3}>
              <FormControl id="birthDate" isRequired>
                <FormLabel>Documento</FormLabel>
                <Select
                  size="lg"
                  value={personType}
                  onChange={e => setPersonType(e.target.value)}
                >
                  <option value="PF">Pessoa Física</option>
                  <option value="PJ">Pessoa Jurídica</option>
                </Select>
              </FormControl>
              <FormControl id="document" isRequired>
                <FormLabel>Documento</FormLabel>
                <InputMask
                  value={document}
                  onChange={e => setDocument(e.target.value)}
                  mask={
                    personType === 'PF'
                      ? '999.999.999-99'
                      : '99.999.999/9999-99'
                  }
                >
                  {inputProps => <Input {...inputProps} />}
                </InputMask>
              </FormControl>
            </Grid>
            <FormControl id="phone" isRequired={!cellphone} mb={3}>
              <FormLabel>Telefone</FormLabel>
              <InputMask
                mask="(99) 99999-9999"
                value={phone}
                onChange={e => setPhone(e.target.value)}
              >
                {inputProps => <Input {...inputProps} />}
              </InputMask>
            </FormControl>
            <FormControl id="phone" isRequired={!phone} mb={3}>
              <FormLabel>Celular</FormLabel>
              <InputMask
                mask="(99) 99999-9999"
                value={cellphone}
                onChange={e => setCellphone(e.target.value)}
              >
                {inputProps => <Input {...inputProps} />}
              </InputMask>
            </FormControl>
            <FormControl id="email" mb={3}>
              <FormLabel>E-mail</FormLabel>
              <Input
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl id="type" isRequired>
              <FormLabel>Tipo de cliente</FormLabel>
              <Select
                size="lg"
                value={type}
                onChange={e => setType(e.target.value)}
              >
                <option value="TENANT">Inquilino</option>
                <option value="OWNER">Proprietário</option>
                <option value="GUARANTOR">Fiador</option>
              </Select>
            </FormControl>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              bg="orange.400"
              color="white"
              isLoading={loading}
              disabled={loading}
              onClick={create}
              _hover={{ bg: 'orange.600' }}
            >
              Criar novo cliente
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  )
}

export default ClientCreate
