import {
  Grid,
  Center,
  Spinner,
  useToast,
  useDisclosure
} from '@chakra-ui/react'
import React, { useState, useEffect, useCallback } from 'react'
import Paginate from 'react-paginate'

import PropertyFilters from '../../components/Property/Filters'
import PropertyModal from '../../components/Property/Modal'
import PropertyPreview from '../../components/Property/Preview'
import AppLayout from '../../layouts/AppLayout'
import api from '../../services/api'

function Properties() {
  const toast = useToast()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [id, setId] = useState()
  const [query, setQuery] = useState()
  const [transaction, setTransaction] = useState()
  const [category, setCategory] = useState()
  const [available, setAvailable] = useState('ALL')
  const [order, setOrder] = useState('id:desc')

  const [loading, setLoading] = useState(false)

  const [selected, setSelected] = useState()

  const [data, setData] = useState()
  const [meta, setMeta] = useState()

  const getData = useCallback(
    async (page = 1) => {
      setLoading(true)

      try {
        const {
          data: { data, lastPage, page: currentPage }
        } = await api.get('property', {
          params: {
            page,
            id,
            query: encodeURIComponent(query),
            transaction,
            available,
            order,
            category
          }
        })

        setData(data)
        setMeta({
          last_page: lastPage,
          current_page: currentPage
        })
      } catch {
        toast({
          title: 'Oopss...',
          description: 'Ocorreu um erro ao tentar buscar pelas propriedades',
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      }

      setLoading(false)
    },
    [id, query, transaction, available, order, toast, category]
  )

  useEffect(() => {
    getData()
  }, [getData, id, query, transaction, available, order, category])

  return (
    <AppLayout title="Imóveis" keyPage="8" subKeyPage="sub2">
      <PropertyFilters
        onChange={({ id, query, transaction, available, category, order }) => {
          if (id) {
            setId(id)
          }

          if (query) {
            setQuery(query)
          }

          if (available) {
            setAvailable(available)
          }

          if (transaction) {
            setTransaction(transaction)
          }

          if (category) {
            setCategory(category)
          }

          if (order) {
            setOrder(order)
          }
        }}
        onCreate={data => {
          setSelected(data.id)
          onOpen()
        }}
      />
      <PropertyModal
        isOpen={isOpen}
        onClose={() => {
          onClose()
          getData()
        }}
        id={selected}
      />
      <Grid
        templateColumns={['100%', 'repeat(2, 1fr)', 'repeat(4, 1fr)']}
        gap="20px"
      >
        {data &&
          data.map(property => (
            <PropertyPreview
              key={property.id}
              data={property}
              withStatus
              selectable
              onSelect={() => {
                setSelected(property.id)
                onOpen()
              }}
            />
          ))}
      </Grid>
      {loading && (
        <Center>
          <Spinner />
        </Center>
      )}
      {data && data.length > 0 && (
        <Center>
          <Paginate
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            pageCount={meta && meta.last_page}
            forcePage={meta && meta.current_page - 1}
            pageRangeDisplayed={5}
            marginPagesDisplayed={1}
            previousLabel={'Página anterior'}
            nextLabel={'Próxima página'}
            onPageChange={({ selected }) => {
              getData(selected + 1)
            }}
          />
        </Center>
      )}
    </AppLayout>
  )
}

export default Properties
