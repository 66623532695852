import { Modal, ModalOverlay } from '@chakra-ui/react'
import React from 'react'

import PropertyProvider from '../../providers/PropertyProvider'
import PropertyModalContent from './ModalContent'

function PropertyModal({ isOpen, onClose, id }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <PropertyProvider id={id} onCloseModal={onClose}>
        <PropertyModalContent onClose={onClose} />
      </PropertyProvider>
    </Modal>
  )
}

export default PropertyModal
