import React, { createContext } from 'react'
import { useImmer } from 'use-immer'

function PropertiesProvider({ children }) {
  const [selected, updateSelected] = useImmer([])

  function selectToShare(data) {
    if (selected.find(item => item.id === data.id)) {
      updateSelected(draft => {
        return draft.filter(item => item.id !== data.id)
      })
    } else {
      updateSelected(draft => {
        return draft.concat(data)
      })
    }
  }

  function clearAll() {
    updateSelected(draft => {
      return draft.filter(item => item.id === 'delete')
    })
  }

  return (
    <PropertiesContext.Provider
      value={{
        selected,
        selectToShare,
        clearAll
      }}
    >
      {children}
    </PropertiesContext.Provider>
  )
}

export const PropertiesContext = createContext({})
export default PropertiesProvider
