import { Box, Progress, Flex, Heading, Tooltip } from '@chakra-ui/react'
import {
  Card,
  Button,
  Select,
  Switch,
  Input,
  Radio,
  Form,
  Modal,
  InputNumber,
  message
} from 'antd'
import MaskedInput from 'antd-mask-input'
import axios from 'axios'
import React, { useState, useEffect, useRef } from 'react'
import InputMask from 'react-input-mask'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'
import { useHistory } from 'react-router-dom'

import AppLayout from '../../layouts/AppLayout'
import api from '../../services/api'

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 14 }
}

const tailLayout = {
  wrapperCol: { offset: 5, span: 14 }
}

const types = [
  ['SALE', 'Venda'],
  ['RENT', 'Aluguel'],
  ['PRE_SALE', 'Pré Venda'],
  ['EXCHANGE', 'Permuta']
]

const locations = [
  ['APARTMENT', 'Apartamento'],
  ['ROOF', 'Cobertura'],
  ['HOUSE', 'Casa'],
  ['FARM', 'Fazenda'],
  ['CLINIC', 'Consultório'],
  ['SHED', 'Galpão'],
  ['GARAGE', 'Garagem'],
  ['KITNET', 'Kitnet'],
  ['LOT', 'Lote'],
  ['STORE', 'Loja'],
  ['COMERCIAL', 'Sala Comercial'],
  ['SITE', 'Sítio']
]

function PropertiesRegister() {
  var numberRef = useRef(null)

  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)
  const [exterior, setExterior] = useState(false)

  const [clientModal, setClientModal] = useState(false)
  const [tmpAddress, setTmpAddress] = useState('')

  const history = useHistory()

  const [form] = Form.useForm()
  const [formClient] = Form.useForm()

  const [clientLoading, setClientLoading] = useState(false)

  const [document, setDocument] = useState('CPF')
  const [emailRequired, setEmailRequired] = useState(true)

  useEffect(() => {
    getUsers()
  }, [])

  async function getUsers() {
    const { data } = await api.get('/user/owners').catch(() => {
      message.error('Não foi possível buscar os proprietários')
    })

    setUsers(data)

    return data
  }

  function getAddressData(object, name) {
    const data = object.address_components
    const item = data.find(el => el.types.includes(name))

    return item ? item.long_name : null
  }

  async function handleAddress(address) {
    const place = await geocodeByAddress(address)

    console.log(place[0])

    form.setFieldsValue({
      cep: getAddressData(place[0], 'postal_code'),
      street: getAddressData(place[0], 'route'),
      neighborhood: getAddressData(place[0], 'sublocality'),
      city: getAddressData(place[0], 'administrative_area_level_2'),
      state: getAddressData(place[0], 'administrative_area_level_1'),
      country: getAddressData(place[0], 'country'),
      number: getAddressData(place[0], 'street_number')
    })

    if (place[0].geometry.bounds) {
      if (place[0].geometry.bounds.Sa) {
        form.setFieldsValue({ latitude: place[0].geometry.bounds.Sa.g })
      } else {
        form.setFieldsValue({ latitude: place[0].geometry.bounds.Ra.g })
      }
      form.setFieldsValue({ longitude: place[0].geometry.bounds.La.g })
    }

    if (place[0].geometry.viewport) {
      if (place[0].geometry.viewport.Sa) {
        form.setFieldsValue({ latitude: place[0].geometry.viewport.Sa.g })
      } else {
        form.setFieldsValue({ latitude: place[0].geometry.viewport.Ra.g })
      }
      form.setFieldsValue({ longitude: place[0].geometry.viewport.La.g })
    }

    setTmpAddress(place[0].formatted_address)
  }

  async function onSubmit(values) {
    setLoading(true)

    try {
      const {
        data: { id }
      } = await api.post('/property', values)

      history.push(`/properties/info/${id}`)
    } catch (err) {
      message.error(
        err.response.data[0].message ||
          'Ocorreu um erro ao tentar fazer cadastro do imóvel, revise as informações'
      )
    }

    setLoading(false)
  }

  async function submitClient(values) {
    if (loading) return false

    setClientLoading(true)

    try {
      const { data } = await api.post('/user/manual', values)

      await getUsers()

      formClient.resetFields()
      setClientModal(false)

      message.info(`Usuário adicionado, o código dele é ${data.id}`)
    } catch (err) {
      message.error(
        err.response.data.message || 'Não foi possível efetuar o cadastro'
      )
    }

    setClientLoading(false)
  }

  return (
    <AppLayout title="Imóveis" keyPage="9" subKeyPage="sub2">
      <Card title="Cadastrar novo imóvel">
        <Form
          {...layout}
          name="register"
          form={form}
          onFinish={onSubmit}
          onValuesChange={({ is_exterior: isExterior }) => {
            if (isExterior !== undefined) {
              if (isExterior) {
                form.setFieldsValue({ cep: '' })
              }

              setExterior(isExterior)
            }
          }}
          autoComplete="off"
          scrollToFirstError
        >
          <Form.Item label="Buscador de endereço">
            <PlacesAutocomplete
              value={tmpAddress}
              onChange={setTmpAddress}
              onSelect={handleAddress}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading
              }) => (
                <>
                  <Input
                    {...getInputProps({
                      placeholder:
                        'Digite o endereço para agilizarmos seu cadastro...'
                    })}
                  />
                  {(loading || suggestions.length > 0) && (
                    <Box p="2" bg="gray.50" borderRadius="5px">
                      {suggestions.map(suggestion => {
                        return (
                          <Tooltip
                            label={suggestion.description}
                            key={suggestion.placeId}
                          >
                            <Flex
                              {...getSuggestionItemProps(suggestion)}
                              direction="row"
                              cursor="pointer"
                              bg={suggestion.active ? 'blue.50' : 'white'}
                              my={2}
                              borderRadius="5px"
                              p="2"
                              transition="all ease .3s"
                            >
                              <Heading size="sm" color="gray.700" isTruncated>
                                {suggestion.description}
                              </Heading>
                            </Flex>
                          </Tooltip>
                        )
                      })}
                      {loading && <Progress size="xs" isIndeterminate />}
                    </Box>
                  )}
                </>
              )}
            </PlacesAutocomplete>
          </Form.Item>
          <Form.Item label="Exterior" name="is_exterior">
            <Switch />
          </Form.Item>
          {!exterior && (
            <Form.Item label="CEP" name="cep">
              <InputMask
                mask="99.999-999"
                onChange={async e => {
                  if (!e.target.value.includes('_')) {
                    var unformatted = e.target.value

                    unformatted = unformatted
                      .split('.')
                      .join('')
                      .split('-')
                      .join('')

                    const { data } = await axios.get(
                      `https://viacep.com.br/ws/${unformatted}/json/`
                    )

                    form.setFieldsValue({
                      street: data.logradouro,
                      neighborhood: data.bairro,
                      city: data.localidade,
                      state: data.uf,
                      latitude: '',
                      longitude: ''
                    })

                    form.scrollToField('number')
                  }
                }}
              >
                {inputProps => <Input {...inputProps} />}
              </InputMask>
            </Form.Item>
          )}
          <Form.Item label="Logradouro" name="street">
            <Input />
          </Form.Item>
          <Form.Item label="Bairro" name="neighborhood">
            <Input />
          </Form.Item>
          <Form.Item label="Cidade" name="city">
            <Input />
          </Form.Item>
          <Form.Item label="Estado" name="state">
            <Input />
          </Form.Item>
          <Form.Item label="País" name="country">
            <Input />
          </Form.Item>
          <Form.Item
            label="Número"
            name="number"
            rules={[{ required: true, message: 'Informe o Número' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Localidade">
            <Input.Group compact>
              <Form.Item name="latitude">
                <Input readOnly />
              </Form.Item>
              <Form.Item name="longitude">
                <Input readOnly />
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item label="Complemento" name="complement">
            <Input />
          </Form.Item>
          <Form.Item
            label="Transação"
            name="type"
            rules={[{ required: true, message: 'Informe o tipo' }]}
          >
            <Select>
              {types.map((type, idx) => (
                <Select.Option key={`type-${idx}`} value={type[0]}>
                  {type[1]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Tipo"
            name="location"
            rules={[{ required: true, message: 'Informe a locação' }]}
          >
            <Select>
              {locations.map((location, idx) => (
                <Select.Option key={`location-${idx}`} value={location[0]}>
                  {location[1]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Proprietário">
            <Form.Item
              name="owner_id"
              rules={[{ required: true, message: 'Informe o proprietário' }]}
            >
              <Select
                showSearch
                style={{ width: '80%' }}
                filterOption={(input, option) =>
                  option.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {users.map(user => (
                  <Select.Option key={`user-${user.id}`} value={user.id}>
                    [Cod {user.id}] {user.name} {user.cpf_cpnj}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Button
              onClick={() => setClientModal(true)}
              style={{ margin: '0 10px' }}
            >
              Adicionar
            </Button>
          </Form.Item>
          <Form.Item
            label="Preço"
            name="price"
            rules={[{ required: true, message: 'Informe o preço' }]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              disabled={loading}
              loading={loading}
            >
              Cadastrar
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <Modal
        title="Adicionar novo cliente"
        visible={clientModal}
        width={640}
        onOk={() => formClient.submit()}
        okButtonProps={{ loading: clientLoading, disabled: clientLoading }}
        onCancel={() => setClientModal(false)}
        cancelText="Fechar"
        okText="Salvar cliente"
      >
        <Form
          form={formClient}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          layout="horizontal"
          initialValues={{ type: 'OWNER', document: 'CPF', sale_rent: 'RENT' }}
          onFinish={submitClient}
          onValuesChange={async obj => {
            if (obj.document) {
              setDocument(obj.document)
            }

            if (obj.sale_rent) {
              setEmailRequired(obj.sale_rent === 'RENT')
            }

            if (obj.cep && obj.cep.split('_').length === 1) {
              const { data } = await axios.get(
                `https://viacep.com.br/ws/${obj.cep
                  .replace('.', '')
                  .replace('-', '')}/json/`
              )

              formClient.setFieldsValue({
                street: data.logradouro,
                neighborhood: data.bairro,
                city: data.localidade,
                state: data.uf
              })

              numberRef.focus()
            }
          }}
        >
          <Form.Item
            label="Nome"
            name="name"
            rules={[{ required: true, message: 'Informe o nome' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Documento" name="document">
            <Radio.Group>
              <Radio.Button value="CPF">CPF</Radio.Button>
              <Radio.Button value="CNPJ">CNPJ</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="Cadastro para" name="sale_rent">
            <Radio.Group>
              <Radio.Button value="RENT">Aluguel</Radio.Button>
              <Radio.Button value="SALE">Venda</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="CPF ou CNPJ"
            name="cpf_cnpj"
            rules={[{ required: true, message: 'Informe o documento' }]}
          >
            <MaskedInput
              mask={
                document === 'CPF' ? '111.111.111-11' : '11.111.111/1111-11'
              }
            />
          </Form.Item>
          <Form.Item
            label="Tipo de cliente"
            name="type"
            rules={[{ required: true, message: 'Informe o tipo' }]}
          >
            <Select>
              <Select.Option value="OWNER">Proprietário</Select.Option>
              <Select.Option value="TENANT">Inquilino</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="E-mail"
            name="email"
            rules={[{ required: emailRequired, message: 'Informe o e-mail' }]}
          >
            <Input type="email" />
          </Form.Item>
          <Form.Item
            label="Telefone"
            name="phone"
            rules={[{ required: true, message: 'Informe o telefone' }]}
          >
            <MaskedInput mask="(11) 1 1111-1111" />
          </Form.Item>
          <hr />
          <Form.Item label="CEP" name="cep">
            <MaskedInput mask="11.111-111" />
          </Form.Item>
          <Form.Item label="Endereço">
            <Input.Group compact>
              <Form.Item name="street" style={{ marginRight: 10 }}>
                <Input placeholder="Logradouro" style={{ width: 340 }} />
              </Form.Item>
              <Form.Item name="neighborhood" style={{ marginRight: 10 }}>
                <Input placeholder="Bairro" />
              </Form.Item>
              <Form.Item name="number" style={{ marginRight: 10 }}>
                <Input
                  placeholder="Número"
                  type="number"
                  style={{ width: 100 }}
                  ref={input => {
                    numberRef = input
                  }}
                />
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item label="Endereço">
            <Input.Group compact>
              <Form.Item name="city" style={{ marginRight: 10 }}>
                <Input readOnly disabled placeholder="Cidade" />
              </Form.Item>
              <Form.Item name="state" style={{ marginRight: 10 }}>
                <Input readOnly disabled placeholder="Estado" />
              </Form.Item>
              <Form.Item
                name="complement"
                style={{ marginRight: 10, width: '80%' }}
              >
                <Input placeholder="Complemento" />
              </Form.Item>
            </Input.Group>
          </Form.Item>
        </Form>
      </Modal>
    </AppLayout>
  )
}

export default PropertiesRegister
