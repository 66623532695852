import { createGlobalStyle } from 'styled-components'

export const Styles = createGlobalStyle`
  html, body {
    min-height: 100vh
  }

  body {
    font-family: 'Source Sans Pro', sans-serif;
  }

  #components-layout-demo-responsive .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
  }

  .site-layout-sub-header-background {
    background: #fff;
  }

  .site-layout-background {
    background: #fff;
  }

  .ant-layout-sider {
    .logo {
      text-align: center;
      padding: 25px 0;

      img {
        max-height: 60px;
      }
    }
  }

  .site-layout-sub-header-background {
    ul {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding: 0 25px;
      list-style: none;

      li {
        margin-left: 25px;

        a {
          color: #616161;
          font-weight: 600;
          font-size: 16px;
        }
      }
    }
  }

  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: rgb(255, 67, 1);
  }

  .note-group-select-from-files {
    display: none
  }

  div.ant-space.ant-space-horizontal.ant-space-align-center > div:nth-child(2) {
    margin-top: -28px;
  }

  .chat {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 5px;
  padding: 25px;
  background: rgba(255, 255, 255, 0.04);
  height: 380px;
  overflow-y: auto;
}
.chat::-webkit-scrollbar {
  width: 5px;
}
.chat::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.4);
}
.chat::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  transition: all ease 0.3s;
}
.chat::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.8);
}
.chat .chat-message {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 15px;
}
.chat .chat-message.chat-me {
  margin-left: auto;
}
.chat .chat-message.chat-me .chat-message-body {
  background-color: #7158e2;
  color: #fff;
}
.chat .chat-message .chat-message-body {
  padding: 15px;
  background-color: #383a3f;
  min-width: 280px;
  max-width: 450px;
  margin-right: 10px;
  color: #fff;
  border-radius: 12px;
}
.chat .chat-message .chat-message-time {
  font-style: italic;
  padding-top: 15px;
}

 * {
    list-style: none;  
  }

  .pagination {
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    gap: 15px;

    margin: 0;
    padding: 0;

    margin-top: 30px;

    li {
      a {
        padding: 10px;
        background: rgba(0, 0, 0, 0.1);
        color: #000;
        border-radius: 5px;
        font-size: 14px;
        cursor: pointer;
        transition: all ease .3s;

        &:hover {
          background: #F6AD55;
        }
      }

      &.active {
        a {
          background-color: #ED8936;
          color: #fff;
        }
      }
    }
`
