import { Box, Heading, CircularProgress } from '@chakra-ui/react'
import React, { useState, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { IoCloudUploadOutline } from 'react-icons/io5'
import uniqid from 'uniqid'
import { useImmer } from 'use-immer'

import api from '../../services/api'
import FileListItem from '../FileListItem'

function TransferAttachments({
  transferId,
  id,
  receipt,
  receiptUrl,
  defaultAttachments
}) {
  const [attachments, updateAttachments] = useImmer(defaultAttachments || [])
  const [oldReceipt, setOldReceipt] = useState(receipt)
  const [uploading, setUploading] = useState(false)

  const onDrop = useCallback(
    acceptedFiles => {
      setUploading(true)

      acceptedFiles.forEach(file => {
        const formData = new FormData()
        formData.append('file', file)

        api.put(`/transfer/${id}/file`, formData).then(res => {
          updateAttachments(draft => {
            return draft.concat(res.data)
          })
        })
      })

      setUploading(false)
    },
    [id, updateAttachments]
  )

  function remove(fileId) {
    updateAttachments(draft => {
      return draft.filter(file => file.id !== fileId)
    })

    api.delete(`/transfer/${fileId}/file`)
  }

  function removeOld() {
    api.patch(`/transfer/${transferId}`, { receipt: null })
    setOldReceipt(null)
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <>
      {uploading ? (
        <Box
          d="flex"
          align="center"
          justifyContent="center"
          flexDirection="column"
          bg="gray.300"
          p="5"
          borderRadius="5px"
          cursor="pointer"
        >
          <CircularProgress isIndeterminate color="blue.300" />
          <Heading size="md" fontWeight="500" mt="2">
            Estamos enviando seus arquivos
          </Heading>
        </Box>
      ) : (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <Box
              d="flex"
              align="center"
              justifyContent="center"
              flexDirection="column"
              bg="gray.300"
              p="5"
              borderRadius="5px"
              cursor="pointer"
            >
              <IoCloudUploadOutline
                size="45"
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto'
                }}
              />
              <Heading size="md" fontWeight="500" mt="2">
                Solte aqui seus arquivos
              </Heading>
            </Box>
          ) : (
            <Box
              d="flex"
              align="center"
              justifyContent="center"
              flexDirection="column"
              bg="gray.100"
              p="5"
              borderRadius="5px"
              cursor="pointer"
              _hover={{ bg: 'gray.200' }}
            >
              <IoCloudUploadOutline
                size="45"
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto'
                }}
              />
              <Heading size="md" fontWeight="500" mt="2">
                Arraste aqui seus arquivos
              </Heading>
            </Box>
          )}
        </div>
      )}

      <Box mt="5">
        {oldReceipt && (
          <FileListItem
            key={uniqid()}
            index="Antigo"
            file={{ id: 99999, source: oldReceipt, url: receiptUrl }}
            remove={() => removeOld()}
          />
        )}
        {attachments.map((file, index) => (
          <FileListItem
            key={uniqid()}
            file={file}
            index={index}
            remove={() => remove(file.id)}
          />
        ))}
      </Box>
    </>
  )
}

export default TransferAttachments
