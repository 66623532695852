import {
  Grid,
  Flex,
  Box,
  Heading,
  Input,
  Button,
  IconButton
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { FiTrash } from 'react-icons/fi'
import uniqid from 'uniqid'

import useProperty from '../../hooks/use-property'

function PropertyFeatures() {
  const [name, setName] = useState('')

  const { data, addFeature, onFeatureDelete } = useProperty()

  return (
    <Box>
      <Flex alignItems="center" mb={4}>
        <Input
          placeholder="Informe a característica"
          value={name}
          onChange={e => setName(e.target.value)}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              addFeature(name)
              setName('')
            }
          }}
        />
        <Button
          colorScheme="orange"
          ml={3}
          onClick={() => {
            addFeature(name)
            setName('')
          }}
        >
          Adicionar
        </Button>
      </Flex>
      <Grid
        mt={5}
        templateColumns={['100%', '100%', 'repeat(2, 1fr)']}
        gap="15px"
      >
        {data.features.map(feature => (
          <Flex
            key={uniqid()}
            bg="gray.50"
            p="12px"
            borderRadius="lg"
            justify="space-between"
            alignItems="center"
          >
            <Box>
              <Heading size="sm">{feature.name}</Heading>
            </Box>

            <Flex as="div" alignItems="center" justifyContent="flex-start">
              <IconButton
                size="md"
                icon={<FiTrash />}
                bg="white"
                color="red.400"
                isRound
                onClick={() => onFeatureDelete(feature.id)}
              />
            </Flex>
          </Flex>
        ))}
      </Grid>
    </Box>
  )
}

export default PropertyFeatures
