import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Badge,
  Box,
  Button,
  useToast,
  Spinner,
  useDisclosure,
  HStack
} from '@chakra-ui/react'
import React, { useState, useCallback, useEffect } from 'react'
import Paginate from 'react-paginate'
import uniqid from 'uniqid'

import ClientFilters from '../../components/Client/Filters'
import ClientModal from '../../components/Client/Modal'
import AppLayout from '../../layouts/AppLayout'
import api from '../../services/api'

function Clients() {
  const toast = useToast()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [loading, setLoading] = useState(false)

  const [selected, setSelected] = useState()

  const [data, setData] = useState([])
  const [meta, setMeta] = useState()

  const [id, setId] = useState()
  const [query, setQuery] = useState()

  const getData = useCallback(
    async (page = 1) => {
      setLoading(true)

      try {
        const {
          data: { data, lastPage, page: currentPage }
        } = await api.get('user', {
          params: { page, id, query }
        })

        setData(data)
        setMeta({
          last_page: lastPage,
          current_page: currentPage
        })
      } catch {
        toast({
          title: 'Oopss...',
          description: 'Ocorreu um erro ao tentar buscar pelos clientes',
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      }

      setLoading(false)
    },
    [id, query, toast]
  )

  useEffect(() => {
    getData()
  }, [getData, id, query])

  return (
    <AppLayout title="Clientes" keyPage="7">
      <ClientFilters
        onChange={({ id, query }) => {
          if (id) {
            setId(id)
          }

          if (query) {
            setQuery(query)
          }
        }}
      />
      <ClientModal
        isOpen={isOpen}
        onClose={() => {
          onClose()
          getData(meta.current_page)
        }}
        clientId={selected}
      />
      <Box mt="6" bg="white" p="25px" borderRadius="lg">
        <Table variant="simple" size="sm">
          <TableCaption>
            {loading && <Spinner />}
            {data && data.length > 0 && (
              <Paginate
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                pageCount={meta && meta.last_page}
                forcePage={meta && meta.current_page - 1}
                pageRangeDisplayed={5}
                marginPagesDisplayed={1}
                previousLabel={'Página anterior'}
                nextLabel={'Próxima página'}
                onPageChange={({ selected }) => {
                  getData(selected + 1)
                }}
              />
            )}
          </TableCaption>
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Nome completo</Th>
              <Th>E-mail</Th>
              <Th>Telefone</Th>
              <Th>Tipo(s)</Th>
              <Th>Detalhes</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.map(client => (
              <Tr key={uniqid()}>
                <Td isNumeric>{client.id}</Td>
                <Td>{client.name}</Td>
                <Td>{client.email || 'E-mail não definido'}</Td>
                <Td>{client.phone}</Td>
                <Td>
                  <HStack spacing="3">
                    {client.type === 'TENANT' && (
                      <Badge colorScheme="green">Inquilino</Badge>
                    )}
                    {client.type === 'OWNER' && (
                      <Badge colorScheme="blue">Proprietário</Badge>
                    )}
                    {client.type === 'GUARANTOR' && (
                      <Badge colorScheme="purple">Fiador</Badge>
                    )}
                  </HStack>
                </Td>
                <Td>
                  <Button
                    size="sm"
                    variant="outline"
                    onClick={() => {
                      setSelected(client.id)
                      onOpen()
                    }}
                  >
                    Abrir
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </AppLayout>
  )
}

export default Clients
