import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Grid,
  Switch,
  Select
} from '@chakra-ui/react'
import React from 'react'
import uniqid from 'uniqid'

import useProperty from '../../hooks/use-property'

function PropertyForm() {
  const { data, updateData, setUpdating, setUpdatingDefault } = useProperty()

  const types = [
    ['SALE', 'Venda'],
    ['RENT', 'Aluguel'],
    ['PRE_SALE', 'Pré Venda'],
    ['EXCHANGE', 'Permuta']
  ]

  const locations = [
    ['APARTMENT', 'Apartamento'],
    ['ROOF', 'Cobertura'],
    ['HOUSE', 'Casa'],
    ['FARM', 'Fazenda'],
    ['CLINIC', 'Consultório'],
    ['SHED', 'Galpão'],
    ['GARAGE', 'Garagem'],
    ['KITNET', 'Kitnet'],
    ['LOT', 'Lote'],
    ['STORE', 'Loja'],
    ['COMERCIAL', 'Sala Comercial'],
    ['SITE', 'Sítio']
  ]

  function handleChange(event) {
    const { name, value } = event.target

    var toAssign = {
      ...JSON.parse(JSON.stringify(data))
    }

    toAssign[name] = value

    setUpdating(true)
    setUpdatingDefault(true)

    updateData(draft => {
      return Object.assign(draft, toAssign)
    })
  }

  function handleCheck(event) {
    const { name, checked } = event.target

    var toAssign = {
      ...JSON.parse(JSON.stringify(data))
    }

    toAssign[name] = checked

    setUpdating(true)
    setUpdatingDefault(true)

    updateData(draft => {
      return Object.assign(draft, toAssign)
    })
  }

  return (
    <Box p="0 5">
      <Grid templateColumns="38% 38% 20%" gap="2%" mb={3}>
        <FormControl id="transaction" isRequired>
          <FormLabel>Transação</FormLabel>
          <Select name="type" onChange={handleChange} value={data.type}>
            {types.map(type => (
              <option key={uniqid()} value={type[0]}>
                {type[1]}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl id="category" isRequired>
          <FormLabel>Categoria</FormLabel>
          <Select name="location" onChange={handleChange} value={data.location}>
            {locations.map(location => (
              <option key={uniqid()} value={location[0]}>
                {location[1]}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl id="price" isRequired>
          <FormLabel>Valor</FormLabel>
          <Input
            type="number"
            step="2"
            name="price"
            onChange={handleChange}
            value={data.price}
          />
        </FormControl>
      </Grid>

      <Grid templateColumns="repeat(3, 1fr)" gap="15px" mb={3}>
        <FormControl id="bedrooms" isRequired>
          <FormLabel>Quartos</FormLabel>
          <Input
            type="number"
            name="bedrooms"
            onChange={handleChange}
            value={data.bedrooms}
          />
        </FormControl>
        <FormControl id="bathrooms" isRequired>
          <FormLabel>Banheiros</FormLabel>
          <Input
            type="number"
            name="bathrooms"
            onChange={handleChange}
            value={data.bathrooms}
          />
        </FormControl>
        <FormControl id="garage" isRequired>
          <FormLabel>Vagas</FormLabel>
          <Input
            type="number"
            name="garage"
            onChange={handleChange}
            value={data.garage}
          />
        </FormControl>
      </Grid>

      {Object.keys(data).length > 0 && (
        <Grid templateColumns="repeat(3, 1fr)" gap="15px" mb={3}>
          <Box bg="gray.100" p="4" borderRadius="lg">
            <FormControl
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Switch
                id="spotlight"
                name="spotlight"
                defaultChecked={data.spotlight}
                onChange={handleCheck}
                mb="0"
              />
              <FormLabel ml={3} htmlFor="spotlight" mb="0">
                Deseja destacar?
              </FormLabel>
            </FormControl>
          </Box>
          <Box bg="gray.100" p="4" borderRadius="lg">
            <FormControl
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Switch
                id="is_exterior"
                name="is_exterior"
                isChecked={data.is_exterior}
                onChange={handleCheck}
                mb="0"
              />
              <FormLabel ml={3} htmlFor="is_exterior" mb="0">
                É no exterior?
              </FormLabel>
            </FormControl>
          </Box>
          <Box bg="gray.100" p="4" borderRadius="lg">
            <FormControl
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Switch
                id="exclusivity"
                name="exclusivity"
                isChecked={data.exclusivity}
                onChange={handleCheck}
                mb="0"
              />
              <FormLabel ml={3} htmlFor="exclusivity" mb="0">
                Exclusividade?
              </FormLabel>
            </FormControl>
          </Box>
        </Grid>
      )}

      <Grid templateColumns="repeat(2, 1fr)" gap="15px" mb={3}>
        <FormControl id="pickup" isRequired>
          <FormLabel>Captador</FormLabel>
          <Input name="pickup" value={data.pickup} onChange={handleChange} />
        </FormControl>
        <FormControl id="commission" isRequired>
          <FormLabel>Comissão</FormLabel>
          <Input
            name="commission"
            value={data.commission}
            onChange={handleChange}
          />
        </FormControl>
      </Grid>
    </Box>
  )
}

export default PropertyForm
