import {
  Box,
  Heading,
  IconButton,
  Tooltip,
  Text,
  Input,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Button,
  Textarea,
  Flex,
  useClipboard,
  Grid
} from '@chakra-ui/react'
import React, { memo, useState } from 'react'
import { BsCheck, BsInfoCircle } from 'react-icons/bs'
import { IoMdCopy } from 'react-icons/io'
import { IoCloseOutline } from 'react-icons/io5'

import useClient from '../../../hooks/use-client'
import api from '../../../services/api'

function BanksItem({ bank }) {
  const toast = useToast()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const { hasCopied: hasBankNameCopied, onCopy: onBankNameCopy } = useClipboard(
    bank.holder_name
  )
  const { hasCopied: hasBankDocumentCopied, onCopy: onBankDocumentCopy } =
    useClipboard(bank.document)
  const { hasCopied: hasBankInstitutionCopied, onCopy: onBankInstitutionCopy } =
    useClipboard(bank.bank_name)
  const { hasCopied: hasBankAccountCopied, onCopy: onBankAccountCopy } =
    useClipboard(bank.account_number)
  const { hasCopied: hasBankAgencyCopied, onCopy: onBankAgencyCopy } =
    useClipboard(bank.agency_number)
  const { hasCopied: hasBankPixCopied, onCopy: onBankPixCopy } = useClipboard(
    bank.pix_key
  )
  const { hasCopied: hasOperationCopied, onCopy: onOperationCopy } =
    useClipboard(bank.operation_number)

  const { clientId, updateBanks } = useClient()

  const [deletting, setDeletting] = useState(false)

  async function handleDelete() {
    setDeletting(true)

    setTimeout(() => {
      onClose()
    }, 50)

    try {
      await api.delete(`/user/${clientId}/bank/${bank.id}`)

      updateBanks(draft => {
        return draft.filter(item => item.id !== bank.id)
      })
    } catch {
      toast({
        title: 'Oopss...',
        description: 'Não foi possível adicionar a conta bancária',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }

    setDeletting(false)
  }

  return (
    <>
      <Box
        pos="relative"
        bg="gray.100"
        p="5"
        cursor="pointer"
        height="150px"
        borderRadius="8px"
        _hover={{ bg: 'gray.200' }}
        onClick={onOpen}
      >
        <Heading size="md" mb={4} maxW="70%" isTruncated>
          {bank.bank_name}
        </Heading>
        <Text isTruncated>{bank.holder_name}</Text>
        <Text>{bank.document}</Text>
        <Text>
          Conta {bank.account_type === 'CORRENTE' ? 'corrente' : 'poupança'}
        </Text>
        <IconButton
          pos="absolute"
          top="16px"
          right="16px"
          size="sm"
          variant="ghost"
          zIndex={999}
          icon={<IoCloseOutline />}
          onClick={handleDelete}
          isLoading={deletting}
          isDisabled={deletting}
        />
        {!!bank.obs && (
          <Tooltip label={bank.obs}>
            <IconButton
              pos="absolute"
              top="16px"
              right="56px"
              size="sm"
              variant="ghost"
              zIndex={999}
              icon={<BsInfoCircle />}
            />
          </Tooltip>
        )}
      </Box>
      <AlertDialog isOpen={isOpen} onClose={onClose} size="2xl">
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Detalhes da conta bancária
            </AlertDialogHeader>

            <AlertDialogBody>
              <Grid templateColumns="2fr 1fr" gap="26px" mb={3}>
                <Box>
                  <Text>Nome completo</Text>
                  <Flex>
                    <Input
                      w="100%"
                      value={bank.holder_name || 'Não encontrado'}
                      isReadOnly
                    />
                    <IconButton
                      icon={hasBankNameCopied ? <BsCheck /> : <IoMdCopy />}
                      ml={2}
                      colorScheme={hasBankNameCopied ? 'whatsapp' : 'gray'}
                      onClick={onBankNameCopy}
                    />
                  </Flex>
                </Box>
                <Box>
                  <Text>CPF</Text>
                  <Flex>
                    <Input w="100%" value={bank.document} isReadOnly />
                    <IconButton
                      icon={hasBankDocumentCopied ? <BsCheck /> : <IoMdCopy />}
                      ml={2}
                      colorScheme={hasBankDocumentCopied ? 'whatsapp' : 'gray'}
                      onClick={onBankDocumentCopy}
                    />
                  </Flex>
                </Box>
              </Grid>
              <Box mb={3}>
                <Text>Banco / Instituição:</Text>
                <Flex>
                  <Input
                    w="100%"
                    value={`${bank.code} - ${bank.bank_name}`}
                    isReadOnly
                  />
                  <IconButton
                    icon={hasBankInstitutionCopied ? <BsCheck /> : <IoMdCopy />}
                    ml={2}
                    colorScheme={hasBankInstitutionCopied ? 'whatsapp' : 'gray'}
                    onClick={onBankInstitutionCopy}
                  />
                </Flex>
              </Box>
              <Grid templateColumns="repeat(3, 1fr)" gap="26px" mb={3}>
                <Box>
                  <Text>Nº da Conta</Text>
                  <Flex>
                    <Input w="100%" value={bank.account_number} isReadOnly />
                    <IconButton
                      icon={hasBankAccountCopied ? <BsCheck /> : <IoMdCopy />}
                      ml={2}
                      colorScheme={hasBankAccountCopied ? 'whatsapp' : 'gray'}
                      onClick={onBankAccountCopy}
                    />
                  </Flex>
                </Box>
                <Box>
                  <Text>Agência</Text>
                  <Flex>
                    <Input w="100%" value={bank.agency_number} isReadOnly />
                    <IconButton
                      icon={hasBankAgencyCopied ? <BsCheck /> : <IoMdCopy />}
                      ml={2}
                      colorScheme={hasBankAgencyCopied ? 'whatsapp' : 'gray'}
                      onClick={onBankAgencyCopy}
                    />
                  </Flex>
                </Box>
                <Box>
                  <Text>Operação</Text>
                  <Flex>
                    <Input w="100%" value={bank.operation_number} isReadOnly />
                    <IconButton
                      icon={hasOperationCopied ? <BsCheck /> : <IoMdCopy />}
                      ml={2}
                      colorScheme={hasOperationCopied ? 'whatsapp' : 'gray'}
                      onClick={onOperationCopy}
                    />
                  </Flex>
                </Box>
              </Grid>
              <Box>
                <Text>Chave PIX</Text>
                <Flex>
                  <Input w="100%" value={bank.pix_key} isReadOnly />
                  <IconButton
                    icon={hasBankPixCopied ? <BsCheck /> : <IoMdCopy />}
                    ml={2}
                    colorScheme={hasBankPixCopied ? 'whatsapp' : 'gray'}
                    onClick={onBankPixCopy}
                  />
                </Flex>
              </Box>
              <Text>Observações</Text>
              <Textarea value={bank.obs} />
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button variant="ghost" onClick={onClose}>
                Fechar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default memo(BanksItem)
